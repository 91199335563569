import { axiosClient } from '../../config/axios';
import { IPagable } from './patientProfile';

const fetchPatientsData = async (pageable?: IPagable) => {
  const { data } = await axiosClient.get(`copilot/patients?page=${pageable?.page || 0}&size=${pageable?.size || 10}`);
  return data;
};

const enrollPatient = async (siteId?:string | number, patientId?: string | number) => {
  const { data } = await axiosClient.post(`centers/${siteId}/patients/${patientId}`);
  return data;
};

export {
  fetchPatientsData,
  enrollPatient
}