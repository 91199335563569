// transforms any string type in case insensitive
export type AnyCase<T extends string> = string extends T
  ? string
  : T extends `${infer F1}${infer F2}${infer R}`
  ? `${Uppercase<F1> | Lowercase<F1>}${Uppercase<F2> | Lowercase<F2>}${AnyCase<R>}`
  : T extends `${infer F}${infer R}`
  ? `${Uppercase<F> | Lowercase<F>}${AnyCase<R>}`
  : '';

export const langIconEnum: any = {
  en: 'english',
  fr: 'french',
  ja: 'japanese',
  pt: 'portuguese',
} 


export const languageMap: Record<string, string> = {
  en: 'EN',
  fr: 'FR',
  ja: 'JA',
  pt: 'PT',
};