import { FiChevronLeft, FiChevronsLeft } from 'react-icons/fi';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { Menu } from 'components/common';
import { useCentersStore } from 'store/centers';
import { memo, useEffect, useState } from 'react';
import useAuthStore from 'store/authStore';
import NavigateFunctionComponent from 'pages/App/NavigateFunctionComponent';
import { useQuery } from '@tanstack/react-query';
import { retrieveCenters } from 'services/api/study';
import { Opt } from 'types/form';
import { useStudyStore } from 'store/StudyStore';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import _ from 'lodash';
import { useParticipantStore } from 'store/participantStore';
import StatusSelect from './StatusSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import usePermissions from '../../../hooks/usePermissions';

function StatusBar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { studyId, participantId } = useParams();
  const { setAccessToken, userData } = useAuthStore();
  const [optLabel, setOptLabel] = useState(intl.formatMessage({ id: 'allStudies' }));
  const [centerOpt, setCenterOpt] = useState<Opt[]>();
  const [patientOpt, setPatientOpt] = useState<Opt[]>();
  const [folderOpt, setFolderOpt] = useState<Opt[]>();
  const { setSelectedCenter, selectedCenter, setCenters, centers } = useCentersStore();
  const { currentStudy, loggedInStudyUser, setCurrentStudy } = useStudyStore();
  const { setSelectedFolder, selectedFolder, customFolders } = useQuestionnaireStore();
  const { setParticipant, participant } = useParticipantStore();
  const { hasNotAccess } = usePermissions();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const isViewOthers = !hasNotAccess('VIEW_OTHER_PATIENTS')

  const { data, refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && userData && userData?.firstName && retrieveCenters(studyId),
  });

  useEffect(() => {
    participantId && (participant === '' || participant === 'all') && setParticipant(participantId);
  }, [participantId, participant]);

  const handleBack = () => {
    setSelectedCenter('all');
    setSelectedFolder('all');
    setParticipant('all');
    setCurrentStudy(null as any);
  };

  useEffect(() => {
    selectedCenter === '' && setSelectedCenter('all');
    selectedFolder === '' && setSelectedFolder('all');
    participant === '' && setParticipant('all');
  }, []);

  useEffect(() => {
    studyId && userData && refetch();
  }, [studyId, userData]);

  useEffect(() => {
    if (studyId && participantId) {
      const getCenter = data?.find(
        (cn: any) =>
          `${cn?.studyId}` === `${studyId}` &&
          cn?.patients?.length > 0 &&
          cn?.patients?.find((pcn: any) => `${pcn?.id}` === `${participantId}`),
      );
      getCenter && setSelectedCenter(getCenter?.id);
    }
  }, [studyId, data, participantId]);

  const checkAllowedCenter: any = (center: any) => {
    const countryCheck = (`${center?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}`) || (loggedInStudyUser?.country?.name === 'Global');
    return (loggedInStudyUser?.allowedCenters?.length === 0 && countryCheck) || (loggedInStudyUser?.allowedCenters?.length > 0 && loggedInStudyUser?.allowedCenters?.find((cntr: any) => `${cntr}` === `${center?.id}`));
  }

  useEffect(() => {
    if (data && data?.length > 0) {
      const tempCenter = data?.filter((ce: any) => `${ce.studyId}` === `${studyId}` && ((isSiteStaff && (isViewOthers || `${ce?.id}` === `${loggedInStudyUser?.centerId}`)) || (!isSiteStaff && checkAllowedCenter(ce))));
      setCenters(tempCenter);
      const centersOptions = [
        { label: intl.formatMessage({ id: 'study-dashboard.allCenter' }), value: 'all' },
      ].concat(
        (tempCenter?.length > 0 &&
          tempCenter.map((item: any) => ({
            label: item.name,
            value: `${item.id}`,
          }))) ||
          [],
      );
      setCenterOpt(centersOptions);
    } else {
      setCenterOpt([
        { label: intl.formatMessage({ id: 'study-dashboard.allCenter' }), value: 'all' },
      ]);
    }
  }, [data, studyId, intl, isSiteStaff, isViewOthers, loggedInStudyUser]);

  const isShowPatient = (center: any) => {
    const countryCheck = (`${center?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}`) || (loggedInStudyUser?.country?.name === 'Global');
    const isAllowedCntrs = (loggedInStudyUser?.allowedCenters?.length === 0 && countryCheck) || (loggedInStudyUser?.allowedCenters?.length > 0 && loggedInStudyUser?.allowedCenters?.find((cntr: any) => `${cntr}` === `${center?.id}`));
    return (isSiteStaff && (isViewOthers || `${center.id}` === `${loggedInStudyUser?.centerId}`)) || (!isSiteStaff && isAllowedCntrs)
  }

  const isShowCenterPatient = (item: any) => {
    const countryCheck = (`${item?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}`) || (loggedInStudyUser?.country?.name === 'Global');
    const isAllowedCntrs = (loggedInStudyUser?.allowedCenters?.length === 0 && countryCheck) || (loggedInStudyUser?.allowedCenters?.length > 0 && loggedInStudyUser?.allowedCenters?.find((cntr: any) => `${cntr}` === `${selectedCenter}`));
    return `${selectedCenter}` === `${item?.id}` && ((isSiteStaff && (isViewOthers || (`${item.id}` === `${loggedInStudyUser?.centerId}`))) || (!isSiteStaff && isAllowedCntrs))
  }

  useEffect(() => {
    if (centers && centers?.length > 0) {
      let patientList: any = [];
      centers?.map((item: any) => {
        item?.patients?.length > 0 &&
          item?.patients.map((pnt: any) => {
            if (
              (selectedCenter !== 'all' && isShowCenterPatient(item)) ||
              (selectedCenter === 'all' && isShowPatient(item))
            ) {
              const isShowSite = `${item.id}` === `${loggedInStudyUser?.centerId}`;
              const orderIndex = pnt?.id < 10 ? `00${pnt?.id}` : `0${pnt?.id}`;
              patientList = [
                ...patientList,
                {
                  label: isSiteStaff && isShowSite
                    ? `${pnt?.userProfile?.firstName}${pnt?.userProfile?.lastName || ''}`
                    : `${orderIndex}${item?.address?.country?.countryIsoCode}${pnt.centerId}`,
                  value: pnt?.id,
                },
              ];
            }
          });
      });
      const patientOptions = [
        { label: intl.formatMessage({ id: 'study-dashboard.allpatient' }), value: 'all' },
      ].concat(patientList || []);

      setPatientOpt(patientOptions.filter((patOpt) => patOpt !== null));
    } else {
      setPatientOpt([
        { label: intl.formatMessage({ id: 'study-dashboard.allpatient' }), value: 'all' },
      ]);
    }
    if (currentStudy?.steps && currentStudy?.steps?.length > 0) {
      if (customFolders && customFolders?.length > 0) {
        setFolderOpt([
          { label: intl.formatMessage({ id: 'study-dashboard.allVisit' }), value: 'all' },
        ].concat(customFolders))
        return;
      }
      const folderOptions = [
        { label: intl.formatMessage({ id: 'study-dashboard.allVisit' }), value: 'all' },
      ].concat(
        currentStudy?.steps?.map((item: any) =>
          item?.folder
            ? {
                label: item?.folder?.name,
                value: item?.folder?.id,
              }
            : null,
        ),
      );
      const filteredArr = _.uniqBy(
        folderOptions.filter((qsopt) => qsopt !== null),
        'value',
      );
      setFolderOpt(filteredArr);
    } else {
      setFolderOpt([
        { label: intl.formatMessage({ id: 'study-dashboard.allVisit' }), value: 'all' },
      ]);
    }
  }, [currentStudy, selectedCenter, intl, customFolders]);

  const studySelected = location.pathname.match(/study/gi)?.length;

  const handleOptClick = (option: string) => {
    option !== 'Logout' && setOptLabel(option === 'Studies' ? `All ${option}` : option);
    switch (option) {
      case 'Logout':
        setAccessToken('');
        navigate('/login');
        break;
      case 'Admin Settings':
        navigate('/admin-settings');
        break;
      case 'Management':
        navigate('/users');
        break;
      case 'Studies':
        navigate('/');
        break;
    }
  };

  const adminOpts = ['Studies', 'Admin Settings', 'Logout'];
  const userOpts = ['Studies', 'Logout'];

  return (
    <header
      className='flex h-[4.8125rem] sticky top-0 justify-between z-50 text-base-white items-center '
      style={{
        backgroundImage: 'linear-gradient(to right, #012C54 -12%,#064584 24.28%,#C491D7 97.46%,#FFFFFF 111.91%)',
      }}
    >
      <NavigateFunctionComponent />
      <div className='flex gap-5 items-center ml-4'>
        <Link to={'/'} onClick={handleBack} >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='49'
            height='49'
            viewBox='0 0 49 49'
            fill='none'
            className='shrink-0'
          >
            <rect width='49' height='49' rx='24.5' fill='white' />
            <line
              x1='10.9377'
              y1='32.4481'
              x2='10.9377'
              y2='19.0827'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='30.27'
              y1='18.2376'
              x2='39.6421'
              y2='27.6097'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='20.4289'
              y1='27.382'
              x2='11.4207'
              y2='19.37'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='29.8263'
              y1='18.3947'
              x2='21.1055'
              y2='27.2605'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
          </svg>
        </Link>
        {location?.pathname !== '/study/create' &&
          (studySelected ? (
            <Link to={currentStudy?.shortTitle ? `/study/${currentStudy?.id}/summary` : '/'} className='flex items-center cursor-pointer'>
              <FiChevronsLeft className='text-3xl' />
              <h1 className='text-xl pl-3 text-left'>
                {currentStudy?.shortTitle ? (
                  <div className='flex'>{currentStudy?.shortTitle}</div>
                ) : (
                  <FormattedMessage id='allStudies' />
                )}
              </h1>
            </Link>
          ) : (
            <div className='flex items-center cursor-pointer'>
              <FiChevronLeft width={36} height={36} className='text-2xl invisible' />
              <h1 className='text-xl pl-3 text-left w-[180px]'>{optLabel}</h1>
            </div>
          ))}

        {((location?.pathname?.includes('/participants') &&
          !location?.pathname?.includes('/participants/recruitment')) ||
          location?.pathname?.includes('/query-dashboard') ||
          location?.pathname?.includes('/summary') ||
          location?.pathname?.includes('/standard-forms') ||
          location?.pathname?.includes('/details')) && (
          <div className='flex gap-x-2'>
            <p className='mr-4'>|</p>
            <StatusSelect
              customControl={{
                value: `${selectedCenter}` ?? '',
                onChange: (e: any) => {
                  setSelectedCenter(e);
                  setParticipant('all');
                  if (window?.location?.pathname?.includes('/participants')) {
                    navigate(`study/${studyId}/participants/enrollment`);
                  }
                },
              }}
              name={'center'}
              placeholder={intl.formatMessage({ id: 'patient-profile.center' })}
              options={centerOpt || []}
            />
            <StatusSelect
              customControl={{
                value: `${participant}` ?? '',
                onChange: (e: any) => {
                  setParticipant(e);
                  if (window?.location?.pathname?.includes('/participants')) {
                    if (e !== 'all') {
                      const lastPath =
                        window.location.pathname.split('participants/')?.length > 1
                          ? window.location.pathname.split('participants/')[1]?.split('/')[1]
                          : '';
                      navigate(
                        `study/${studyId}/participants/${e}/${lastPath || 'questionnaire-status'}`,
                      );
                    } else {
                      navigate(`study/${studyId}/participants/enrollment`);
                    }
                  }
                },
              }}
              name={'participant'}
              placeholder={intl.formatMessage({ id: 'study-dashboard.participant' })}
              options={patientOpt || []}
            />
            {!location?.pathname?.includes('/participants') && (
              <StatusSelect
                customControl={{
                  value: `${selectedFolder}` ?? '',
                  onChange: (e: any) => {
                    setSelectedFolder(e);
                    if (e !== 'all' && location?.pathname?.includes('/participants')) {
                      navigate(
                        `study/${studyId}/participants/${participantId}/questionnaire-status`,
                      );
                    }
                  },
                }}
                name='folder'
                placeholder={intl.formatMessage({ id: 'patient-status.folder' })}
                options={folderOpt || []}
              />
            )}
          </div>
        )}
      </div>
      <Menu onOptionClick={handleOptClick} options={userData?.userAccount?.role?.name === 'ADMIN' ? adminOpts : userOpts}>
        <div className='flex items-center p-4 pr-3 mr-1 gap-4 cursor-pointer hover:bg-gray-light hover:bg-opacity-25 min-w-[125px]'>
          <span className='text-base'>
            {userData?.firstName} {userData?.lastName}
          </span>
          <img
            src='https://images.pexels.com/photos/697509/pexels-photo-697509.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            className='w-[2.6875rem] h-[2.6875rem] rounded-full'
            alt='Theóphile'
          />
        </div>
      </Menu>
    </header>
  );
}

export default memo(StatusBar);
