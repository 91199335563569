import { Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { routes } from 'routes';
import FallbackLoading from 'components/common/FallbackLoading';
import './App.css';
import 'chartjs-plugin-style';
import 'react-toastify/dist/ReactToastify.css';
import LanguageProvider from './LanguageContext';
import { useIntl } from 'react-intl';

function MyFallbackComponent({ error, resetErrorBoundary }: any) {
  const intl = useIntl();

  return (
    <div role='alert'>
      <p>{intl.formatMessage({id: 'fallback.somethingWentWrong'})}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{intl.formatMessage({id: 'fallback.tryAgain'})}</button>
    </div>
  );
}

function App() {
  const router = createBrowserRouter(routes);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <LanguageProvider>
        <div id="dropdown-root" />
        <QueryClientProvider client={queryClient}>
          <div className="App bg-base-background h-screen max-h-screen min-h-screen">
            <Suspense fallback={<FallbackLoading />}>
              <RouterProvider router={router} />
            </Suspense>
            <ToastContainer autoClose={2000} />
          </div>
        </QueryClientProvider>
      </LanguageProvider>
    </ErrorBoundary>
  );
}

export default App;
