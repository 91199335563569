
export function loadMessages(locale: string) {
  switch (locale) {
    case 'en':
      return import('../../../../services/language/i18n/en-US.json');
    case 'ja':
      return import('../../../../services/language/i18n/ja.json');
    case 'pt':
      return import('../../../../services/language/i18n/pt.json');
    case 'fr':
      return import('../../../../services/language/i18n/fr.json');
    default:
      return import('../../../../services/language/i18n/en-US.json');
  }
}
