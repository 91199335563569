import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from './Icons';
import { SvgIcon } from '@mui/material';
import clsx from 'classnames';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: ReactNode;
  className?: {
    content?: string;
    header?: string;
    bg?: string;
  };
  hideCloseIcon?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  className,
  children,
  hideCloseIcon = false,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className='fixed inset-0 flex items-center justify-center z-50 shadow-md'>
      <div
        onClick={onClose}
        className={clsx('modal-overlay bg-black opacity-40 fixed inset-0', className?.bg)}
      ></div>
      <div
        className={clsx(
          'modal-content bg-white p-4 rounded-lg shadow-md relative',
          className?.content,
        )}
      >
        <div
          className={clsx(
            'modal-close right-4 flex justify-between items-center',
            className?.header,
            !title && '!justify-end',
          )}
        >
          {title && title}

          {!hideCloseIcon && (
            <SvgIcon
              onClick={onClose}
              component={CloseIcon}
              className='cursor-pointer !w-[18px] !h-[18px]'
            />
          )}
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')!,
  );
};

export default Modal;
