import { Calendar, CheckBoxList, Radio, Select, TextArea } from 'components/common';
import FileUploadWithProgressBar from 'components/common/FileUploadWithProgressBar';
import RatingStar from 'components/common/RatingStar';
import Input from 'components/common/TextInput';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'classnames';
import ScaleInput from './ScaleInput';
import PreRepeatedQuestion from './PreRepeatedQuestions';
import { useIntl } from 'react-intl';
import { addAsterisk } from '../../../../../utils/string';
import { languageMap } from 'types/util';
import { LanguageContext } from 'pages/App/LanguageContext';

interface IQuestionOptions {
  id: number;
  label: string;
  value: string;
  labelTranslations?: any;
}

interface QuestionProps {
  title: string;
  id?: number;
  questionType?: string;
  uploadLoading?: boolean;
  questionOptions?: any;
  inputPlaceHolder?: any;
  description?: any;
  methods?: any;
  answer: string;
  dateTime?: string;
  checkType?: () => void;
  onBlur?: (value: any) => void;
  questionairetype?: string;
  dateTimeType?: string;
  styleType?: string;
  stars?: string;
  subtitle?: string;
  preview?: boolean;
  startValue?: any;
  endValue?: any;
  increment?: any;
  parentQuestionId?: any;
  titleQuestionId?: any;
  isLastQuestion?: any;
  previewMobileStyle?: boolean;
  mobileView?: boolean;
  required?: boolean;
  questionNumber?: number;
  repeatedQuestions?: any;
  isSubLastQuestion?: boolean;
  numberType?: 'DECIMAL' | 'INTEGER';
  isQuestionEmpty?: boolean;
  startLegend?: any;
  endLegend?: any;
  inputType?: any;
  startLegendTranslations?: any;
  endLegendTranslations?: any;
  titleTranslations?:any;
  subtitleTranslations?: any;
  translation?: any;
}

const PickerTypeEnum: any = {
  DATE: 'date',
  TIME: 'time',
  DATE_AND_TIME: 'datetime',
  YEAR: 'year',
  YEAR_AND_MONTH: 'yearmonth',
};

const Question = ({
  id,
  methods,
  title,
  answer,
  uploadLoading,
  checkType,
  questionType = '',
  questionOptions,
  inputPlaceHolder = '',
  dateTimeType,
  onBlur,
  stars,
  preview,
  startValue,
  endValue,
  increment,
  styleType,
  parentQuestionId,
  titleQuestionId,
  isLastQuestion,
  previewMobileStyle,
  questionNumber,
  subtitle,
  mobileView,
  repeatedQuestions,
  isSubLastQuestion,
  required,
  numberType,
  isQuestionEmpty,
  startLegend,
  endLegend,
  inputType,
  translation,
  ...quest
}: QuestionProps & { mobileView?: boolean }) => {

  const intl = useIntl();
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedRadioBtns, setSelectedRadioBtns] = useState(undefined);
  const extendedName = `${id}`
  const { locale } = useContext(LanguageContext);
  const [defaultLocale, setDefaultLocale] = useState<string>(locale);

  const getCheckboxAns = (answer: string) => {
    const idList = answer.replace('[', '').replace(']', '').split(',');
    const valueList = questionOptions?.reduce((newValue: any, ques: any) => {
      if (idList?.find((ids) => `${ids}` === `${ques.id}`)) {
        newValue.push(ques.value);
      }
      return newValue;
    }, []);
    return valueList || [];
  };

  useEffect(() => {
    if (translation) {
      setDefaultLocale(translation);
    }
  }, [translation]);

  useEffect(() => {
    if (locale && translation === '') {
      setDefaultLocale(locale);
    }
  }, [locale]);

  const getAnsValue = (answer: string) => {
    const ans = answer.replace('[', '').replace(']', '');
    return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
  };

  useLayoutEffect(() => {
    if (answer) {
      setSelectedRadioBtns(methods.getValues(`radioBtns${extendedName}`));
    }
  }, [answer, getAnsValue]);

  useEffect(() => {
    switch (questionType) {
      case 'SCALE':
        methods.setValue(`scale${extendedName}`, answer);
        break;
      case 'TEXT_INPUT':
        inputType === 'LONG' ?
          methods.setValue(`textArea${extendedName}`, answer) :
          methods.setValue(`textInput${extendedName}`, answer)
        break;
      case 'NUMBER_VALUE':
        methods.setValue(`numberTxt${extendedName}`, answer);
        break;
      case 'CHECKBOX':
        methods.setValue(`checkList${extendedName}`, getCheckboxAns(`${answer}`));
        break;
      case 'DROPDOWN':
        methods.setValue(`selectField${extendedName}`, getAnsValue(`${answer}`));
        break;
      case 'DATE_TIME':
        answer &&
          answer !== '' &&
          `${new Date(answer)}` !== 'Invalid Date' &&
          methods.setValue(`dateTime${extendedName}`, new Date(answer)?.toISOString() || null);
        break;
      case 'RADIO_BUTTONS':
        methods.setValue(`radioBtns${extendedName}`, getAnsValue(`${answer}`));
        break;
      case 'RATING':
        methods.setValue(`rating${extendedName}`, answer);
        break;
      case 'FILE_UPLOAD':
        methods.setValue(`fileUpload${extendedName}`, answer);
        break;
      default:
        null;
    }
  }, [answer, questionType]);

  const renderAnswer = () => {
    const values = methods?.watch();
    switch (questionType) {
      case 'SCALE':
        return (
          <div className={`${previewMobileStyle ? 'w-[14rem] items-center flex flex-col' : ''}`}>
            {startLegend && previewMobileStyle && (
              <p className='max-w-[12rem] leading-4 text-wrap pt-1 text-center z-100 w-auto text-[12px] text-blue-oil'>
               {quest?.startLegendTranslations?.[languageMap[defaultLocale]] || startLegend}
              </p>
            )}
            <div className={previewMobileStyle ? `-rotate-90 h-[70px] -ml-[30px] ${startLegend ? 'mt-[11rem]' : 'mt-[7rem]' } ` : ''}>
              <ScaleInput
                name={`scale${extendedName}`}
                control={methods.control}
                start={startValue}
                end={endValue}
                startLegend={quest?.startLegendTranslations?.[languageMap[defaultLocale]] || startLegend}
                isMobileLayout={previewMobileStyle}
                islegendSide={(startLegend || endLegend) && parentQuestionId === null}
                endLegend={quest?.endLegendTranslations?.[languageMap[defaultLocale]] || endLegend}
                increment={increment}
                onChange={() => {}}
                mainClass={previewMobileStyle ? '[&>div>.percent]:rotate-90' : mobileView ? '!w-[210px]' : ''}
                inputClass={previewMobileStyle ? '' : mobileView ? '!w-[210px]' : ''}
                legendClass={{
                  start: mobileView ? '!w-[100px]' : '',
                  startTooltip: mobileView ? '!w-[100px]' : '',
                  end: mobileView ? '!w-[100px]' : '',
                  endTooltip: mobileView ? '!w-[100px]' : '',
                }}
                numberClass={previewMobileStyle ? '[&>div>.division]:rotate-90' : mobileView ? '!w-[210px]' : ''}
              />{' '}
            </div>
            {endLegend && previewMobileStyle && (
              <p className='max-w-[12rem] leading-4 text-center mt-[12rem] text-wrap w-auto text-[12px] text-blue-oil'>
                {quest?.endLegendTranslations?.[languageMap[defaultLocale]] || endLegend}
              </p>
            )}
          </div>
        );
      case 'TEXT_INPUT':
        return inputType === 'LONG' ? (
          <div
            className={clsx(
              'h-12 flex w-[27rem] items-center  ',
              preview && 'w-full',
              previewMobileStyle ? 'pl-0' : 'pl-4',
            )}
          >
            <TextArea
              name={`textArea${extendedName}`}
              placeholder={inputPlaceHolder}
              customControl={{
                value: values[`textArea${extendedName}`],
                onChange: (e: any) => {
                  methods?.setValue(`textArea${extendedName}`, e);
                },
              }}
              errors={methods?.formState?.errors}
              rows={values[`textArea${extendedName}`] ? 3 : 1}
              className='!mt-0 w-full !min-w-[120px]'
              textClass={clsx(
                '!border-blue-normal-35 !rounded-[4px] !py-0.5 min-h-[40px] !h-auto',
              )}
              readOnly={preview || checkType?.() ? false : true}
            />
          </div>
        ) : (
          <div
            className={clsx(
              'h-12 flex w-[27rem] items-center  ',
              preview && 'w-full',
              previewMobileStyle ? 'pl-0' : 'pl-4',
            )}
          >
            <Input
              name={`textInput${extendedName}`}
              placeholder={inputPlaceHolder}
              register={methods?.register}
              onBlur={onBlur}
              control={{
                value: values[`textInput${extendedName}`],
                onChange: (e: any) => {
                  methods?.setValue(`textInput${extendedName}`, e.target.value);
                },
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  if (!preview) e.target.blur(e.target.value);
                }
              }}
              className={clsx('!mt-0 w-full ')}
              inputClassName={clsx(
                'h-[28px] !border-blue-normal-35 !rounded-[4px]',
              )}
              readOnly={preview || checkType?.() ? false : true}
            />
          </div>
        );
      case 'NUMBER_VALUE':
        return (
          <div
            className={clsx(
              'h-12 flex w-[5rem] items-center  ',
              preview && 'w-full',
              previewMobileStyle ? '' : 'pl-4',
            )}
          >
            <Input
              name={`numberTxt${extendedName}`}
              placeholder={inputPlaceHolder}
              register={methods?.register}
              onBlur={onBlur}
              type='number'
              control={{
                value: values[`numberTxt${extendedName}`],
                onChange: (e: any) => {
                  methods?.setValue(`numberTxt${extendedName}`, e.target.value);
                },
              }}
              onKeyDown={(e: any) => {
                if (e.key === '-' || e.key === '+' || e.key === '.' || e.key === ',') {
                  if (numberType === 'DECIMAL' && e.key === '.') return;
                  e.preventDefault();
                }
                if (e.key === 'Enter') {
                  e.target.blur(e.target.value);
                }
              }}
              className='!mt-0 w-full'
              inputClassName={clsx(
                'h-[28px] !border-blue-normal-35 !rounded-[4px] text-right !p-0 !pr-4 hide-arrow',
              )}
              readOnly={checkType?.() || preview ? false : true}
            />
          </div>
        );
      case 'DROPDOWN':
        return (
          <div className={clsx('items-center', preview && 'w-full mt-0 block' )} >
            {previewMobileStyle ? (
              <Radio
                register={methods?.register}
                name={`selectField${extendedName}`}
                options={questionOptions.map((item: IQuestionOptions) => ({
                  label: item?.labelTranslations?.[languageMap[defaultLocale]] || item.label,
                  value: item.value,
                }))}
                handleBlur={(e: any) => {
                  methods?.setValue(`otherDropdown-${id}`, '');
                  onBlur?.(e);
                  if (preview) setSelectedRadioBtns(e);
                }}
                optionsClassName={`w-full !gap-x-0 ${styleType === 'vertical' ? ' !block !my-1' : 'flex !flex-wrap !max-w-[464px] !justify-start'} ${previewMobileStyle ? '!max-w-full !w-full' : ''}`}
                disabled={checkType?.() || preview ? false : true}
                labelClassName={`!text-blue-oil !font-normal !text-base ${styleType === 'vertical' ? 'max-w-[40rem] min-w-[200px] !w-full' : 'text-wrap min-w-[80px] !w-auto -mt-[4px]'}`}
                className={`${previewMobileStyle ? '!px-4 !pt-4 !pb-3.5 border border-blue-ocean-deep rounded-xl w-full mb-5  !relative' : '!py-2 !pl-0 border-none'} ml-[0rem] !gap-x-[0.5rem]`}
                radioClassName='!w-4 !h-4 !min-w-4 !min-h-4 gap-x-2'
                selectedOptionClassName={previewMobileStyle ? 'bg-[rgb(255,243,243)]' : ''}
                methods={methods}
              />
            ) : (
              <Select
                control={methods?.control}
                label=''
                isSearchable={true}
                onBlur={() => {
                  methods?.setValue(`otherDropdown-${id}`, '');
                }}
                optionsClassName='!text-blue-oil'
                optWrapperClassName='!border !border-blue-normal-35 !rounded-b-[4px]'
                className={clsx(
                  'shadow-none !p-0 min-w-[120px] ',
                  preview && 'w-full',
                  previewMobileStyle ? '' : '!pl-4',
                )}
                mainClass={clsx(
                  '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[4px] !max-h-[28px] !border-blue-normal-35 !bg-white [&>div>ul]:!pl-0',
                )}
                buttonClassName={clsx('pl-4', preview && '!py-1')}
                noTranslation={true}
                isCheckIcon={true}
                options={questionOptions.map((item: IQuestionOptions) => ({
                  label: item?.labelTranslations?.[languageMap[defaultLocale]] || item.label,
                  value: item.value,
                }))}
                placeholder={intl.formatMessage({ id: 'questionnaire-field.select' })}
                selectOptClass={values[`selectField${extendedName}`] ? '' : '!text-gray-very-light'}
                name={`selectField${extendedName}`}
              />
            )}
            {values &&
              values[`selectField${extendedName}`] &&
              typeof values[`selectField${extendedName}`] === 'string' &&
              values[`selectField${extendedName}`]?.trim().toLowerCase() === 'other' && (
                <div
                  className={clsx(
                    'flex items-center w-full',
                    previewMobileStyle ? '' : 'pl-4',
                  )}
                >
                  <Input
                    name={`otherDropdown-${extendedName}`}
                    placeholder={intl.formatMessage({
                      id: 'questionnaire-field.pleaseSpecify',
                    })}
                    register={methods?.register}
                    control={{
                      value: values[`otherDropdown-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherDropdown-${extendedName}`, e.target.value);
                      },
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx('w-full', previewMobileStyle ? '!mt-0' : '!mt-3')}
                    inputClassName={clsx('h-[28px] !border-blue-normal-35 !rounded-[4px]')}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'CHECKBOX':
        if (preview && questionOptions?.length === 0)
          questionOptions = [{ id: 26424, label: '', score: null, value: 'check' }];
        return (
          <div className='h-max w-full'>
            <CheckBoxList
              register={methods.register}
              name={`checkList${extendedName}`}
              className={clsx(
                `!gap-x-0 w-full pl-4 ${styleType === 'vertical' ? '!block' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`,
                mobileView && '!grid !grid-cols-1 !pl-0',
              )}
              options={questionOptions.map((item: IQuestionOptions) => ({
                label: item?.labelTranslations?.[languageMap[defaultLocale]] || item.label,
                value: item.value,
              }))}
              otherClick={() => methods.setValue(`otherCheck-${id}`, '')}
              optionsClassName={
                previewMobileStyle
                  ? '!border !border-blue-ocean-deep !px-4 !pt-4 !pb-3.5 mt-3 !rounded-md'
                  : '!border-none !py-1.5 !pl-0 pr-4 !gap-x-[0.5rem] [&>svg]:m-[2px]'
              }
              disabled={checkType?.() || preview || previewMobileStyle ? false : true}
              checkboxClassName={clsx(
                '!w-4 !h-4 !rounded',
                previewMobileStyle && '!border-blue-ocean-deep !rounded-[4px]',
              )}
              labelClassName={clsx(
                `!text-blue-oil !font-normal !text-base ${styleType === 'vertical' ? 'max-w-[40rem] min-w-[200px] !w-full' : 'text-wrap min-w-[80px] !w-auto -mt-[4px]'}`,
                previewMobileStyle && 'grow !max-w-[170px]',
              )}
              selectedOptionClassName={previewMobileStyle ? 'bg-[rgb(255,243,243)]' : ''}
              methods={methods}
            />
            {values &&
              values[`checkList${extendedName}`] &&
              values[`checkList${extendedName}`]?.length > 0 &&
              values[`checkList${extendedName}`]?.find(
                (val: any) => val && val?.trim().toLowerCase() === 'other',
              ) && (
                <div className={clsx('flex items-center w-full')}>
                  <Input
                    name={`otherCheck-${extendedName}`}
                    placeholder={intl.formatMessage({ id: 'questionnaire-field.pleaseSpecify' })}
                    register={methods?.register}
                    control={{
                      value: values[`otherCheck-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherCheck-${extendedName}`, e.target.value);
                      },
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx('w-full', previewMobileStyle ? '!mt-3' : '!mt-2')}
                    inputClassName={clsx(
                      'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                    )}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'DATE_TIME':
        return (
          <div className='flex items-center gap-5 h-12 pl-1'>
            <Calendar
              control={methods.control}
              name={`dateTime${extendedName}`}
              label=''
              className='!border-none no-border-mui [&>div>div>input]:!text-left'
              placeholder={intl.formatMessage({ id: 'patient-status.selectADate' })}
              onBlur={!preview ? onBlur : () => {}}
              inputDisable={true}
              disabled={checkType?.() || preview ? false : true}
              type={(dateTimeType && PickerTypeEnum[dateTimeType]) || 'date'}
            />
          </div>
        );
      case 'RADIO_BUTTONS':
        return (
          <div className={`${previewMobileStyle ? clsx('h-12 items-center mt-1', preview && 'w-full') : 'h-max relative w-full pl-4'}`}>
            <Radio
              register={methods.register}
              name={`radioBtns${extendedName}`}
              options={questionOptions.map((item: IQuestionOptions) => ({
                label: item?.labelTranslations?.[languageMap[defaultLocale]] || item.label,
                value: item.value,
              }))}
              handleBlur={(e: any) => {
                methods?.setValue(`otherRadio-${id}`, '')
                onBlur?.(e);
                if (preview) setSelectedRadioBtns(e);
              }}
              optionsClassName={`w-full !gap-x-0 ${styleType === 'vertical' ? ' !block !my-1' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`}
              disabled={checkType?.() || preview ? false : true}
              labelClassName={`!text-blue-oil !font-normal !text-base ${styleType === 'vertical' ? 'max-w-[40rem] min-w-[200px] !w-full' : 'text-wrap min-w-[80px] !w-auto -mt-[4px]'}`}
              className={`${previewMobileStyle ? '!px-4 !pt-4 !pb-3.5 border border-blue-ocean-deep rounded-xl w-full mb-5 !relative' : '!py-2 !pl-0 border-none' } ml-[0rem] !gap-x-[0.5rem]`}
              radioClassName='!w-4 !h-4 !min-w-4 !min-h-4 gap-x-2'
              selectedValue={selectedRadioBtns}
              selectedOptionClassName={previewMobileStyle ? 'bg-[rgb(255,243,243)]' : ''}
              methods={methods}
            />
            {values &&
              values[`radioBtns${extendedName}`] &&
              typeof values[`radioBtns${extendedName}`] === 'string' &&
              values[`radioBtns${extendedName}`]?.trim().toLowerCase() === 'other' && (
                <div className={clsx('flex items-center w-full')}>
                  <Input
                    name={`otherRadio-${extendedName}`}
                    placeholder={intl.formatMessage({
                      id: 'questionnaire-field.pleaseSpecify',
                    })}
                    control={{
                      value: values[`otherRadio-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherRadio-${extendedName}`, e.target.value);
                      },
                    }}
                    register={methods?.register}
                    // onBlur={onBlur}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx(
                      '!mt-0 w-full', previewMobileStyle ? 'mb-3' : '',
                    )}
                    inputClassName={clsx(
                      'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                    )}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'FILE_UPLOAD':
        return (
          <div className={`h-12 flex items-center pl-3 ${previewMobileStyle && 'mt-4'}`}>
            <FileUploadWithProgressBar
              register={methods.register}
              disabled={checkType?.() || preview ? false : true}
              name={`fileUpload${extendedName}`}
              label='Upload file'
              uploadId={-1}
              progress={0}
              imageCard={true}
              loading={uploadLoading}
              accept='.png,.jpeg,.WebP,.HEIF,.pdf,.word,.txt,.ODF'
              handleUpload={(e: any) => {
                methods?.setValue(`fileUpload${extendedName}`, e);
              }}
              control={methods?.control}
            />
          </div>
        );
      case 'RATING':
        return (
          <div
            className={clsx(
              ' ',
              previewMobileStyle
                ? 'mx-auto flex border-[1px] border-[#0973D4_25%] flex-wrap h-auto min-h-12 mt-4 pl-[2px] [&>div]:flex-wrap [&>div]:gap-x-[2px] [&>div]:pr-0'
                : 'h-12 pl-4',
            )}
          >
            <RatingStar
              control={methods.control}
              name={`rating${extendedName}`}
              stars={stars}
              onBlur={(e) => !preview && onBlur?.(e)}
              disabled={checkType?.() || preview ? false : true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const isStyledQuestion = !['TITLE'].includes(questionType) && !parentQuestionId;
  const questionNumbering = titleQuestionId ? String.fromCharCode(65 + (questionNumber || 0)).toLowerCase() : questionNumber;
  // const isStyledQuestion = ['REPEATED', 'TITLE']?.includes(questionType);
  return (
    <div
      className={clsx(
        `${title && !parentQuestionId && !isLastQuestion && (questionType !== 'TITLE' || (questionType === 'TITLE' && isQuestionEmpty)) && (!titleQuestionId || isSubLastQuestion) ? `border-b ${previewMobileStyle && questionType === 'SCALE' ? '' : 'pb-[24px]'  } mb-[20px] pt-[4px]` : ''}`,
        previewMobileStyle ? 'pl-2 border-none ' : 'pl-[32px] pr-2 border-pink-lace border-dashed ',
      )}
    >
      <div
        className={clsx(
          'questionValues group flex justify-between gap-4 h-max mb-1 items-center',
          previewMobileStyle && 'w-full [&>div]:w-full',
        )}
        key={title}
      >
        {parentQuestionId === null &&
          (questionType === 'REPEATED' ? (
            <PreRepeatedQuestion
              repeatedQuestions={repeatedQuestions}
              title={quest?.titleTranslations?.[languageMap[defaultLocale]]  || title}
              preview={preview}
              checkType={checkType}
              questionOptions={questionOptions?.length > 0 ? questionOptions : []}
              PickerTypeEnum={PickerTypeEnum}
              setSelectedTable={setSelectedTable}
              selectedTable={selectedTable}
              questionNumber={questionNumber}
            />
          ) : (
            <div className='w-full'>
              {!titleQuestionId ? (
                <div
                  className={clsx(
                    'flex justify-between items-center w-full',
                    previewMobileStyle && 'flex-col ',
                  )}
                >
                  <div className={clsx(previewMobileStyle && 'w-full')}>
                    <p
                      className={clsx(
                        ' mr-1 w-[375px] flex text-left break-words ',
                        previewMobileStyle
                          ? 'w-full !text-[rgb(14,49,87)] font-normal text-left'
                          : 'font-normal text-blue-oil font-sans',
                      )}
                    >
                      {isStyledQuestion ? (
                        <p className="flex">
                          <span className="mr-1"> {`${questionNumbering}. `} </span>
                          <p className={`${previewMobileStyle ? 'max-w-[200px] w-full' : 'w-[90%] min-w-[375px]'} px-1 mr-0.5`} dangerouslySetInnerHTML={{ __html: questionType !== 'TITLE' && required ? addAsterisk(title) : title }}></p>
                        </p>
                      ) : (
                        `${questionNumbering}. ${quest?.titleTranslations?.[languageMap[defaultLocale]] || title} ${questionType !== 'TITLE' && required ? '*' : ''}`
                      )}
                    </p>
                    {subtitle && (
                      <p
                        className={clsx(
                          'mr-1  mt-4 w-[375px] text-left break-words ',
                          previewMobileStyle
                            ? 'w-full text-[rgb(7,44,63)] font-normal text-left'
                            : 'font-normal text-blue-oil font-sans !pl-[1.4rem]',
                        )}
                      >
                        {quest?.subtitleTranslations?.[languageMap[defaultLocale]] || subtitle}
                      </p>
                    )}
                  </div>
                  {questionType !== 'TITLE' && (
                    <div
                      className={clsx(
                        'max-w-[30rem] w-[30rem] flex justify-start',
                        previewMobileStyle && 'w-full ',
                      )}
                    >
                      {questionType && renderAnswer()}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={clsx(
                    'flex justify-between items-center w-full',
                    previewMobileStyle && 'flex-col ',
                  )}
                >
                  <div className={clsx(previewMobileStyle && 'w-full')}>
                    <p
                      className={clsx(
                        ' pl-[1.4rem] w-[375px] text-left break-words ',
                        previewMobileStyle
                          ? 'w-full !text-[rgb(14,49,87)] font-normal text-left !pl-0'
                          : 'font-normal text-blue-oil font-sans',
                      )}
                    >
                      {isStyledQuestion ? (
                        <p className='flex'>
                          {`${questionNumbering}. `}{' '}
                          <p className={`${previewMobileStyle ? 'w-[55%]' : 'w-[90%] min-w-[375px]'} px-1`} dangerouslySetInnerHTML={{ __html: questionType !== 'TITLE' && required ? addAsterisk(title) : title }}></p>
                        </p>
                      ) : (
                        `${questionNumbering}. ${quest?.titleTranslations?.[languageMap[defaultLocale]] || title}${questionType !== 'TITLE' && required ? ' *' : ''}`
                      )}
                    </p>
                  </div>
                  {questionType !== 'TITLE' && (
                    <div
                      className={clsx(
                        'max-w-[30rem] w-[30rem] my-3 flex justify-start',
                        previewMobileStyle && 'w-full ',
                      )}
                    >
                      {questionType && renderAnswer()}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Question;
