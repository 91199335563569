import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from '@ckeditor/ckeditor5-core';
import { UploadAdapter, FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useRef, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import {
  addStaticQuestionToQuestionnaire, questionnairesTranslateEntity,
} from 'services/api/questionnaire';
import { IQuestion } from 'types/questionnaire';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useStudyStore } from 'store/StudyStore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'components/common';
import SubDropdown from 'components/common/SubDropdown';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FiLoader } from 'react-icons/fi';
import './editor.css';
import { FormattedMessage, useIntl } from 'react-intl';

interface PluginInterface {
  createUploadAdapter: (loader: any) => any;
}

interface IQuestionForm {
  id: number;
  questionType: string;
  data: any;
}

interface IProps {
  consentFormContent: string;
  setConsentFormContent: Dispatch<SetStateAction<string>>;
  refetchStudy: any;
  setPage?: any;
}

function uploadAdapter(loader: FileLoader): UploadAdapter {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        const file = await loader.file;
        const reader: any = new FileReader();
        console.log('file is: ', file, reject);
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve({ default: base64String });
        };
        reader.readAsDataURL(file);
      });
    },
    abort: () => {},
  };
}

function uploadPlugin(editor: Editor) {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository') as PluginInterface;
  fileRepositoryPlugin.createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

const ConsentForm = ({
  setPage,
  consentFormContent,
  setConsentFormContent,
  refetchStudy,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<File[]>([]);
  const [translation, setTranslation] = useState<any>(null);
  const [translatedText, setTranslatedText] = useState<any>(null);
  const [editTranslation, setEditTranslation] = useState<any>(false);
  const [editText, setEditText] = useState<any>(false);
  const [isAutoLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { studyId } = useParams();
  const intl = useIntl();
  const { currentStudy } = useStudyStore();
  const { showTranslate } = useQuestionnaireStore();

  useEffect(() => {
    setImages([]);
  }, []);

  useEffect(() => {
    async function convertFunc() {
      const arr: any = [];
      for (const element of images) {
        const base64String = await convertFileToBase64(element);
        arr.push(base64String);
      }
    }
    convertFunc();
  }, [images]);

  useEffect(() => {
    if (currentStudy?.consentQuestionnaire?.staticContents?.[0]) {
      setConsentFormContent(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.instruction);
    }
  }, [currentStudy?.consentQuestionnaire?.staticContents]);

  useEffect(() => {
    if (translation && currentStudy?.consentQuestionnaire?.staticContents?.[0] && currentStudy?.consentQuestionnaire?.staticContents?.[0]?.instructionTranslation[translation?.toUpperCase()]) {
      setTranslatedText(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.instructionTranslation[translation?.toUpperCase()]);
    } else {
      setTranslatedText('');
    }
  }, [currentStudy?.consentQuestionnaire?.staticContents, translation]);

  useEffect(() => {
    if (
      currentStudy?.consentQuestionnaire?.active == false &&
      location?.pathname?.includes('consent')
    ) {
      navigate(`/study/${studyId}/study-builder`);
      setPage('');
    }
  }, [currentStudy?.consentQuestionnaire?.active]);

  const { mutate: addStaticQuestionMutate } = useMutation<
    IQuestion,
    unknown,
    [IQuestionForm],
    unknown
  >(
    (data: [IQuestionForm]) =>
      addStaticQuestionToQuestionnaire(data, currentStudy?.consentQuestionnaire?.id),
    {
      onSuccess: () => {
        refetchStudy();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: consentTranslationEntity } = useMutation<any, unknown, any, unknown>(
    (value: string) =>
      questionnairesTranslateEntity(
        currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id,
        translation?.toUpperCase(),
        'consent',
        parseInt(`${studyId}`, 10),
        value,
      ),
    {
      onSuccess() {
        refetchStudy();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        if (showTranslate && translation && editTranslation && translatedText) {
          consentTranslationEntity(translatedText);
          setEditTranslation(false);
        }
        if (editText) {
          const body: any = {
            staticContentType: 'INSTRUCTION',
            data: {
              ...(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id && {
                id: currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id,
              }),
              sortOrder: 1,
              instruction: consentFormContent,
            },
          };
          addStaticQuestionMutate([body]);
          setEditText(false);
        }
      }
    },
    [consentFormContent, translation, showTranslate, editTranslation, editText, translatedText],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const langOpts = [
    { value: 'en', label: 'lang.english' },
    { value: 'pt', label: 'lang.portuguese' },
    { value: 'ja', label: 'lang.japanese' },
    { value: 'fr', label: 'lang.french' },
  ];

  const getLabel = () => {
    return langOpts?.find((option: any) => translation && `${translation}` === `${option?.value}`)?.label;
  }

  return (
    <div
      className={`${showTranslate ? 'max-h-full' : ' max-h-[calc(100vh-60px)] '}
        w-full h-full max-h-[calc(100vh-60px)] min-h-full bg-white border-[1px] border-purple rounded-lg
      `}
    >
      <h3 className='text-[20px] text-blue-ocean-deep font-medium leading-[28px] text-left py-[28px] pl-[43px] border-b-2 border-b-purple cursor-pointer pb-4'>
        <FormattedMessage id='study-builder.consentForm' />
      </h3>
      <div
        className={`${showTranslate ? 'h-[96vh] ' : ' h-[69vh] '} md:mb-6 mt-9 bg-white rounded-3xl `}
      >
        <div
          className={
            showTranslate ? 'h-1/2 max-h-1/2 overflow-y-scroll' : 'h-full overflow-y-scroll'
          }
        >
          {showTranslate && (
            <div className='m-4'>
              <div className='flex justify-end mb-6'>
                <Tooltip
                  className='ml-16 -mt-[5px]'
                  text={intl.formatMessage({ id: 'study-builder.tooltip.selectTranslation' })}
                >
                  <SubDropdown
                    name='translation'
                    customControl={{
                      value: translation || '',
                      onChange: (e: any) => setTranslation(e),
                    }}
                    defaultIsOpen={false}
                    placeholder={intl.formatMessage({ id: 'study-builder.selectTranslation' })}
                    options={langOpts}
                  />
                </Tooltip>
              </div>
              <div className='flex flex-col items-center justify-center mb-6'>
                <p className='text-center font-semibold text-blue-normal pb-[4px]'>
                  <FormattedMessage id='study-builder.originalLanguage' />
                </p>
                <div className='h-[1px] w-[9.75rem] bg-blue-normal' />
              </div>
            </div>
          )}
          <div ref={containerRef} className={`${showTranslate ? '[&>.ck-content]:h-[28vh]' : '[&>.ck-content]:h-[60vh]'} md:m-4 [&>.ck>.ck-editor__top]:!border-blue-ocean-deep [&>.ck-content]:!border-blue-ocean-deep [&>.ck-toolbar]:!mb-2 [&>.ck-content]:overflow-y-auto [&>.ck-content]:text-blue-ocean-deep [&>.ck-content]:overflow-x-hidden`}>
            <CKEditor
              config={
                {
                  extraPlugins: [uploadPlugin],
                  toolbar: [
                    'heading',
                    'alignment',
                    '|',
                    'fontColor',
                    'FontSize',
                    'fontFamily',
                    'fontBackgroundColor',
                    'bold',
                    'italic',
                    '|',
                    'imageUpload',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'indent',
                    'todoList',
                    'outdent',
                    '|',
                    'blockQuote',
                    'undo',
                    'redo',
                  ],
                  fontSize: {
                    options: [
                      'default',
                      9,
                      11,
                      13,
                      14,
                      15,
                      16,
                      17,
                      18,
                      20,
                      19,
                      21,
                      22,
                      23,
                      24,
                      25,
                      26,
                      27,
                      28,
                      29,
                      30,
                      34,
                    ],
                  },
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'headingFancy',
                        view: {
                          name: 'h2',
                          classes: ['fancy', 'text-red'],
                        },
                        title: 'Heading 2 (fancy)',
                        class: 'ck-heading_heading2_fancy',
                        converterPriority: 'high',
                      },
                    ],
                  },
                } as any
              }
              editor={DecoupledEditor}
              onReady={(editor) => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement(),
                  );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setConsentFormContent(data);
                setEditText(true);
              }}
              data={consentFormContent}
            />
          </div>
        </div>
        {showTranslate && <hr className='bg-purple w-full mb-5 mt-[0.938rem]' />}
        {showTranslate && (
          <div className='h-1/2 max-h-1/2 overflow-y-scroll'>
            <div className='w-full'>
              {translation &&
                (isAutoLoading ? (
                  <div className='flex my-[9rem] flex-col justify-center'>
                    <FiLoader className='w-[4.2rem] h-[4.2rem] mb-6 animate-spin place-self-center text-blue-ocean-deep' />
                    <p className='text-blue-oil text-sm'>
                      <FormattedMessage id='study-builder.loadingTranslationWait' />
                    </p>
                  </div>
                ) : (
                  <div className='relative md:m-4'>
                    <div className='flex flex-col items-center justify-center mb-6'>
                      <p className='text-center font-semibold text-blue-normal pb-[4px]'>
                        {intl.formatMessage({ id: getLabel() })}
                      </p>
                      <div className='h-[1px] w-[9.75rem] bg-blue-normal' />
                    </div>
                    <div ref={containerRef} className='[&>.ck-content]:h-[29vh] [&>.ck>.ck-editor__top]:!border-blue-ocean-deep [&>.ck-content]:!border-blue-ocean-deep [&>.ck-toolbar]:!mb-2 [&>.ck-content]:overflow-y-auto [&>.ck-content]:text-blue-ocean-deep [&>.ck-content]:overflow-x-hidden'>
                      <CKEditor
                        config={
                          {
                            extraPlugins: [uploadPlugin],
                            toolbar: [
                              'heading',
                              'alignment',
                              '|',
                              'fontColor',
                              'FontSize',
                              'fontFamily',
                              'fontBackgroundColor',
                              'bold',
                              'italic',
                              '|',
                              'imageUpload',
                              '|',
                              'bulletedList',
                              'numberedList',
                              'indent',
                              'todoList',
                              'outdent',
                              '|',
                              'blockQuote',
                              'undo',
                              'redo',
                            ],
                            fontSize: {
                              options: [
                                'default',
                                9,
                                11,
                                13,
                                14,
                                15,
                                16,
                                17,
                                18,
                                20,
                                19,
                                21,
                                22,
                                23,
                                24,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                34,
                              ],
                            },
                            heading: {
                              options: [
                                {
                                  model: 'paragraph',
                                  title: 'Paragraph',
                                  class: 'ck-heading_paragraph',
                                },
                                {
                                  model: 'heading1',
                                  view: 'h1',
                                  title: 'Heading 1',
                                  class: 'ck-heading_heading1',
                                },
                                {
                                  model: 'heading2',
                                  view: 'h2',
                                  title: 'Heading 2',
                                  class: 'ck-heading_heading2',
                                },
                                {
                                  model: 'headingFancy',
                                  view: {
                                    name: 'h2',
                                    classes: ['fancy', 'text-red'],
                                  },
                                  title: 'Heading 2 (fancy)',
                                  class: 'ck-heading_heading2_fancy',
                                  converterPriority: 'high',
                                },
                              ],
                            },
                          } as any
                        }
                        editor={DecoupledEditor}
                        onReady={(editor) => {
                          editor.ui
                            .getEditableElement()
                            .parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement(),
                          );
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setTranslatedText(data);
                          setEditTranslation(true);
                        }}
                        data={translatedText || ''}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ConsentForm;
