import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FiLoader } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';
import { fetchPatientsData } from '../../../../services/api/patients';
import { useQuery } from '@tanstack/react-query';
// import DotsNavigation from './DotsNavitation';
// import { useStudyStore } from 'store/StudyStore';
// import { IPagable } from 'services/api/patientProfile';

const PatientRecruitment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({ content: [], totalElements: 0 });
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [, setActiveRowId] = useState<string | null>(null);
  // const { loggedInStudyUser } = useStudyStore();
  
  // const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const { data: patientRecruiments, isFetching } = useQuery({
    queryKey: ['patients-recruitment', currentPage],
    queryFn: () =>
      fetchPatientsData({
        page: currentPage,
        size: pageSize,
        sort: [''],
      }),
    keepPreviousData: true,
  });

  const getLocalTime = (gmtDateString: string) => {
    const gmtDate = new Date(gmtDateString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    gmtDate.setMinutes(gmtDate.getMinutes() - localTimeZoneOffset);
  
    const formattedDate = gmtDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    const formattedTime = gmtDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  
    return `${formattedDate}, ${formattedTime}`;
  };

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight
    ) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    if (data.content.length < data.totalElements && !isFetching) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (patientRecruiments) {
      setData((prevData: any) => ({
        content: [...prevData.content, ...patientRecruiments.content],
        totalElements: patientRecruiments.totalElements,
      }));
      setIsLoading(false);
    }
  }, [patientRecruiments]);

  const intl = useIntl();
  const methods = useForm();

 // const renderStatus = (status: string) => {
  //   switch (status) {
  //     case 'INVITED':
  //       return <div className='flex items-center'>
  //         <FiMail className='text-blue-ocean-deep w-3.5	h-3.5 mr-2'/>
  //         <p><FormattedMessage id={patient-recruitment.${status}} /></p>
  //       </div>
  //   }
  // }

  const renderEligibility = (eligible: boolean | null | undefined) => {
    if (eligible === null || eligible === undefined) {
      return null;
    }
  
    return eligible ? (
      <div className="p-1.5 bg-green-confirmation rounded-[2px]">
        <FormattedMessage id="yes" />
      </div>
    ) : (
      <div className="p-1.5 bg-error-feedback text-white rounded-[2px]">
        <FormattedMessage id="no" />
      </div>
    );
  };

  // const handleSuccess = (success: boolean) => {
  //   if (success) {
  //     setCurrentPage(0);
  //     setData({ content: [], totalElements: 0 });
  //     refetch();
  //   }
  // }

  const headerItems: any = [
    {
      label: intl.formatMessage({ id: 'patient-enrollment.patientId' }),
      value: 'id',
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.patientName' }),
      value: 'firstName',
      renderCol: (__value: any, record: any) => {
        return (
          <div className='flex justify-between w-[150px] max-w-[150px] items-center'>
            {record?.firstName ?? '' + ' ' + record?.lastName ?? ''}
            
          </div>
        )
      }
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.prescreenedPatients' }),
      value: 'prescreenedPatients',
      renderCol: (value: any, record: any) => {
        return (
          <div className='flex justify-between w-[150px] max-w-[150px] items-center'>
            {intl.formatMessage({ id: 'patient-recruitment.byMiloCopilot' })}
            {record?.copilotStudyIncluded == true || record?.copilotStudyIncluded == false && (
              <div className='border border-blue-normal text-xs rounded-[2px] text-blue-normal p-1 ml-2'>
                  {record?.copilotPatientTopScore ?? 0}/{record?.copilotStudyMaxScore ?? 0}
              </div>
)}         </div>
        )
      }
    },
    // {
    //   label: intl.formatMessage({ id: 'patient-recruitment.prescreenedOn' }),
    //   value: 'createdAt',
    //   renderCol: (value: any) => format(new Date(value), 'dd MMM yyyy, HH:mm')
    // },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.eligibility' }),
      value: 'copilotStudyIncluded',
      renderCol: (value: any) => renderEligibility(value)
    },
    // {
    //   label: intl.formatMessage({ id: 'patient-recruitment.verifiedOn' }),
    //   value: 'createdAt',
    //   renderCol: (value: any) => format(new Date(value), 'dd MMM yyyy, HH:mm')
    // },
    {
      label: intl.formatMessage({ id: 'patient-enrollment.site' }),
      value: 'site',
      renderCol: (value: any, record: any) => {
        return (
          <div className='flex justify-between w-[150px] max-w-[150px] items-center'>
            {record?.copilotSite?.name}
          </div>
        )
      }
      
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.investigatorName' }),
      value: 'copilotInviterName'
    },
    // {
    //   label: intl.formatMessage({ id: 'patient-recruitment.inviteStatus' }),
    //   value: 'inviteStatus',
    //   renderCol: () => renderStatus('INVITED')
    // },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.invitedOn' }),
      value: 'createdAt',
      renderCol: (value: any) => getLocalTime(value)
    }
  ];

  return (
    <div className="flex flex-col justify-start w-full" id="wrapper">
      <div className="flex justify-between items-end">
        <h1 className="text-left text-2xl mt-9 mb-4 pl-4 text-blue-ocean-deep font-sans text-[40px]">
          <FormattedMessage id="patient-recruitment" />
        </h1>
      </div>
      <div
        className="bg-white mt-4 pt-0 rounded-lg w-full max-w-[calc(100vw_-_274px)] h-[calc(100vh_-_185px)] overflow-auto no-scrollbar relative border-[1px] border-purple"
        ref={containerRef}
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div className="p-4 text-center flex justify-center items-center w-full">
            <FiLoader className="w-20 h-20 animate-spin place-self-center text-blue-ocean-deep" />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form>
              <table className="mb-10 bg-white w-full min-w-full table-auto">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    {headerItems.map((item: any) => (
                      <th
                        key={item.label}
                        className="px-4 py-3 text-left font-semibold text-blue-ocean-deep text-base tracking-wider min-w-[160px]"
                      >
                        {item.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.content.map((row: any) => (
                    
                    <tr key={row.id} className="hover:bg-slate-200 hover:bg-blue-light-100 hover:font-bold hover:text-black group"  onMouseOver={() => setActiveRowId(row.id)}
                    onMouseOut={() => setActiveRowId(null)}>
                      {headerItems.map((column: any) => (
                        <td
                          key={column.label}
                          className="px-4 py-3 text-left text-dark border-b text-sm border-gray-light"
                        >
                          <div className="flex group">
                            {column?.renderCol
                              ? column.renderCol?.(row[column.value], row)
                              : row[column.value]}
                          </div>
                        </td>
                      ))}
                      {/* {isSiteStaff && <DotsNavigation onClick={(e: any) => {e?.preventDefault(); e?.stopPropagation()}} active={activeRowId === row.id} patientId={row?.id} centerId={loggedInStudyUser?.centerId ?? ''} onSuccess={handleSuccess} />} */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </FormProvider>
        )}
        {isFetching && (
          <div className="p-4 text-center flex justify-center items-center w-full">
            <FiLoader className="w-6 h-6 animate-spin text-blue-ocean-deep" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientRecruitment;
