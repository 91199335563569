import { memo, useCallback, useEffect, useRef, useState } from 'react';
import AgoraUIKit, { layout, PropsInterface } from 'agora-react-uikit';
import { FiClock, FiFileText, FiXCircle } from 'react-icons/fi';
import { Duration, intervalToDuration, isBefore } from 'date-fns';
import clsx from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'components/common';
import { endAppointment, fetchAppointments, getPatientSteps, getUserStatusAgora } from 'services/api/appointment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParticipantStore } from 'store/participantStore';
import { useStudyStore } from 'store/StudyStore';
import { toast } from 'react-toastify';
import useAuthStore from 'store/authStore';
import { FormattedMessage, useIntl } from 'react-intl';
import { endConsetFormMeeting, getConsetFormPatient } from 'services/api/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';

interface VideoParams {
  channel: string;
  appId: string;
  token: string;
  studyId: string;
  participantId: string;
}

function VideoCall() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [timeElapsed, setTimeElapsed] = useState<Duration | null>(null);
  const [displayEConsent, setDisplayEConsent] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [consentData, setConsentData] = useState('');
  const { loggedInStudyUser } = useStudyStore();
  const eConsentContainerRef = useRef<HTMLElement>(null);
  const { setIsDoneMeeting } = useQuestionnaireStore();
  const { participant } = useParticipantStore();
  const { userData } = useAuthStore();

  const { channel, appId, token, studyId, participantId } = useParams<
    keyof VideoParams
  >() as VideoParams;

  const agoraProps: PropsInterface = {
    rtcProps: {
      appId,
      channel,
      token: decodeURIComponent(token),
      layout: layout.pin,
      disableRtm: true,
      uid: userData?.userAccount?.id,
    },
  };

  const { data: agoraUser, refetch: agoraRefetch } = useQuery({
    queryKey: ['agoraUser'],
    queryFn: () => isModalOpen && state && state?.appointment?.patient && appId && channel && getUserStatusAgora(appId, state?.appointment?.patient?.userProfile?.userAccount?.id, channel),
  });

  const { data: stepsData, refetch: stepRefetch } = useQuery({
    queryKey: ['stepsData'],
    queryFn: () => (state?.page.includes('consentForm') && (participantId || participant !== 'all')) && getPatientSteps(participantId || participant),
  });

  const { refetch } = useQuery({
    queryKey: ['appointmments'],
    queryFn: () => state?.page.includes('appointment') && participantId && loggedInStudyUser && fetchAppointments(parseInt(`${participantId}`, 10), parseInt(`${loggedInStudyUser?.id}`)),
  });

  const { refetch: refetchConsent } = useQuery({
    queryKey: ['consetFormPatient', participantId],
    queryFn: () => state?.page.includes('consentForm') && getConsetFormPatient(participantId as string),
  });

  const { mutate: closeAppointment } = useMutation(
    () => endAppointment(state?.id),
    {
      onSuccess: ()  => {
        refetch();
        toast.success(intl.formatMessage({ id: 'appointment.meetingEnd' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: closeConsentMeeting } = useMutation(
    (meetingNo: number) => endConsetFormMeeting(state?.id, meetingNo),
    {
      onSuccess: () => {
        const meetNo = state?.page?.split('-');
        if (state && state?.end && isBefore(new Date(state?.end), new Date())) {
          setIsDoneMeeting(parseInt(meetNo[1], 10));
        }
        toast.success(intl.formatMessage({ id: 'appointment.meetingEnd' }));
        refetchConsent();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  useEffect(() => {
    if (state?.page.includes('consentForm') && (participantId || participant !== 'all')) {
      stepRefetch();
    }
  }, [participantId, participant, state]);

  useEffect(() => {
    if (stepsData && stepsData?.length > 0) {
      setConsentData('');
      stepsData?.map((step: any) => {
        let newText = '';
        if (step?.step.questionnaire && step?.step?.questionnaire?.type === 'CONSENT') {
          step?.step?.questionnaire?.staticContents &&
            step?.step?.questionnaire?.staticContents?.length > 0 &&
            step?.step?.questionnaire?.staticContents?.map((stData: any) => {
              newText = newText + ' ' + stData?.header;
              setConsentData(newText);
            })
        }
      });
    }
  }, [stepsData]);

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setCurrentDate(new Date());
      if (startDate && currentDate) {
        setTimeElapsed(intervalToDuration({ start: startDate, end: currentDate }));
      }
    }, 1000);

    return () => clearInterval(dateInterval);
  }, [startDate, currentDate]);

  const getConsentTextContainerHeight = useCallback(() => {
    if (eConsentContainerRef.current) {
      let currentTextContainerHeight = eConsentContainerRef.current.clientHeight;
      const eConsentContainerPadding = parseInt(
        getComputedStyle(eConsentContainerRef.current).padding.split('p')[0],
      );

      currentTextContainerHeight -= eConsentContainerPadding * 1;

      const header = eConsentContainerRef.current.firstElementChild;
      if (header && 'computedStyleMap' in header) {
        currentTextContainerHeight -= header.clientHeight;

        const headerMarginBottom = parseInt(getComputedStyle(header).marginBottom.split('p')[0]);
        currentTextContainerHeight -= headerMarginBottom;
      }

      return currentTextContainerHeight;
    }

    return 'auto';
  }, [eConsentContainerRef.current]);

  useEffect(() => {
    isModalOpen && agoraRefetch();
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLeaveCall = () => {
    setStartDate(null);
    if (state?.page.includes('appointment')) {
      state?.id && agoraUser?.data?.in_channel && closeAppointment();
      navigate(`/study/${studyId}/participants/${participantId}/appointment`, {
        state: { endedCall: true },
      });
    } else if (state?.page.includes('participant-video')) {
      navigate(`/study/${studyId}/participants/${participantId}/video`, {
        state: { endedCall: true },
      });
    } else if (state?.page.includes('consentForm')) {
      const meetNo = state?.page?.split('-');
      state?.id && agoraUser?.data?.in_channel && closeConsentMeeting(meetNo[1]);
      navigate(`/study/${studyId}/participants/${participantId}/questionnaire-status/consent`, {
        state: { endedCall: true },
      });
    }
  };

  const callbacks = {
    EndCall: () => {
      openModal();
    },
  };

  return (
    <>
      <Modal
        className={{
          content: 'p-8',
        }}
        hideCloseIcon
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <h1 className='text-blue-oil mb-6 text-2xl font-normal font-serif leading-[34px] tracking-tight'>
          <FormattedMessage id="video-call.leaveTheCall" />
        </h1>
        <p className='text-xl mb-12 max-w-[34rem] font-medium leading-7'>
          <FormattedMessage id="video-call.youAreCurrently" />
        </p>
        <div className='flex gap-3 justify-end'>
          <button
            type='button'
            title='close-modal'
            onClick={closeModal}
            className='h-10 p-2.5 px-11 text-base-white bg-blue-ocean-deep rounded-[90px] justify-center items-center gap-2.5 inline-flex'
          >
            <FormattedMessage id="no" />
          </button>
          <button
            type='button'
            title='leave-call'
            onClick={handleLeaveCall}
            className='h-10 p-2.5 px-11 rounded-[90px] border border-blue-ocean-deep text-blue-ocean-deep justify-center items-center gap-2.5 inline-flex'
          >
            <FormattedMessage id="leave" />
          </button>
        </div>
      </Modal>
      <div className='flex flex-col bg-gray-dark absolute left-0 top-0 z-50 w-screen h-screen'>
        <header className='bg-gray-dark mb-0 p-4 flex z-50 h-20 justify-between items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='49'
            height='49'
            viewBox='0 0 49 49'
            fill='none'
          >
            <rect width='49' height='49' rx='24.5' fill='white' />
            <line
              x1='10.9379'
              y1='32.4481'
              x2='10.9379'
              y2='19.0827'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='30.27'
              y1='18.2375'
              x2='39.6421'
              y2='27.6097'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='20.4292'
              y1='27.382'
              x2='11.421'
              y2='19.3701'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <line
              x1='29.8264'
              y1='18.3947'
              x2='21.1056'
              y2='27.2605'
              stroke='#012C54'
              strokeWidth='3'
              strokeLinecap='round'
            />
          </svg>
          <div onClick={openModal} className='flex items-center cursor-pointer'>
            <p className='text-white text-lg pr-3'><FormattedMessage id='close' /></p>
            <FiXCircle className='text-2xl text-white' />
          </div>
        </header>
        <AgoraUIKit
            styleProps={{
              pinnedVideoContainer: {
                position: 'relative',
                display: 'block',
                borderRadius: '10px', 
                width: displayEConsent ? '35%' : '100%',
                height: '100%',
                zIndex: 1,
              },
              maxViewContainer: {
                position: 'relative',
                width: '100%',
                height: '100%'
              },
              maxViewStyles: {
                position: 'relative',
                width: '100%',
                height: '100%'
              },
              localBtnContainer: {
                backgroundColor: 'transparent',
                justifyContent: 'center',
                gap: '1.63rem',
                height: '7rem',
              },
              BtnTemplateStyles: {
                backgroundColor: '#f5f5ff',
                width: '3.5rem',
                height: '3.5rem',
              },
              localBtnStyles: {
                endCall: {
                  backgroundColor: '#f5f5ff',
                },
              },
              theme: '#004381',
              UIKitContainer: { backgroundColor: '#403F3F', marginRight: '2rem', marginLeft: '2rem' },
              minViewContainer: {
                position: 'absolute',
                bottom: '1rem',
                right: '0rem',
                width: '100%',
                height: '100%',
                zIndex: 2,
                left: '2rem',
                minWidth: 'auto'
              },
              minViewStyles: {
                position: 'absolute',
                width: '196px',
                height: '124px',
                borderRadius: '10px', 
                bottom: '1rem',
                right: '4rem'
              }
            }}
            rtcProps={agoraProps.rtcProps}
            callbacks={callbacks}
          />
        <article
          ref={eConsentContainerRef}
          className={clsx(
            'bg-base-white  min-h-[200px] rounded-[2rem] absolute z-[60] min-w-[30rem] w-[59%] py-6 max-h-[78.5vh] overflow-hidden right-8 bottom-[7rem]',
            displayEConsent ? 'visible' : 'invisible',
          )}
        >
          <h1 className='leading-7 px-6 mb-3 text-xl font-medium text-blue-ocean-deep text-left'>
            <FormattedMessage id="video-call.pleaseRead" />
          </h1>
          <div
            className='overflow-auto pl-6 pr-4 mr-2 scrollbar-thumb-rounded-2xl scrollbar-thumb-gray-very-light scrollbar-thin  max-h-[calc(100%-3.5rem-1.5rem-0.75rem)] pb-6'
            style={{
              height: getConsentTextContainerHeight(),
            }}
          >
            <div className='text-left' dangerouslySetInnerHTML={{ __html: (state && state?.consentData) ? state?.consentData : consentData }} />
          </div>
        </article>
        <div className='bg-[#f5f5ff] text-gray-dark rounded-3xl gap-2 text-center flex items-center p-1 px-2 absolute bottom-7 left-8'>
          <FiClock className='w-6 h-6' />{' '}
          <span className='text-xs font-medium text-gray-dark'>
            {!timeElapsed
              ? '00:00 Minutes'
              : `${(timeElapsed?.minutes || timeElapsed?.minutes === 0) && timeElapsed?.minutes < 10
                ? timeElapsed?.minutes.toString().padStart(2, '0')
                : timeElapsed?.minutes
              }:${(timeElapsed?.seconds || timeElapsed?.seconds === 0) && timeElapsed?.seconds < 10
                ? timeElapsed?.seconds.toString().padStart(2, '0')
                : timeElapsed?.seconds
              } Minutes`}
          </span>
        </div>
        {state && state?.page !== 'appointment' && <button
          type='button'
          title='e-consent'
          onClick={() => setDisplayEConsent((prevState) => !prevState)}
          className={clsx(
            'z-[61] rounded-full gap-2 text-center flex items-center p-3 absolute bottom-7 right-8',
            displayEConsent ? 'bg-blue-ocean-deep text-base-white' : 'bg-[#f5f5ff] text-gray-dark',
          )}
        >
          <FiFileText className='w-8 h-8' />
        </button>}
      </div>
    </>
  );
}

export default memo(VideoCall);
