// import { useEffect } from 'react';
import { IQuestionnaire } from 'types/questionnaire';
import Question from './Question';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FormProvider, useForm } from 'react-hook-form';
import { IAddAnswerForm, IAnswer } from 'types/step';
import useAuthStore from 'store/authStore';
import { format } from 'date-fns';
import clsx from 'classnames';
import MobileView from './MobileView';
import { ToggleButton, Tooltip } from 'components/common';
import { FormattedMessage, useIntl } from 'react-intl';
import './style.css';
import { languageMap } from 'types/util';
import { useContext, useState } from 'react';
import { LanguageContext } from 'pages/App/LanguageContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getLocaleTranslation } from '../../../../../utils/string';

export const PickerFormatEnum: any = {
  DATE: 'MM/dd/yyyy',
  TIME: 'hh:mm aa',
  DATE_AND_TIME: 'MM/dd/yyyy-hh:mm aa',
  YEAR: 'yyyy',
  YEAR_AND_MONTH: 'MM/yyyy',
};

interface PreviewQuestionsProps {
  questionnaire?: IQuestionnaire;
  preview?: boolean;
  styleType?: string;
  setStyleType?: any;
  handleType?: any;
  initQuestionnaire?: any;
  setChangeQuestionnaireConfirmation?: any;
  hasRepeatedQuestions?: any;
  setMobileView?: any;
  mobileView?: any;
  isStandardForms?: boolean;
  translation?: string;
}

const PreviewQuestions = ({
  questionnaire,
  preview = false,
  styleType,
  setStyleType = () => {},
  isStandardForms,
  setMobileView = () => {},
  mobileView,
  translation,
}: PreviewQuestionsProps) => {
  const intl = useIntl();
  const methods = useForm();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [subCurrentStep, setSubCurrentStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const { stepsData, questions } = useQuestionnaireStore();
  const answers: IAnswer[] = stepsData?.answers || [];
  const { userData } = useAuthStore();
  const { locale } = useContext(LanguageContext)
  const values = methods.watch();

  const filteredQuestions: any = questions?.filter(
    (q: any) => q?.questionTitle != '' && !q?.parentQuestionId && q?.titleQuestionId === null,
  );

  const handleNext = () => {
    const question: any = filteredQuestions?.[currentStep];
    if (
      question?.questionType === 'TITLE' &&
      subCurrentStep < Number(question?.titleQuestions?.length) - 1
    ) {
      setSubCurrentStep(subCurrentStep + 1);
    } else if (currentStep < filteredQuestions?.length - 1) {
      setSubCurrentStep(0);
      setCurrentStep(currentStep + 1);
      setCompletedSteps([...completedSteps, currentStep]);
    }
  };

  const handlePrev = () => {
    const question: any = filteredQuestions?.[currentStep];
    if (question?.questionType === 'TITLE' && subCurrentStep > 0) {
      setSubCurrentStep(subCurrentStep - 1);
    } else if (currentStep > 0) {
      const prevQuestion = filteredQuestions[currentStep - 1];
      if (
        prevQuestion?.questionType === 'TITLE' &&
        Number(prevQuestion?.titleQuestions?.length) > 0
      ) {
        setSubCurrentStep(Number(prevQuestion?.titleQuestions?.length) - 1);
      } else {
        setSubCurrentStep(0);
      }
      setCurrentStep(currentStep - 1);
      setCompletedSteps(completedSteps.filter((st: any) => st !== currentStep));
    }
  };

  const getAnswer = (qid: number) => {
    const found: any = answers?.length > 0 && answers.find((ans) => ans.questionId === qid);
    return found;
  };

  const getOptionIds = (type: string, data: any, id: number | string) => {
    const quest =
      questions.length && questions?.find((qs) => qs.questionType === type && qs.id === id);
    if (quest) {
      if (typeof data === 'string') {
        return quest?.questionOptions?.find((qs: any) => qs.value === data)?.id;
      } else {
        const idList =
          data &&
          data?.length > 0 &&
          data?.map((i: string) => {
            const foundOpt = quest?.questionOptions?.find((qs: any) => qs.value === i);
            return foundOpt?.id;
          });
        return idList;
      }
    }
    return [];
  };

  const isAnswerValid = (data: IAddAnswerForm) => {
    const ansData = answers.find((ans) => ans.questionId === data.questionId);
    return ansData
      ? data && data.optionId
        ? ansData.optionId !== data.optionId
        : ansData && ansData.answer !== data.answer
      : true;
  };

  const checkType = () => {
    return questionnaire?.type && questionnaire?.type === 'E_CRF';
  };

  const handleBlur = (id: any, qType: string, dateTimeType = '', answerValue: any) => {
    if ((checkType() || preview) && id) {
      let answer = '';
      let optionId = null;
      switch (qType) {
        case 'TEXT_INPUT':
          answer = values[`textInput${id}`];
          break;
        case 'TEXT_AREA':
          answer = values[`textArea${id}`];
          break;
        case 'NUMBER_VALUE':
          answer = values[`numberTxt${id}`];
          break;
        case 'CHECKBOX': {
          const checkAns = getOptionIds('CHECKBOX', answerValue, id);
          optionId = checkAns;
          answer = (checkAns && checkAns?.join('')) || '';
          if (preview) methods.setValue(`checkList${id}`, answerValue);
          break;
        }
        case 'DROPDOWN': {
          const dropAns = getOptionIds('DROPDOWN', answerValue, id);
          optionId = [dropAns];
          answer = dropAns;
          break;
        }
        case 'DATE_TIME':
          answer = answerValue && format(new Date(answerValue), PickerFormatEnum[dateTimeType]);
          break;
        case 'RADIO_BUTTONS': {
          const radioAns = getOptionIds('RADIO_BUTTONS', answerValue, id);
          optionId = [radioAns];
          answer = radioAns;
          if (preview) methods.setValue(`radioBtns${id}`, answerValue);
          break;
        }
        case 'RATING':
          answer = answerValue;
          break;
        default:
          null;
      }
      const payload = {
        answererId: userData.id,
        questionId: id,
        optionId,
        answer: `${answer}`,
      };
      if (answer && isAnswerValid(payload)) {
        // addAnswersMutation([payload]);
      }
    }
  };

  const getRepeatedQuestion = (questionsList: any, question: any) => {
    if (question?.questionType === 'REPEATED') {
      const parentQuestions = questionsList?.filter(
        (item: any) => item?.parentQuestionId === question?.id,
      );
      return { ...question, parentQuestions: parentQuestions };
    }
  };

  return (
    <div className={clsx(' w-[867px] flex-1 text-left h-full  bg-white rounded-lg  contents')}>
      <div className='text-gray-dark leading-6 pb-8 pt-4'>
        <div className='flex justify-end items-center gap-x-2 relative pr-7'>
          {preview && (
            <span
              className={clsx(
                'flex mr-2 relative',
                mobileView !== 'web' &&
                  !isStandardForms &&
                  questionnaire?.questionnaire?.type === 'E_PRO' &&
                  'pointer-events-none cursor-not-allowed',
              )}
            >
              <Tooltip
                className='ml-[99px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.verticalMessage' })}
              >
                <span
                  className={`text-sm font-normal ${
                    styleType === 'vertical' ? '!text-blue-normal' : '!text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='questionnaire-field.vertical' />
                </span>
              </Tooltip>
              <ToggleButton
                onChange={() => setStyleType(styleType === 'vertical' ? 'horizontal' : 'vertical')}
                className={clsx(
                  '!mx-3',
                  mobileView !== 'web' &&
                    !isStandardForms &&
                    questionnaire?.questionnaire?.type === 'E_PRO' &&
                    'disabled cursor-not-allowed',
                )}
                // defaultChecked={styleType === 'horizontal'}
                checked={mobileView === 'mobile' ? false : styleType === 'horizontal'}
                borderClass={
                  mobileView !== 'web' &&
                  !isStandardForms &&
                  questionnaire?.questionnaire?.type === 'E_PRO'
                    ? '!border-gray-medium border-1 !bg-white '
                    : '!border-blue-oil !bg-white !border'
                }
                dotClass={
                  mobileView !== 'web' &&
                  !isStandardForms &&
                  questionnaire?.questionnaire?.type === 'E_PRO'
                    ? `bg-white border-gray-medium border-1 ${styleType === 'vertical' && 'right-4'}`
                    : `!bg-blue-oil ${styleType === 'vertical' ? '!right-4' : '!right-1'}`
                }
                inputClassName={
                  mobileView !== 'mobile' &&
                  !isStandardForms &&
                  questionnaire?.questionnaire?.type === 'E_PRO'
                    ? 'disabled cursor-not-allowed'
                    : ''
                }
                name='horizontal'
              />
              <Tooltip
                className='ml-[99px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.horizontalMessage' })}
              >
                <span
                  className={`text-sm font-normal ${
                    styleType === 'horizontal' ? '!text-blue-normal' : '!text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='questionnaire-field.horizontal' />
                </span>
              </Tooltip>
            </span>
          )}
          {questionnaire?.questionnaire?.type === 'E_PRO' ? (
            <span className='flex mr-2 relative'>
              <Tooltip
                className='ml-[99px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.mobileMessage' })}
              >
                <span
                  className={`text-sm font-normal ${mobileView === 'mobile' ? '!text-blue-normal' : '!text-blue-oil'}`}
                >
                  {' '}
                  <FormattedMessage id='questionnaire-field.mobile' />
                </span>
              </Tooltip>
              <ToggleButton
                onChange={(e) => {
                  if (!e) {
                    setMobileView('mobile');
                  } else {
                    setMobileView('web');
                  }
                }}
                className='!mx-3'
                // defaultChecked={mobileView !== 'mobile'}
                borderClass='!border-blue-oil !bg-white !border'
                dotClass='!bg-blue-oil'
                checked={mobileView === 'web'}
                name='mobile'
              />
              <Tooltip
                className='ml-[99px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.horizontalMessage' })}
              >
                <span
                  className={`text-sm font-normal ${mobileView === 'web' ? '!text-blue-normal' : '!text-blue-oil'}`}
                >
                  {' '}
                  <FormattedMessage id='questionnaire-field.web' />
                </span>
              </Tooltip>
            </span>
          ) : null}
        </div>
        <div className='flex w-full'>
          <div
            className={clsx(
              ' overflow-y-auto no-scrollbar !max-h-[calc(100vh-299px)] !overflow-auto pr-3 mt-7',
              mobileView === 'mobile' &&
                questionnaire?.questionnaire?.type === 'E_PRO' &&
                translation
                ? 'hidden'
                : mobileView === 'mobile' && questionnaire?.questionnaire?.type === 'E_PRO'
                  ? 'w-[70%]'
                  : 'w-full',
            )}
          >
            <FormProvider {...methods}>
              <form>
                {filteredQuestions && filteredQuestions.length ? (
                  filteredQuestions.map((question: any, i: number) => {
                    const foundAns: any = question?.id && getAnswer(question?.id);

                    const filteredSubQuestions: any = question?.titleQuestions;

                    return (
                      <div key={question.id}>
                        <Question
                          methods={methods}
                          {...question}
                          questionNumber={i + 1}
                          repeatedQuestions={getRepeatedQuestion(
                            questionnaire?.questions,
                            question,
                          )}
                          isQuestionEmpty={
                            question?.questionType === 'TITLE' &&
                            (!filteredSubQuestions ||
                              (filteredSubQuestions && filteredSubQuestions?.length === 0))
                          }
                          styleType={styleType}
                          isLastQuestion={`${i}` === `${filteredQuestions.length - 1}`}
                          checkType={checkType}
                          mobileView={mobileView === 'mobile'}
                          onBlur={(e) =>
                            handleBlur(
                              question?.id,
                              question?.questionType || '',
                              question?.dateTimeType,
                              e,
                            )
                          }
                          title={
                            question?.titleTranslations?.[languageMap[locale]] ||
                            question.questionTitle
                          }
                          answer={
                            foundAns && foundAns?.optionId ? foundAns?.optionId : foundAns?.answer
                          }
                          preview={preview}
                        />
                        {filteredSubQuestions &&
                          filteredSubQuestions?.length > 0 &&
                          filteredSubQuestions?.map((quest: any, i: number) => (
                            <Question
                              key={i}
                              methods={methods}
                              {...quest}
                              questionNumber={i}
                              mobileView={mobileView === 'mobile'}
                              styleType={styleType}
                              isLastQuestion={false}
                              isSubLastQuestion={`${i}` === `${filteredSubQuestions.length - 1}`}
                              checkType={checkType}
                              onBlur={(e) =>
                                handleBlur(
                                  quest?.id,
                                  quest?.questionType || '',
                                  quest?.dateTimeType,
                                  e,
                                )
                              }
                              title={
                                quest?.titleTranslations?.[languageMap[locale]] ||
                                quest.questionTitle ||
                                ''
                              }
                              answer={
                                foundAns && foundAns?.optionId
                                  ? foundAns?.optionId
                                  : foundAns?.answer
                              }
                              preview={preview}
                            />
                          ))}
                      </div>
                    );
                  })
                ) : (
                  <p>This questionnaire do not have questions yet</p>
                )}
              </form>
            </FormProvider>
          </div>
          {mobileView === 'mobile' &&
            questionnaire?.questionnaire?.type === 'E_PRO' &&
            translation && (
              <div className='relative flex flex-row justify-center space-x-16 w-full mt-[50px]'>
                <div className='w-[24px]'>
                  {(currentStep >= 1 || subCurrentStep >= 1) && (
                    <ArrowBackIosIcon
                      onClick={handlePrev}
                      className='items-center !h-full cursor-pointer'
                    />
                  )}
                </div>
                <div className='flex flex-col items-center'>
                  <p className='font-semibold text-blue-normal pb-[4px]'>
                    <FormattedMessage id='study-builder.originalLanguage' />
                  </p>
                  <MobileView
                    questions={questions}
                    questionnaire={questionnaire}
                    methods={methods}
                    getAnswer={getAnswer}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    subCurrentStep={subCurrentStep}
                    completedSteps={completedSteps}
                  />
                </div>
                <div className='flex flex-col items-center'>
                  <p className='text-center font-semibold text-blue-normal pb-[4px]'>
                    {translation
                      ? intl.formatMessage({ id: getLocaleTranslation(translation) })
                      : ''}
                  </p>
                  <MobileView
                    questions={questions}
                    questionnaire={questionnaire}
                    methods={methods}
                    getAnswer={getAnswer}
                    translation={translation}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    subCurrentStep={subCurrentStep}
                    completedSteps={completedSteps}
                  />
                </div>
                <div className='w-[24px]'>
                  {filteredQuestions?.length - 1 > currentStep && (
                    <ArrowForwardIosIcon
                      onClick={handleNext}
                      className='items-center !h-full cursor-pointer'
                    />
                  )}
                </div>
              </div>
            )}
          {mobileView === 'mobile' &&
            questionnaire?.questionnaire?.type === 'E_PRO' &&
            !translation && (
              <div className='w-[30%]'>
                <MobileView
                  questions={questions}
                  questionnaire={questionnaire}
                  methods={methods}
                  getAnswer={getAnswer}
                  translation={translation}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  subCurrentStep={subCurrentStep}
                  completedSteps={completedSteps}
                />
              </div>
            )}
        </div>

        {/* </> */}
      </div>
    </div>
  );
};
export default PreviewQuestions;
