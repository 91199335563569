import { FiSearch, FiLoader } from 'react-icons/fi';
import clsx from 'classnames';
import { useIntl } from 'react-intl';
import { getTemplates, saveTemplateInLibrary, deleteQsFromLibraryApi } from 'services/api/study';
import { useMutation, useInfiniteQuery } from '@tanstack/react-query';
import TemplateQuestions from './TemplateQuestions';
import { useParams } from 'react-router-dom';
import { useEffect, useCallback, useRef, useState } from 'react';

interface IProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  showLibraryModal: any;
  selectedTemplateId: any;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<any>>;
  onSearchResultSelect: (result: any) => void;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLibraryModal: any;
  refetchSteps?: any;
  steps?: any;
  dragDropList?: any;
}
const AddModaltemplate = ({
  searchQuery,
  setSearchQuery,
  showLibraryModal,
  selectedTemplateId,
  setSelectedTemplateId,
  steps,
  setShowDropdown,
  setShowLibraryModal,
  refetchSteps,
  dragDropList,
}: IProps) => {
  const intl = useIntl();
  const { studyId } = useParams();
  const hoveredItemRef: any = useRef<HTMLDivElement>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [showDeleteMenu, setShowDeleteMenu] = useState<string | null>(null);

  const handleDeleteClick = (itemId: string) => {
    handleDeleteTemplateFromLibrary(itemId);

    setShowDeleteMenu(null);
  };

  const {
    data: templatesData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch: refetchTemplates,
  } = useInfiniteQuery({
    queryKey: ['get-templates', debouncedSearchTerm],
    queryFn: ({ pageParam = 0 }) =>
      getTemplates({ search: debouncedSearchTerm, page: pageParam, size: 20 }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.content.length < 20) return undefined;
      return pages.length;
    },
    keepPreviousData: true,
  });
  const { mutate: handleSaveTemplateInLibrary } = useMutation<any, unknown, any, unknown>(
    (data: any) => saveTemplateInLibrary(data),
    {
      onSuccess: () => {
        setShowLibraryModal(null);
        refetchSteps();
        setSelectedTemplateId(null);
      },
      onError(error: any) {
        console.log(error);
      },
    },
  );
  const { mutate: handleDeleteTemplateFromLibrary } = useMutation<any, unknown, any, unknown>(
    (data: any) => deleteQsFromLibraryApi(data),
    {
      onSuccess: () => {
        refetchTemplates();
        setSelectedTemplateId(null);
      },
      onError(error: any) {
        console.log(error);
      },
    },
  );
  const getSortIndex = () => {
    const findParent = dragDropList?.find((it: any) => it?.folder?.id === showLibraryModal?.id);

    const maxSortOrder =
      findParent?.children?.reduce((max: number, item: any) => {
        return item.sortOrder > max ? item.sortOrder : max;
      }, 0) ?? 0;
    return maxSortOrder + 1;
  };
  const saveTemplateInLibraryOnClick = () => {
    handleSaveTemplateInLibrary({
      studyId: studyId,
      folderId: showLibraryModal?.id ?? null,
      sortOrder: !showLibraryModal?.id ? steps?.length + 1 : getSortIndex(),
      questionnaireId: selectedTemplateId.id,
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(value);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
    const scrollThreshold = 100;

    if (
      scrollHeight - (scrollTop + clientHeight) < scrollThreshold &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const templates = templatesData?.pages.flatMap((page) => page.content) ?? [];

  return (
    <div className='bg-white w-full h-full rounded-lg py-10 px-[38px] flex'>
      <div className='w-[310px] border-[1px] border-purple bg-[#F1F8F9]'>
        <h2 className='px-6 text-left pt-10 pb-6 text-blue-oil text-[32px] font-semibold'>
          {intl.formatMessage({ id: 'study-builder.template-library' })}
        </h2>
        <div className='px-6 relative mb-8'>
          <div className='relative'>
            <input
              type='text'
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={() => searchQuery && setShowDropdown(true)}
              placeholder={intl.formatMessage({ id: 'common.search' })}
              className='w-full h-[40px] px-4 pr-10 border-[1px] border-purple rounded-[4px] text-sm focus:outline-none focus:border-purple-dark'
            />
            {!searchQuery && (
              <FiSearch className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-medium w-5 h-5' />
            )}
          </div>
          {/* {showDropdown && searchQuery && (
            <div className='absolute left-6 right-6 top-[42px] bg-white border-[1px] border-purple rounded-[4px] shadow-lg max-h-[300px] overflow-y-auto z-10'>
              {searchResults.length > 0 ? (
                searchResults.map((result, index) => (
                  <div
                    key={index}
                    className='px-4 py-2 hover:bg-[#F1F8F9] cursor-pointer text-sm'
                    onClick={() => {
                      setSearchQuery(result.title);
                      setShowDropdown(false);
                      onSearchResultSelect(result);
                    }}
                  >
                    {result.title}
                  </div>
                ))
              ) : (
                <div className='px-4 py-2 text-gray-medium text-sm'>
                  {intl.formatMessage({ id: 'common.no-results' })}
                </div>
              )}
            </div>
          )} */}
        </div>

        <div
          ref={scrollContainerRef}
          onScroll={handleScroll}
          className='pb-3 w-full pr-6 max-h-[calc(100vh-300px)] overflow-y-auto !overflow-x-visible'
        >
          {isLoading || isRefetching ? (
            <div className='flex justify-center items-center gap-x-2 py-[10px]'>
              <FiLoader className='w-10 h-10 animate-spin  self-center text-blue-ocean-deep' />
            </div>
          ) : templates?.length === 0 ? (
            <div className='px-4 py-2 text-gray-medium text-sm'>
              {intl.formatMessage({ id: 'common.no-results' })}
            </div>
          ) : (
            templates.map((item: any) => (
              <div
                ref={hoveredItemId === item.id ? hoveredItemRef : null}
                key={item.id}
                className={clsx(
                  'flex w-[95%] justify-start items-center gap-x-2 py-[10px] cursor-pointer relative group',
                  selectedTemplateId?.id === item.id && 'bg-blue-oil [&>p]:text-white',
                )}
                onClick={() => {
                  setSelectedTemplateId(item);
                }}
                onMouseEnter={() => setHoveredItemId(item.id)}
                onMouseLeave={() => {
                  if (!showDeleteMenu) {
                    setHoveredItemId(null);
                  }
                }}
              >
                <p className='text-blue-oil pl-6 text-sm w-full text-ellipsis truncate'>
                  {item.title}
                </p>
                {hoveredItemId === item.id && (
                  <div
                    className='absolute -right-1 cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteMenu(showDeleteMenu === item.id ? null : item.id);
                    }}
                  >
                    <div className='w-5 text-center text-blue-oil text-[20px] rotate-90 -mr-5'>
                      ...
                    </div>
                    {showDeleteMenu === item.id && (
                      <div
                        className='fixed w-[128px] z-[9999] text-xs bg-blue-oil text-white shadow-md rounded-md py-1 px-[8px] text-sm cursor-pointer'
                        style={{
                          left: `${hoveredItemRef.current?.getBoundingClientRect().right - 10}px`,
                          top: `${hoveredItemRef.current?.getBoundingClientRect().top + 28}px`,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item.id);
                        }}
                      >
                        {intl.formatMessage({ id: 'study-builder.delete-from-library' })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          )}
          {isFetchingNextPage && (
            <div className='text-center py-2 text-sm text-gray-500'>Loading more...</div>
          )}
        </div>
      </div>
      {selectedTemplateId?.id ? (
        <div className='w-[calc(100%-310px)] p-8 pt-0 -mt-2'>
          <h2 className='font-semibold text-[20px] text-blue-oil mb-8'>
            {intl.formatMessage({ id: 'standard-forms.template' })}
          </h2>
          <TemplateQuestions selectedTemplate={selectedTemplateId} />
          <div className='flex-1 relative h-[300px] w-full flex items-center justify-between'>
            <button
              onClick={saveTemplateInLibraryOnClick}
              className='px-[29px] py-[9px] text-semibold bg-blue-oil text-white rounded-full hover:bg-blue-600 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'
            >
              {intl.formatMessage({ id: 'common.select' })}
            </button>
            <img
              src='/assets/images/study/template-bg.png'
              alt='Template background'
              className='h-full object-contain ml-auto'
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default AddModaltemplate;
