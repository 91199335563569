import { useState, useEffect, Dispatch, SetStateAction, useContext } from 'react';
import LeftMenu from '../StudyBuilerLeftMenu';
import { Button, Modal, TextInput } from 'components/common';
import { FiLoader } from 'react-icons/fi';
import QuestionnairePage from './QuestionnairePage';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { fetchStepsByStudyId, createStep, deleteStep } from 'services/api/steps';
import { useQuery, useMutation } from '@tanstack/react-query';
import { generatePath, useNavigate, useParams, useLocation } from 'react-router-dom';
import { createFolder, updateFolder } from 'services/api/folders';
import {
  createQuestionnaire,
  createIDVerification,
  updateQuestionnaire,
} from 'services/api/questionnaire';
import { IFolderForm, IFolder, IUpdateFolder } from 'types/folder';
import { toast } from 'react-toastify';
import { IStepForm, IStep } from 'types/step';
import {
  ICreateQuestionnaireForm,
  IQuestionnaireForm,
  IQuestion,
  IQuestionnaire,
} from 'types/questionnaire';
import StudyDashboard from 'pages/Study/StudyDashboard';
import { IItem } from 'constants/questionnaire/steps';
import { getMaxSortId } from 'utils/number';
import ConsentForm from './ConsentForm';
import StandardFormsContent from './StandardFormsComponents/StandardFormsContent';
import { useStudyStore } from 'store/StudyStore';
import { IStandardForm } from 'types/study';
import PreviewQuestions from './PreviewQuestions';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageContext } from '../../../App/LanguageContext';

interface IProps {
  preview: boolean;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
  refetchStudy: any;
  standardForms: IStandardForm[] | [];
  page: string;
  setPage: any;
}

const StudyBuilderContainer = ({
  page,
  setPage,
  preview,
  standardForms,
  setShowPreview,
  refetchStudy,
}: IProps) => {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [showStudyDashboard, setShowStudyDashboard] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const [itemType, setItemType] = useState<string>('');
  const [consentFormContent, setConsentFormContent] = useState<string>('');
  const [itemName, setItemName] = useState<string>('');
  const { questionnaireId: questionnaireIdStr } = useParams();
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const [emptySteps, setEmptySteps] = useState(false);
  const location = useLocation();
  const { currentStudy, selectedStandardForm } = useStudyStore();
  const isStandardFormPage = localStorage.getItem('is-standard-form-page');
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const {
    setQuestions,
    steps,
    setSteps,
    questions,
    selectedQuestionnairy,
    setSelectedQuestionnairy,
    setIsNewItemCreated,
  } = useQuestionnaireStore();

  const {
    data: stepsData,
    refetch: refetchStepsQuery,
    isLoading: isStepsLoading,
  } = useQuery<IStep[]>({
    queryKey: ['steps'],
    queryFn: () => fetchStepsByStudyId(studyId as string),
  });

  const refetchSteps = (type?: string, questionId?: number, data?: any) => {
    if (type === 'add-new') {
      setQuestions([
        ...questions.filter((it: any) => it.frontId !== questionId),
        { ...data?.[0], questionTitle: data?.[0]?.questionTitle ?? data?.[0]?.staticContentType },
      ]);
      refetchStepsQuery();
    } else if (type === 'front-delete') {
      setQuestions(questions.filter((item) => item.frontId !== questionId));
    } else {
      refetchStepsQuery();
      if (type === 'delete' && questionId) {
        setQuestions(questions.filter((item) => item.id !== questionId));
      }
    }
  };
  const { mutate: createFolderMutate } = useMutation<IFolder, unknown, IFolderForm, unknown>(
    (data: IFolderForm) => createFolder(data),
    {
      onSuccess(data: any) {
        if (data?.id) {
          createStepMutate({
            studyId: studyId,
            folderId: data.id,
            sortOrder: getMaxSortId(steps) + 1,
            questionnaireId: null,
            idVerificationId: null,
          });
        }
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createFolder' }),
        );
      },
    },
  );
  const { mutate: createStepMutate } = useMutation<
    IStep,
    unknown,
    IStepForm & { isNestedItem?: boolean; newCreatedItem?: any },
    unknown
  >((data: IStepForm & { isNestedItem?: boolean; newCreatedItem?: any }) => createStep(data), {
    onSuccess() {
      setIsNewItemCreated(false);
      refetchSteps();
    },
    onError(error: any) {
      toast.error(error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createStep' }));
    },
  });

  const { mutate: createQuestionnaireMutate } = useMutation<
    IQuestionnaireForm,
    unknown,
    ICreateQuestionnaireForm & { isNestedItem?: boolean },
    unknown
  >((data: ICreateQuestionnaireForm & { isNestedItem?: boolean }) => createQuestionnaire(data), {
    onSuccess(data: any, variables?: any) {
      if (data?.id) {
        createStepMutate({
          folderId: variables?.folderId ?? null,
          studyId: studyId,
          sortOrder: getMaxSortId(steps) + 1,
          questionnaireId: data.id,
          idVerificationId: null,
          isNestedItem: variables?.isNestedItem,
          newCreatedItem: data,
        });
      }
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createFolder' }),
      );
    },
  });

  const { mutate: createIDVerificationMutate } = useMutation<
    IQuestionnaireForm,
    unknown,
    ICreateQuestionnaireForm,
    unknown
  >((data: ICreateQuestionnaireForm) => createIDVerification(data), {
    onSuccess(data: any) {
      if (data?.id) {
        createStepMutate({
          folderId: null,
          studyId: studyId,
          sortOrder: getMaxSortId(steps) + 1,
          questionnaireId: null,
          idVerificationId: data.id,
        });
      }
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createFolder' }),
      );
    },
  });
  const { mutate: deleteStepMutate } = useMutation<any, unknown, number, unknown>(
    (id: number) => deleteStep(id),
    {
      onSuccess: () => {
        // toast.success(intl.formatMessage({ id: 'error.stepRemoved' }));
        refetchSteps();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.errorStepRemoval' }),
        );
      },
    },
  );
  const { mutate: updateQuestionnaireMutation } = useMutation<
    IQuestionnaireForm,
    unknown,
    IQuestionnaireForm,
    unknown
  >((data: IQuestionnaireForm) => updateQuestionnaire(data), {
    onSuccess: () => {
      // toast.success(intl.formatMessage({ id: 'error.questionnaireUpdated' }));
      refetchSteps();
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ??
          intl.formatMessage({ id: 'error.questionnaireUpdatedError' }),
      );
    },
  });
  const { mutate: updateFolderMutation } = useMutation<
    IUpdateFolder,
    unknown,
    IUpdateFolder,
    unknown
  >((data: IUpdateFolder) => updateFolder(data), {
    onSuccess: () => {
      refetchSteps();
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ?? intl.formatMessage({ id: 'error.folderUpdatedError' }),
      );
    },
  });
  const handleEditTitle = (title: string, qs: IQuestionnaire, folderId?: number) => {
    if (!qs && folderId) {
      updateFolderMutation({
        id: +folderId,
        name: title,
      });
    } else if (title !== qs?.title && !!title && !!qs?.title) {
      updateQuestionnaireMutation({
        title: title,
        id: qs?.id,
        sortOrder: qs?.sortOrder,
        type: qs?.type,
      });
    }
  };

  useEffect(() => {
    if (Array.isArray(stepsData)) {
      setSteps(stepsData.filter((s: IStep) => s?.idVerification === null) ?? []);
    }
  }, [stepsData, currentStudy?.consentQuestionnaire?.active]);

  useEffect(() => {
    if (selectedQuestionnairy?.id) {
      if (selectedQuestionnairy?.questionnaire?.type === 'CONSENT') {
        setQuestions(
          selectedQuestionnairy?.questionnaire?.staticContents
            ?.sort((first: any, second: any) => first?.sortOrder - second?.sortOrder)
            ?.map((item: IQuestion) => ({
              ...item,
              questionTitle: item?.staticContentType,
              questionType: item?.staticContentType,
            })) ?? [],
        );
      } else {
        setQuestions(
          selectedQuestionnairy?.questionnaire?.questions?.sort(
            (first: any, second: any) => first?.sortOrder - second?.sortOrder,
          ) ?? [],
        );
      }
    }
  }, [selectedQuestionnairy]);
  useEffect(() => {
    if (location?.pathname?.includes('consent')) {
      setPage('consent-form');
    } else if (isStandardFormPage === 'true') {
      setPage('standard-form');
    } else if (questionnaireId) {
      const fndStep = steps?.find((sp: any) => sp?.questionnaire?.id === Number(questionnaireId));
      if (
        questions?.length === 1 &&
        questions?.[0]?.questionTitle === '' &&
        !selectedQuestionnairy?.questionnaire?.id
      ) {
        setSelectedQuestionnairy(fndStep);
      }
    }
  }, [location?.pathname, steps]);

  const handleSelectQuestionnaire = (item: any, isFolder?: boolean, isConsentForm?: boolean) => {
    if (isConsentForm) {
      setPage('consent-form');
      setSelectedQuestionnairy({ id: 1, type: 'test' });
    } else if (isFolder) {
      setSelectedQuestionnairy(item);
    } else {
      if (item?.type !== 'ID_VERIFICATION' && !location?.pathname?.includes('consent')) {
        setPage('add-questionnaire');
        const routeId =
          item?.type === 'ID_VERIFICATION' ? `i-${item?.id}` : `q-${item?.questionnaire?.id}`;
        navigate(generatePath(`/study/${studyId}/study-builder/${routeId}`, { id: routeId }));
      }
      const foundQuestionnaire: any = steps.find(
        (step: any) => +step?.questionnaire?.id === +item?.questionnaire?.id,
      );
      setSelectedQuestionnairy(foundQuestionnaire);
      if (location?.pathname?.includes('consent') && !item?.questionnaire?.id) {
        setPage('consent-form');
        setSelectedQuestionnairy({ id: 1, type: 'test' });
      } else {
        const routeId =
          item?.type === 'ID_VERIFICATION' ? `i-${item?.id}` : `q-${item?.questionnaire?.id}`;
        navigate(generatePath(`/study/${studyId}/study-builder/${routeId}`, { id: routeId }));
      }
    }
  };

  const hadnleSaveItem = (payload?: any) => {
    if (payload) {
      payload?.itemName &&
        createQuestionnaireMutate({
          studyId: studyId,
          title: payload?.itemName ?? '',
          type: selectedQuestionnairy?.questionnaire?.type ?? 'E_CRF',
          sortOrder: 1,
          language: locale.toUpperCase(),
          folderId: payload?.folder?.id,
          isNestedItem: true,
        });
    } else {
      setModalOpen(false);
      if (itemType === 'folder') {
        createFolderMutate({
          name: itemName,
          sortOrder: getMaxSortId(steps) + 1,
          studyId: studyId ? studyId : 1,
        });
      } else {
        if (selectedItems?.[0].value === 'id-verification') {
          const item: any = {
            sortOrder: getMaxSortId(steps) + 1,
          };

          createIDVerificationMutate(item);
        } else {
          createQuestionnaireMutate({
            studyId: studyId,
            title: itemName,
            type:
              selectedItems?.[0].value === 'CONSENT'
                ? 'CONSENT'
                : selectedItems?.[0].value === 'id-verification'
                  ? 'ID_VERIFICATION'
                  : selectedItems?.[0].value === 'ELIGIBILITY'
                    ? 'ELIGIBILITY'
                    : selectedItems?.[0].value === 'ePRO-Custom-Questionnaire'
                      ? 'E_CRF'
                      : '',
            sortOrder: getMaxSortId(steps) + 1,
            language: locale.toUpperCase(),
          });
        }
      }
      setItemName('');
      setSelectedItems([]);
      setPage('view-all');
    }
  };
  const handleCreateFolder = (folderName: string) => {
    if (folderName) {
      createFolderMutate({
        name: folderName,
        sortOrder: getMaxSortId(steps) + 1,
        studyId: studyId ? studyId : 1,
      });
    } else {
      setSteps([...steps]);
      setEmptySteps(true);
    }
  };
  const createQuestionnaireItem = (name: string) => {
    if (name) {
      createQuestionnaireMutate({
        studyId: studyId,
        title: name,
        type: 'E_CRF',
        sortOrder: getMaxSortId(steps) + 1,
        language: locale.toUpperCase(),
      });
    } else {
      setSteps([...steps]);
    }
  };

  const setFolderItemType = () => {
    setItemType('folder');
    setItemName('Folder');
    setPage('add-item');
  };

  const handleRemoveFolder = (item: any) => {
    deleteStepMutate(item?.id);
  };

  const titleMap: any = {
    'AE/SAE/DD': <FormattedMessage id='standard-forms.ae-sae-dd' />,
    'Protocol Deviation': <FormattedMessage id='standard-forms.protocole-deviation' />,
    'Termination form': <FormattedMessage id='standard-forms.termination-form' />,
    '': '',
  };

  const getContent = (pg: string) => {
    switch (pg) {
      case 'standard-form':
        if (preview)
          return (
            <div className='w-[1024px] flex-1 h-full overflow-y-auto border border-purple rounded-lg'>
              <h3 className='text-[20px] text-blue-ocean-deep font-medium leading-[28px] text-left py-[25px] pl-[25px] border-b-2 border-b-purple cursor-pointer pb-4'>
                {titleMap[selectedStandardForm?.title]}
              </h3>
              <PreviewQuestions
                questionnaire={selectedQuestionnairy}
                preview={true}
                isStandardForms={true}
              />
            </div>
          );
        return <StandardFormsContent />;
      case 'consent-form':
        if (preview) {
          return (
            <div className='w-full bg-white rounded-lg'>
              <h3 className='text-[20px] text-blue-ocean-deep font-medium leading-[28px] text-left py-[28px] pl-[43px] border-b-2 border-b-[#EFEFEF] cursor-pointer'>
                <FormattedMessage id='study-builder.consentForm' />
              </h3>
              <div
                className='text-left px-[42px] max-h-[650px] overflow-y-auto'
                dangerouslySetInnerHTML={{ __html: consentFormContent }}
              />
            </div>
          );
        } else
          return (
            <ConsentForm
              consentFormContent={consentFormContent}
              setConsentFormContent={setConsentFormContent}
              refetchStudy={refetchStudy}
              setPage={setPage}
            />
          );
      case 'add-item':
        return null;
      case 'add-questionnaire':
        return (
          <QuestionnairePage
            initQuestionnaire={selectedQuestionnairy}
            refetchSteps={refetchSteps}
            preview={preview}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <>
      {isStepsLoading ? (
        <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
      ) : showStudyDashboard ? (
        <StudyDashboard setShowStudyDashboard={setShowStudyDashboard} />
      ) : (
        <>
          <Modal
            className={{ content: 'w-[640px] p-8' }}
            isOpen={isModalOpen}
            onClose={() => {
              setModalOpen(false);
            }}
            title={
              <h1 className='font-serif leading-[2.625rem] text-[24px] text-blue-ocean-deep w-[32rem] truncate text-ellipsis'>
                <FormattedMessage id='study-builder.EnterNameOfItem' />
              </h1>
            }
          >
            <div>
              <TextInput
                name='itemName'
                control={{
                  value: itemName,
                  onChange: (e) => {
                    setItemName(e.target.value);
                  },
                }}
                disabled={selectedItems?.findIndex((item) => item.value === 'id-verification') > -1}
              />
              <div className='flex justify-end items-center gap-x-3 mt-12 py-6 text-right  w-full'>
                <Button
                  onClick={() => setModalOpen(false)}
                  className='border-2 border-blue-ocean-deep rounded-full text-base font-semibold w-[152px] px-[13px] py-[10px] text-blue-ocean-deep h-12'
                >
                  <FormattedMessage id='cancel' />
                </Button>
                <Button
                  onClick={() => hadnleSaveItem()}
                  className='border-2 border-blue-ocean-deep rounded-full text-base font-semibold w-[152px] px-[13px] py-[10px] bg-blue-ocean-deep text-white h-12'
                >
                  <FormattedMessage id='save' />
                </Button>
              </div>
            </div>
          </Modal>

          <div className='w-full flex flex-1 pr-11 mt-1 pl-6 py-6 rounded-md bg-white'>
            <LeftMenu
              {...{
                refetchSteps,
                steps,
                handleSelectQuestionnaire,
                selectedQuestionnairy,
                page,
                setPage,
                setFolderItemType,
                handleRemoveFolder,
                handleEditTitle,
                handleCreateFolder,
                createQuestionnaireItem,
                emptySteps,
                setShowPreview,
                refetchStudy,
                standardForms,
                hadnleSaveItem,
              }}
            />
            {selectedQuestionnairy?.id || page === 'consent-form' || page === 'standard-form' ? (
              <div className='!rounded-lg flex-1 h-full'>{!isStepsLoading && getContent(page)}</div>
            ) : (
              <div className='!rounded-lg flex-1 h-full mb-5'></div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default StudyBuilderContainer;
