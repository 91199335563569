import { useQuery } from '@tanstack/react-query';
import { fetchStepsByParticipantId } from 'services/api/steps';
import { useOutlet, useParams } from 'react-router-dom';
import FallbackLoading from 'components/common/FallbackLoading';
import StepList from './StepList';
import { getQuestionQueries } from 'services/api/questionQuery';
import { useEffect, useState } from 'react';
import { useQueryQuestionStore } from 'store/queryQuestionStore';
import useStandardForms from './useStandardForms';
import StandardFormsMenu from './StandardForms/StandardFormsMenu';
import { FormattedMessage } from 'react-intl';
import { useParticipantStore } from 'store/participantStore';
import { useCentersStore } from 'store/centers';
import { useQuestionnaireStore } from '../../../../store/QuestionnaireStore';

export function QuestionnaireStatus() {
  const { studyId, participantId } = useParams();
  const [patientCode, setPatientCode] = useState('');
  const { centers, selectedCenter } = useCentersStore();
  const { setQueryQuestions } = useQueryQuestionStore();
  const outlet = useOutlet();
  const { standardForms } = useStandardForms();
  const { participant } = useParticipantStore();
  const { consentFormLoading} = useQuestionnaireStore();

  const { data, isLoading } = useQuery({
    queryKey: ['stepsByStudyId', participantId],
    queryFn: () => fetchStepsByParticipantId(participantId as string),
    refetchOnMount: true,
  });

  const { data: queryList, refetch } = useQuery({
    queryKey: ['queries'],
    queryFn: () =>
      studyId &&
      participantId &&
      getQuestionQueries(parseInt(`${studyId}`, 10), 1, parseInt(`${participantId}`, 10)),
  });

  useEffect(() => {
    queryList && setQueryQuestions(queryList);
  }, [participantId, queryList]);

  useEffect(() => {
    refetch();
  }, [participantId, studyId]);

  useEffect(() => {
    const orderIndex = parseInt(`${participant}`, 10) < 10 ? `00${participant}` : `0${participant}`;

    centers?.map((center: any) => {
      if (`${center?.id}` === `${selectedCenter}`) {
        center?.patients?.map((item: any) => {
          if (`${item.id}` === `${participant}`) {
            setPatientCode(
              `${orderIndex}${center?.address?.country?.countryIsoCode}${item?.centerId}`,
            );
          }
        });
      }
    });
  }, [participant, centers, selectedCenter, participantId]);
  return (
    <section className='w-full bg-background-secondary my-[1.37rem] rounded-3xl py-5 px-8 relative max-h-[calc(100%-2.75rem)] max-w-screen-2xl mx-auto'>
      <h1 className='text-blue-ocean-deep text-[40px] text-left mb-4 tracking-tight font-sans pl-6'>
        <FormattedMessage id='query-dashboard.patient' /> - {patientCode}
      </h1>
      <div className='flex gap-6 h-full max-h-[calc(100%-2.875rem)] p-6 bg-white rounded-lg pr-11 border-purple border '>
        <div className='w-full max-w-[18.125rem] bg-rose-light rounded-lg p-3 flex flex-col text-left overflow-y-auto h-full !rounded-tr-lg'>
          <p className='text-lg text-blue-ocean-deep leading-normal font-medium tracking-tight mb-2.5'>
            <FormattedMessage id='patient-status.allItems' />
          </p>
          <div className='rounded-lg'>
            {consentFormLoading && isLoading ? (
              <FallbackLoading className='flex-1' />
            ) : (
              <StepList options={data ? data : []} />
            )}
          </div>
          <StandardFormsMenu standardForms={standardForms} />
        </div>
        {outlet || (
          <div className='flex-1 text-left overflow-y-auto p-[1.875rem]'>
            <h1 className='text-blue-oil font-serif text-[2rem] leading-[2.75rem] mb-6'>
              <FormattedMessage id='patient-status.selectQuestionaire' />
            </h1>
          </div>
        )}
      </div>
    </section>
  );
}
