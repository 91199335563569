import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IPatient } from './patient';
// ID Verification is not really a questionnaire, but it will be helpful for the logic if this stay in questionnaire type
export type IQuestionnaireType = 'CONSENT' | 'ELIGIBILITY' | 'E_PRO' | 'E_CRF' | 'ID_VERIFICATION';

export interface IStepQuestionnaire {
  titleTranslations: any;
  id: string;
  studyId: number;
  folderId: number | null;
  title: string;
  type: IQuestionnaireType;
  sortOrder: number;
  startDate: string;
  dueDate: string;
  createdAt: string;
  updatedAt: string;
  questions: [];
  staticContents: [];
  eproSchedule?: any;
}

export interface IQuestionnaire {
  type: string;
  id: number;
  text?: string;
  title?: string;
  name?: string;
  formData?: TypeForm;
  icon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  studyId?: number;
  folder?: any;
  sortOrder?: number;
  startDate?: string;
  dueDate?: string;
  createdAt?: string;
  updatedAt?: string;
  questions?: IQuestion[];
  staticContents?: [];
  questionnaires?: any;
  questionnaire?: any;
  idVerification?: any;
  content?: string;
  eproSchedule?: any;
  subtitleTranslations?: any;
  titleTranslations?: any;
  translationApproved?: any;
  translationWithAI?: any;
}
export interface IQuestion {
  id?: number;
  selectionType?: string;
  frontId?: number;
  title?: string;
  questionType?: string;
  type?: string;
  data?: any;
  questionTitle?: string;
  staticContentType?: any;
  header?: string;
  description?: string;
  dateTime?: string;
  otherOption?: any;
  disableFutureDates?: boolean;
  inputPlaceHolder?: string;
  maxCharacters?: string | number;
  required?: boolean;
  questionOptions?: any;
  sortOrder?: number;
  updatedAt?: string;
  placeholder?: string;
  dateTimeType?: string;
  staticContents?: string;
  stars?: string;
  editable?: boolean;
  automatic?: boolean;
  questionCategory?: string;
  isScored?: boolean;
  startValue?: string | undefined;
  endValue?: string | undefined;
  increment?: string | undefined;
  parentQuestionId?: number;
  children?: any;
  titleQuestionId?: number;
  subtitle?: any;
  subtitleTranslations?: any;
  numberType?: any;
  titleQuestions?: IQuestion[];
  startLegend?: any;
  startLegendTranslations?: any;
  endLegend?: any;
  endLegendTranslations?: any;
  inputType?: string;
  qssId?: any;
}

export interface DropTargetProps {
  onDrop: (item: IQuestion) => void;
  questions: IQuestion[];
  refetchSteps?: any;
  handleSaveQuestionnanire: () => void;
}

export interface IConditionForm {
  section: string;
  subsection: string;
  question: string;
  conditional: string;
  comparisonValue: string;
  operator: string;
}
export interface TypeForm {
  conditionForm?: IConditionForm[];
  dataValidation?: {
    answerComparator: string;
    answerValue: string;
    exclusion: string;
    message: string;
    conditionForm: IConditionForm[];
  }[];
  description?: string;
  isRequired?: boolean;
  questionTitle: string;
  variableName?: string;
  selectionType?: string;
  header?: string;
  inputPlaceHolder?: string;
  required?: boolean;
  editor?: string;
  config?: {
    options?: IDropDownOption[];
    description?: string;
    inputPlaceHolder?: string;
    maxCharacters?: string;
  };
  dateTimeType?: string;
  // [key: string]: string;
}
export interface IDropDownOption {
  label: string;
  value: string;
}
export interface ICreateQuestionnaireForm {
  studyId?: any;
  sortOrder?: number | undefined;
  folderId?: number;
  title?: string;
  type?: string;
  language?: string;
}
export interface IQuestionForm {
  id: number;
  questionType: string;
  data: any;
}
export interface IQuestionnaireForm {
  id: number;
  title: string;
  type: string;
  sortOrder: number | undefined;
  language?: string;
}
export interface ISaveQuestionnairesForm {
  data: IQuestion[];
  questionnaireId: number;
}

export const consentStatusEnum = {
  WAITING_FOR_APPOINTMENT_1: 'WAITING_FOR_APPOINTMENT_1',
  BOOKED_APPOINTMENT_1: 'BOOKED_APPOINTMENT_1',
  WAITING_FOR_APPOINTMENT_2: 'WAITING_FOR_APPOINTMENT_2',
  BOOKED_APPOINTMENT_2: 'BOOKED_APPOINTMENT_2',
  WAITING_FOR_SIGNATURE: 'WAITING_FOR_SIGNATURE',
  PATIENT_DECLINED: 'PATIENT_DECLINED',
  SIGNED: 'SIGNED',
};

export interface IConsentFormData {
  id: number;
  status: string;
  patientSignStatus: string;
  questionnaire: IQuestionnaire;
  clinicianAppointmentOne: any;
  clinicianAppointmentTwo: any;
  signer: any;
  patientSignature: any;
  studyUserSignature: any;
  patient: IPatient;
}

export interface IEproRepeatation {
  occurence: string;
  progress: number;
  score: number;
  date: string;
  totalQuestion: number;
}

export interface IStepEproList {
  stepId: number;
  questionnaireId: number;
  questionnaire: IQuestionnaire;
  eproSchedule: any;
  eproRepeatation: IEproRepeatation[];
}