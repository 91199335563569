import { create } from 'zustand';

interface TemplateModalState {
  showLibraryModal: any;
  selectedTemplateId: string | null;
  setShowLibraryModal: (show: any) => void;
  setSelectedTemplateId: (id: string | null) => void;
}

export const useTemplateModal = create<TemplateModalState>((set) => ({
  showLibraryModal: false,
  selectedTemplateId: null,
  setShowLibraryModal: (show) => set({ showLibraryModal: show }),
  setSelectedTemplateId: (id) => set({ selectedTemplateId: id }),
}));
