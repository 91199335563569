import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IQuestion, ISaveQuestionnairesForm } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { toast } from 'react-toastify';
import { fetchStepsByStudyId, reOrderSteps } from 'services/api/steps';
import { getMaxSortId } from 'utils/number';
import { IStep, IRorderStepsForm } from 'types/step';
import {
  addQuestionToQuestionnaire,
  addStaticQuestionToQuestionnaire,
  deleteQuestion,
  deleteStaticQuestion,
  saveQuestionnaires,
  saveStaticQuestionnaires,
  duplicateStepApi,
  duplicateQuestion,
  questionnairesTranslateEntity,
} from 'services/api/questionnaire';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isConsentQuestionnaire } from 'constants/questionnaire';
import { retrieveStandardForms } from 'services/api/study';
import { useIntl } from 'react-intl';

interface IQuestionForm {
  id: number;
  questionType: string;
  data: any;
}
interface TypeForm {
  id?: string;
  selectionType?: string;
  description?: string;
  questionTitle?: string;
  required?: boolean;
  maxCharacters?: number;
  inputPlaceHolder?: string;
  sortOrder?: number | any;
  isScored?: boolean;
  parentQuestionId?: number;
  titleQuestionId?: any;
  subtitle?: any;
}

export const useQuestionnaire = ({
  shouldFetchSteps = true,
  shouldFetchSF = true,
}: {
  shouldFetchSteps?: boolean;
  shouldFetchSF?: boolean;
}) => {
  const { questionnaireId: questionnaireIdStr } = useParams();
  const [collapsedSubQuestions, setCollapsedSubQuestions] = useState<number[]>([]);
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const {
    page,
    selectedQuestionnairy,
    setSelectedQuestionnairy,
    questions,
    setQuestions,
    setSteps,
    steps,
    setAddQuestionError,
    editTsData,
    setEditTsData,
  } = useQuestionnaireStore();
  const { studyId } = useParams();
  const intl = useIntl();

  const { refetch: refetchStepsQuery } = useQuery<IStep[]>({
    refetchOnMount: 'always',
    queryKey: ['steps'],
    enabled: shouldFetchSteps,
    queryFn: () => fetchStepsByStudyId(studyId as string),
    onSuccess: (data: any) => {
      setSteps(data.filter((s: IStep) => s?.idVerification === null));
      setSelectedQuestionnairy({
        ...data.find((qss: any) => qss?.id === selectedQuestionnairy?.id),
      });
      setEditTsData({ id: -1, key: '', value: '' });
    },
  });

  const { data: standardForms, refetch: refetchStandardForms } = useQuery({
    queryKey: ['standard-forms'],
    queryFn: () => retrieveStandardForms(studyId),
    refetchOnMount: 'always',
    enabled: shouldFetchSF,
  });

  useEffect(() => {
    if (page === 'standard-form' && standardForms?.length) {
      const formId = selectedQuestionnairy?.questionnaire?.id ?? questionnaireId;
      const newQuestions = standardForms.find((form: any) => form.id === formId)?.questions ?? [];
      setQuestions(newQuestions);
    }
  }, [standardForms]);

  const { mutate: questionTranslateEntity } = useMutation<any, unknown, any, unknown>(
    (language: string) =>
      questionnairesTranslateEntity(
        editTsData?.id,
        language,
        editTsData?.key || '',
        parseInt(`${studyId}`, 10),
        editTsData?.value || '',
      ),
    {
      onSuccess() {
        refetchStepsQuery();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const { mutate: addQuestionMutate, isLoading: addQuestionLoading } = useMutation<
    { data: IQuestion; reOrder?: boolean },
    unknown,
    { data: [IQuestionForm]; reOrder?: boolean; qssId?: any },
    unknown
  >(
    ({ data, qssId }: any) =>
      addQuestionToQuestionnaire(
        data,
        qssId ?? selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
      ),
    {
      onSuccess: (res: any, params: any) => {
        setAddQuestionError('');

        if (page === 'standard-form') {
          refetchStandardForms();
        } else if (params?.[0]?.data?.id) {
          const fndQsId = questions.findIndex(
            (qs: IQuestion) => qs?.id && +qs?.id === +params?.[0]?.data?.id,
          );

          if (fndQsId > -1) {
            const arr: IQuestion[] = [
              ...questions.map((qs: IQuestion, idx: number) =>
                idx === fndQsId ? { ...qs, ...res?.[0] } : { ...qs },
              ),
            ];

            setQuestions([...arr]);
          }
        }

        if (params?.reOrder) {
          const newList1 = insertItemInBettwen([...questions], res?.[0]);
          handleReorderQuestions([...newList1]);
          refetchStepsQuery();
        } else {
          refetchStepsQuery();
        }
      },
      onError(error: any) {
        const errMsg =
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' });
        setAddQuestionError(errMsg);
        toast.error(errMsg);
      },
    },
  );

  const insertItemInBettwen = (list: any, newItem: any): any => {
    const newList = [...list];

    const index = newList.findIndex((item) => item.sortOrder === newItem.sortOrder);

    if (index !== -1) {
      newList.splice(index, 0, newItem);

      for (let i = index + 1; i < newList.length; i++) {
        newList[i].sortOrder += 1;
      }
    } else {
      newList.push(newItem);
    }

    return newList;
  };

  const { mutate: addStaticQuestionMutate } = useMutation<
    IQuestion,
    unknown,
    [IQuestionForm],
    unknown
  >(
    (data: [IQuestionForm]) =>
      addStaticQuestionToQuestionnaire(
        data,
        selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
      ),
    {
      onSuccess: () => {},
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: duplicateStepMutate } = useMutation<number, unknown, number, unknown>(
    (id: number) => duplicateStepApi(id),

    {
      onSuccess: () => {
        refetchStepsQuery();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message ?? 'Error in duplicating the step');
      },
    },
  );
  const duplicateItem = (clickedItemIndex: number, newQs: any, newQuestionId: any) => {
    const newArray = [...questions];
    const fndQs = newQs.findIndex((qs: any) => qs.id === newQuestionId);

    const duplicatedItem = {
      ...newArray[clickedItemIndex],
      ...newQs[fndQs + 1],
      sortOrder: newQs[fndQs + 1]?.sortOrder - 1,
    };
    newArray.splice(clickedItemIndex + 1, 0, duplicatedItem);
    const updatedArray = newArray.map((item, index) => ({
      ...item,
      sortOrder: index + 1,
    }));

    return updatedArray;
  };

  const { mutate: duplicateQuestionMutate } = useMutation<number, unknown, any, unknown>(
    (id: number) => duplicateQuestion(id),
    {
      onSuccess: (data: any, params: any) => {
        const fndItemIndex: any = questions.findIndex((qs: any) => qs.id === params);
        const newItems = duplicateItem(fndItemIndex, data, params);

        if (page === 'standard-form') {
          setQuestions(newItems);
          refetchStandardForms();
          refetchStepsQuery();
        } else refetchStepsQuery();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );

  const getOptionalKeys = (type: string | undefined, data: any) => {
    switch (type) {
      case 'SCALE':
        return {
          startValue:
            data?.optionalProps?.startValue || data?.optionalProps?.startValue == 0
              ? +data?.optionalProps?.startValue
              : -10,
          endValue:
            data?.optionalProps?.endValue || data?.optionalProps?.endValue == 0
              ? +data?.optionalProps?.endValue
              : 10,
          increment: data?.optionalProps?.increment ? +data?.optionalProps?.increment : 2,
          startLegend: data?.optionalProps?.startLegend ? data?.optionalProps?.startLegend : '',
          endLegend: data?.optionalProps?.endLegend ? data?.optionalProps?.endLegend : '',
        };
      case 'TEXT_INPUT':
        return {
          inputPlaceHolder: data?.inputPlaceHolder ?? 'placeholder',
          maxCharacters: +data?.maxCharacters ?? 50,
          ...(data?.description && { description: data?.description }),
          inputType: data?.optionalProps?.inputType ?? 'SHORT',
        };
      case 'NUMBER_VALUE':
        return {
          measurementUnit: data?.measurementUnit,
          maxDecimal: data?.maxDecimal,
          maxNumber: data?.maxNumber,
          minNumber: data?.minNumber,
          numberType: data?.optionalProps?.numberType,
        };
      case 'FILE_UPLOAD':
        return {
          fileType: data?.fileType,
        };
      case 'DATE_TIME':
        return {
          dateTime: new Date(),
          dateTimeType: data?.optionalProps?.dateTimeType ?? 'DATE',
          disableFutureDates: !!data?.disableFutureDates,
        };
      case 'HEADER':
        return { header: data?.header };
      case 'INSTRUCTION':
        return { header: data?.editor };
      case 'IMAGE':
        return { imageUrl: data?.header };
      case 'RATING':
        return { stars: data?.optionalProps?.stars ?? 'THREE' };
      case 'CHECKBOX':
        if (data?.optionalProps?.questionOptions?.length === 0) {
          return {
            questionOptions: [{ label: '', value: '_', score: null }],
          };
        } else {
          return {
            questionOptions:
              data?.optionalProps?.questionOptions
                ?.filter((opt: any) => opt.value !== '_')
                ?.map((opt: any) => ({
                  ...opt,
                  label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
                  value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
                })) ?? [],
          };
        }

      case 'RADIO_BUTTONS':
      case 'DROPDOWN':
        return {
          questionOptions:
            data?.optionalProps?.questionOptions?.map((opt: any) => ({
              ...opt,
              label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
              value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
            })) ?? [],
        };
      default:
        return {};
    }
  };

  const handleAddQSToQuestionnaire = (
    data: TypeForm & { optionalProps: any; qssId?: number },
    reOrder?: boolean,
    isTranslation?: any,
  ) => {
    const editDataTs = useQuestionnaireStore.getState().editTsData;

    const body: any = {
      ...(isConsentQuestionnaire(data?.selectionType?.trim() ?? '')
        ? { staticContentType: data?.selectionType?.trim() }
        : { questionType: data?.selectionType?.trim() ?? 'TEXT_INPUT' }),
      ...(data?.parentQuestionId && { parentQuestionId: data?.parentQuestionId }),
      ...(data?.titleQuestionId && { titleQuestionId: data?.titleQuestionId }),
      data: {
        ...(data?.id && !data?.id?.includes('frontId') && { id: +data?.id }),
        questionTitle: data?.questionTitle ?? '',
        sortOrder: data?.sortOrder
          ? data?.sortOrder
          : isConsentQuestionnaire(data?.selectionType?.trim() ?? '')
            ? getMaxSortId(selectedQuestionnairy?.questionnaire?.staticContents) + 1
            : getMaxSortId(selectedQuestionnairy?.questionnaire?.questions) + 2,
        required: !!data?.required,
        ...(data?.description && { description: data?.description }),
        ...(data?.isScored != null ? { isScored: data?.isScored } : {}),
        ...getOptionalKeys(data?.selectionType?.trim(), data),
        ...(data?.optionalProps?.subtitle ? { subtitle: data?.optionalProps?.subtitle } : {}),
        qssId: data?.qssId,
      },
    };

    if (isConsentQuestionnaire(data?.selectionType?.trim() ?? '')) {
      addStaticQuestionMutate([body]);
    } else {
      isTranslation
        ? editDataTs?.key !== '' && questionTranslateEntity(isTranslation?.toUpperCase())
        : addQuestionMutate({ data: [body], reOrder: reOrder, qssId: data?.qssId });
    }
  };

  const handleDuplicateQuestion = (qsId: number) => {
    duplicateQuestionMutate(qsId);
  };

  const { mutate: handleDeleteQuestion } = useMutation<any, unknown, any, unknown>(
    (data: any) =>
      deleteQuestion(data, selectedQuestionnairy?.questionnaire?.id ?? questionnaireId),
    {
      onSuccess: (data: any, id: number) => {
        const filteredQuestions = questions.filter((qs: IQuestion) => qs?.id && +qs?.id !== id);
        const updatedQuestions: any = filteredQuestions.map((question) => {
          if (question.titleQuestions) {
            return {
              ...question,
              titleQuestions: question.titleQuestions?.filter(
                (inQs: any) => Number(inQs.id) !== id,
              ),
            };
          }
          return { ...question };
        });

        setQuestions(updatedQuestions);
        if (page === 'standard-form') {
          refetchStandardForms();
        } else refetchStepsQuery();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionDeleted' }),
        );
      },
    },
  );
  const { mutate: handleDeleteStaticQuestion } = useMutation<any, unknown, any, unknown>(
    (data: any) =>
      deleteStaticQuestion(data, selectedQuestionnairy?.questionnaire?.id ?? questionnaireId),
    {
      onSuccess: () => {
        // toast.success(intl.formatMessage({ id: 'error.questionDeletedSuccessfully' });
        // refetchSteps('delete', deleteId);
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionDelete' }),
        );
      },
    },
  );
  const handleRemoveQuestionApi = (question: IQuestion) => {
    if (!question) {
      // refetchSteps('front-delete', frontId);
    } else {
      // setDeleteId(questionId);

      if (isConsentQuestionnaire(question?.questionType)) {
        handleDeleteStaticQuestion(question?.id);
      } else {
        handleDeleteQuestion(question?.id);
      }
    }
  };
  const { mutate: saveStaticQuestionnairesMutation } = useMutation<
    unknown,
    unknown,
    ISaveQuestionnairesForm,
    unknown
  >((data: ISaveQuestionnairesForm) => saveStaticQuestionnaires(data), {
    onSuccess: () => {
      // toast.success(intl.formatMessage({ id: 'error.questionnaireSaved' }));
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ??
          intl.formatMessage({ id: 'error.questionnaireSaveError' }),
      );
    },
  });
  const { mutate: saveQuestionnairesMutation } = useMutation<
    unknown,
    unknown,
    ISaveQuestionnairesForm,
    unknown
  >((data: ISaveQuestionnairesForm) => saveQuestionnaires(data), {
    onSuccess: (data: any) => {
      if (page === 'standard-form') {
        setQuestions(data);
        refetchStandardForms();
      }
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ??
          intl.formatMessage({ id: 'error.questionnaireSaveError' }),
      );
    },
  });
  const handleReorderQuestions = (newQuestions: IQuestion[]) => {
    let payload: IQuestion[] = newQuestions
      ?.filter((item: IQuestion) => item?.questionTitle)
      .map((item: IQuestion) => {
        return {
          ...(item?.staticContentType
            ? { staticContentType: item.staticContentType }
            : { questionType: item.questionType }),
          ...(item?.parentQuestionId ? { parentQuestionId: item.parentQuestionId } : {}),
          ...(item?.titleQuestionId ? { titleQuestionId: item.titleQuestionId } : {}),
          data: {
            ...(selectedQuestionnairy?.questionnaire?.staticContents
              ? { header: item?.header }
              : { questionTitle: item?.questionTitle }),
            sortOrder: item?.sortOrder,
            required: !!item?.required,
            questionTitle: item?.questionTitle,
            updatedAt: item?.updatedAt,
            editable: item?.editable,
            ...((item?.automatic === false || item?.automatic === true) &&
              page === 'standard-form' && { automatic: item?.automatic }),
            ...(item?.questionCategory &&
              page === 'standard-form' && { questionCategory: item?.questionCategory }),
            id: item?.id,
            ...(item?.description && { description: item?.description }),
            ...(item.questionType === 'TEXT_INPUT' && {
              maxCharacters: item?.maxCharacters,
              inputPlaceHolder: item?.inputPlaceHolder,
              // inputType: item?.inputType ?? 'SHORT',
            }),
            ...(item.questionType === 'DATE_TIME' && {
              dateTimeType: item?.dateTimeType,
              dateTime: item?.dateTime,
              disableFutureDates: item?.disableFutureDates,
            }),
            ...(item.questionType === 'RATING' && {
              stars: item?.stars ?? 'THREE',
            }),
            ...(item?.questionOptions && { questionOptions: item?.questionOptions }),
            ...(item.questionType === 'SCALE' && {
              startValue: item?.startValue,
              increment: item?.increment,
              endValue: item?.endValue,
              startLegend: item?.startLegend,
              endLegend: item?.endLegend,
            }),
            ...(item.questionType === 'NUMBER' && {
              numberType: item?.numberType,
            }),
            ...(item.subtitle
              ? {
                  subtitle: item?.subtitle,
                }
              : {}),
            isScored: item?.isScored,
          },
        };
      });
    if (page === 'standard-form') {
      payload = payload.filter(
        (item: IQuestion) => !(item?.data?.editable == false || item?.data?.questionCategory),
      );
    }

    if (selectedQuestionnairy?.questionnaire?.staticContents?.length) {
      saveStaticQuestionnairesMutation({
        questionnaireId: selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
        data: payload,
      });
    } else {
      saveQuestionnairesMutation({
        questionnaireId: selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
        data: payload,
      });
      setSelectedQuestionnairy({
        ...selectedQuestionnairy,
        questionnaire: {
          ...selectedQuestionnairy?.questionnaire,
          questions: [...newQuestions],
        },
      });
    }
  };

  const { mutate: reOrderStepsMutate } = useMutation<IStep, unknown, IRorderStepsForm, unknown>(
    (data: IRorderStepsForm) => reOrderSteps(data),
    {
      onSuccess() {
        refetchStepsQuery();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message ?? 'Re-order error!');
      },
    },
  );
  const handleReorderSteps = (newItems: any) => {
    const tmpNewItems = flattenItems([...newItems]);
    const filteredTmpNewItems = [...tmpNewItems]?.filter((it: any) => it?.id);

    handleSwapItemsApi('update', [...filteredTmpNewItems]);
  };
  const getFolderByStepId = (stepId: string) => {
    const id = steps?.find((step: any) => +step?.id === +stepId)?.folder?.id;
    return id ? +id : null;
  };

  const flattenItems = (items: any[], prevIndex?: number): any[] => {
    let flatItems: any[] = [];

    items.forEach((item: any, index: number) => {
      const folderID = getFolderByStepId(item?.parentId);

      flatItems.push({
        id: +item?.id,
        sortOrder: (prevIndex ?? 0) + index + 1,
        folderId: folderID
          ? folderID
          : item?.parentId == null && item?.questionnaire?.id
            ? null
            : item?.folder?.id
              ? item?.folder?.id
              : null, // +data?.parentId === +sp?.id ? null : sp?.folder?.id,
        ...(item?.questionnaire?.id && { questionnaireId: item?.questionnaire?.id }),
        ...(item?.idVerification?.id && { idVerificationId: item?.idVerification?.id }),
        ...(item?.folder?.id && !item?.questionnaire?.id && { folderId: item?.folder?.id }),
        ...(item?.folder?.id &&
          item?.parentId &&
          !item?.questionnaire?.id && { parentFolderId: getFolderByStepId(item?.parentId) }),
      });
      if (item.children && item.children.length > 0) {
        flatItems = flatItems.concat(flattenItems(item.children, index + 1));
      }
    });

    return flatItems;
  };

  const handleSwapItemsApi = (actiontype: string, data: any) => {
    if (actiontype === 'remove-folder') {
      const newSteps: any = steps?.map((sp: any) => {
        return {
          id: sp?.id,
          sortOrder: sp?.sortOrder,
          folderId: +data?.parentId === +sp?.id ? null : sp?.folder?.id,
          ...(sp?.questionnaire?.id && { questionnaireId: sp?.questionnaire?.id }),
          ...(sp?.idVerification?.id && { idVerificationId: sp?.idVerification?.id }),
        };
      });

      reOrderStepsMutate({ studyId: studyId ?? 1, newList: newSteps });
    } else if (actiontype === 'attach') {
      const newSteps: any = steps?.map((sp: any, index: number) => {
        // const foundItem: any = data?.find((it: any) => it?.parentId && +it.parentId === +sp.id);
        return {
          id: sp?.id,
          sortOrder: index + 1, // foundItem?.sortOrder ?? sp?.sortOrder,
          folderId: +sp?.id === +data.stepId ? data?.folder?.id : sp?.folder?.id,
          ...(sp?.questionnaire?.id && { questionnaireId: sp?.questionnaire?.id }),
          ...(sp?.idVerification?.id && { idVerificationId: sp?.idVerification?.id }),
          // ...(sp?.folder?.id && { folderId: sp?.folder?.id }),
        };
      });

      reOrderStepsMutate({ studyId: studyId ?? 1, newList: newSteps });
    } else if (actiontype === 'newList') {
      reOrderStepsMutate({ studyId: studyId ?? 1, newList: data });
    } else if (actiontype === 'update') {
      reOrderStepsMutate({
        studyId: studyId ?? 1,
        newList: data.map((item: any, index: number) => ({ ...item, sortOrder: index + 1 })),
      });
    }
  };
  return {
    handleAddQSToQuestionnaire,
    handleRemoveQuestionApi,
    addQuestionLoading,
    handleReorderQuestions,
    handleReorderSteps,
    handleSwapItemsApi,
    standardForms,
    collapsedSubQuestions,
    setCollapsedSubQuestions,
    duplicateStepMutate,
    handleDuplicateQuestion,
  };
};
