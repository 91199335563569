import { FiUser, FiTrash2 } from 'react-icons/fi';
import { Select } from 'components/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MeetingStatus, MeetingStatusR, deleteAppointment, fetchAppointments, respondAppointment } from 'services/api/appointment';
import { toast } from 'react-toastify';
import { useStudyStore } from 'store/StudyStore';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

const AppointmentPopUp = ({ modalData, handleEdit, setJoinCall, isSiteStaff, setAppointId, checkType, videoData, videoLoading }: any) => {
  const intl = useIntl();
  const { loggedInStudyUser } = useStudyStore();
  const { participantId } = useParams();
  const [selectRes, setSelectedRes] = useState('');
  const [isJoin, setIsJoin] = useState(false);

  const { refetch } = useQuery({
    queryKey: ['appointmments'],
    queryFn: () => participantId && loggedInStudyUser && fetchAppointments(parseInt(`${participantId}`, 10), parseInt(`${loggedInStudyUser?.id}`)),
  });

  const { mutate: delAppointment } = useMutation<any, unknown, any, unknown>(
    () => deleteAppointment(modalData?.id),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'appointment.meetingDelete' }));
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: respondMeeting } = useMutation<any, unknown, string, unknown>(
    (status: string) => respondAppointment(modalData?.id, status),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'appointment.respondMeeting' }));
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const handleSelect = (e: any) => {
    setSelectedRes(e)
    switch (e) {
      case MeetingStatus.CONFIRMED:
        respondMeeting(MeetingStatusR.CONFIRMED);
        break;
      case MeetingStatus.TENTATIVE:
        respondMeeting(MeetingStatusR.TENTATIVE);
        break;
      case MeetingStatus.DECLINED:
        setIsJoin(false);
        respondMeeting(MeetingStatusR.DECLINED);
        break;
      default:
        setSelectedRes(e)
    }
  }

  useEffect(() => {
    if (modalData?.appointment) {
      switch (modalData?.appointment?.appointmentStatus) {
        case MeetingStatusR.CONFIRMED:
          setSelectedRes('accept');
          break;
        case MeetingStatusR.TENTATIVE:
          setSelectedRes('tentative');
          break;
        case MeetingStatusR.DECLINED:
          setSelectedRes('decline');
          setIsJoin(false);
          break;
        default:
          setSelectedRes('')
      }
    }
  }, []);
  
  useEffect(() => {
    if (modalData?.start && modalData?.end && modalData?.appointment?.appointmentStatus !== MeetingStatusR.DECLINED) {
      setIsJoin(true);
    }
  }, [modalData]);

  const checkAfterDate = () => {
    return modalData?.appointment?.appointmentStatus !== MeetingStatusR.DECLINED && 
      modalData?.appointment?.appointmentStatus !== MeetingStatusR.CONFIRMED && 
      isBefore(new Date(modalData?.start), new Date())
  }

  const checkJoin = () => {
    return checkType()
  }

  return (
    <div className='absolute z-10 top-[2.438rem] left-[50%] w-[20.875rem] h-[14.938rem] pl-3.5 pr-2.5 pt-[0.563rem] border border-blue-ocean-deep bg-white rounded-lg'>
      <div className=''>
        <div className='flex justify-between items-center'>
          <p className='text-2xl leading-[34px] font-serif'>{modalData?.title || ''}</p>
          {isSiteStaff && !checkAfterDate() && modalData?.appointment?.organizer === 'SITE_STAFF' && <FiTrash2 onClick={delAppointment} className='text-blue-ocean-deep text-xl' />}
        </div>
        <p className='text-base leading-6 mt-1 font-semibold'>{modalData?.dateTime || ''}</p>
        {isSiteStaff && (
          <div className='flex gap-2.5 items-center mt-4'>
            {checkJoin() && (
              <button
                onClick={() => {
                  !videoData && !videoLoading && setAppointId(modalData?.appointment?.id);
                  setJoinCall(true);
                }}
                disabled={!isJoin}
                className={`px-6 py-1 ${!isJoin ? 'bg-gray-medium cursor-not-allowed' : 'bg-blue-ocean-deep cursor-pointer'} text-white text-xs font-semibold leading-[15px] rounded-sm`}
              >
                <FormattedMessage id="appointment.join" />
              </button>
            )}
            {!checkAfterDate() && (modalData?.appointment?.organizer === 'SITE_STAFF' ?
              <button onClick={handleEdit} className='px-6 py-1 bg-white text-blue-ocean-deep border border-blue-ocean-deep text-xs font-semibold leading-[15px] rounded-sm'>
                <FormattedMessage id="edit" />
              </button>
              :
              <Select
                customControl={{ value: selectRes, onChange: (e) => handleSelect(e) }}
                name='rsvp'
                options={[
                  { label: 'appointment.accept', value: 'accept' },
                  { label: 'appointment.tentative', value: 'tentative' },
                  { label: 'appointment.decline', value: 'decline' },
                ]}
                placeholder={intl.formatMessage({ id: 'appointment.rsvp' })}
                className='bg-white '
                mainClass=' border-blue-ocean-deep !rounded-sm h-[1.438rem] min-w-[5rem]'
                buttonClassName='!gap-x-1 !px-3 py-1 text-blue-ocean-deep leading-[15px]'
                selectOptClass='!text-xs font-semibold'
                optionsClassName='!text-xs font-semibold !px-2 !py-1 !font-normal hover:!bg-blue-ocean-deep hover:text-white'
                optWrapperClassName='border border-blue-ocean-deep pt-1 pb-2'
              />
            )}
          </div>
        )}
      </div>
      <div className='mt-[0.688rem]'>
        {/* <div className='flex items-center gap-2 pl-[0.813rem] pt-2 pb-2.5 border-t-[0.5px] border-gray-300'>
          <FiLink2 className='text-xl' />
          <p className='text-sm leading-[22px] truncate w-[109px]'>link of meeting testing</p>
          <FiCopy className='text-xl' />
        </div> */}
        <div className='flex items-center gap-2 pl-[0.813rem] pt-2 pb-2.5 border-t-[0.5px] border-gray-300'>
          <FiUser className='text-xl' />
          <div>
            <p className='text-sm leading-[22px]'>{modalData?.appointment?.organizer === 'PATIENT' ? modalData?.appointment?.patient?.userProfile?.firstName : modalData?.centerUser?.user?.firstName  }</p>
            <p className='text-xs leading-[19px]'>
              <FormattedMessage id="appointment.organizer" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppointmentPopUp;