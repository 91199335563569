import React, { useCallback, useRef, FunctionComponent } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { TextInput } from 'components/common';
import clsx from 'classnames';

type WrapperComponentProps = any;
type WrappedComponentProps = any;

interface ScoreComponentProps {
  WrapperComponent?: FunctionComponent<WrapperComponentProps>;
  handleCallBack?: any;
  handleSetScores: any;
  props?: WrapperComponentProps & WrappedComponentProps;
  defaultValue?: any;
  defaultIsOpen?: boolean;
  inputPlaceHolder?: string;
  inputContainerClassName?: string;
  value: any;
  scores?: any;
  disabled?: any;
}

const ScoreComponent: FunctionComponent<ScoreComponentProps> = ({
  handleCallBack = () => {},
  handleSetScores,
  value,
  inputPlaceHolder = '',
  inputContainerClassName = '',
  disabled,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        handleCallBack();
      }
    },
    [value],
  );

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSetScores(value);
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className='w-[39px] h-[29px] border-1 rounded-[4px] border-gray'>
      <TextInput
        control={{
          value: value,
          onChange: (e: any) => {
            if (!e.target.value) handleSetScores('');
            else if (!isNaN(e.target.value) && e.target.value >= 0) {
              if (e.target.value < 0 || !e.target.value) handleSetScores('');
              else handleSetScores(e.target.value);
            }
          },
        }}
        type='text'
        name={'score'}
        disabled={disabled}
        placeholder={inputPlaceHolder ?? ''}
        noTranslation={true}
        className={clsx(
          'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:p-0 [&>input]:rounded [&>input]:border-none [&>input]:text-center [&>input]:pl-[2px] [&>input]:text-xs [&>input]:text-black',
          inputContainerClassName,
        )}
        inputClassName='border-r-none border-l-none border-t-none'
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default ScoreComponent;
