import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { Checkbox, Modal, TextInput, Tooltip } from 'components/common';
import { SubmitHandler } from 'react-hook-form';
import { IQuestionnaire, IQuestionnaireForm, IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import {
  questionnairesTranslateAI,
  questionnairesTranslateEntity,
  questionnairesTranslateFile,
  questionnaireTranslateApprove,
  updateQuestionnaire,
} from 'services/api/questionnaire';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { EditQuestionnaireForm } from './index';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { SiteStaffIcon, PatientsIcon } from './Icons';
import { SvgIcon } from '@mui/material';
import { getMaxSortId } from 'utils/number';
import DragDropList from './DragableQuestionsContainer/DragDropList';
import { useQuestionnaire } from './DragableQuestionsContainer/useQuestionnaire';
import { insertAtIndex } from 'utils/array';
import PreviewQuestions from './PreviewQuestions';
import ScheduleEpro from './ScheduleEpro';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { LanguageContext } from 'pages/App/LanguageContext';
import { languageMap } from 'types/util';
import SubDropdown from '../../../../components/common/SubDropdown';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { FiLoader } from 'react-icons/fi';
import ExcelJS from 'exceljs';
import { isExcelFileEmpty, isExcelFileValid, removeHtmlTags } from '../../../../utils/string';

interface IProps {
  initQuestionnaire: IQuestionnaire;
  refetchSteps: any;
  preview: boolean;
}

const QuestionnairePage: React.FC<IProps> = ({
  preview,
  initQuestionnaire,
  refetchSteps,
}: IProps) => {
  const { questionnaireId: questionnaireIdStr } = useParams();
  const { studyId } = useParams();
  const qssId = questionnaireIdStr?.split('-')?.[1];
  const [editTitle, setEditTitle] = useState(false);
  const [editType, setEditType] = useState('');
  const [changeQuestionnaireConfirmation, setChangeQuestionnaireConfirmation] = useState(false);
  const [styleType, setStyleType] = useState('horizontal');
  const [mobileView, setMobileView] = useState('mobile');
  const [translation, setTranslation] = useState<any>(null);
  const [newTitle, setNewTitle] = useState(initQuestionnaire?.questionnaire?.title);
  const [newTitle1, setNewTitle1] = useState(
    (translation &&
      initQuestionnaire?.questionnaire?.titleTranslations &&
      initQuestionnaire?.questionnaire?.titleTranslations[translation.toUpperCase()]) ||
      initQuestionnaire?.questionnaire?.title,
  );
  const [newFolder, setNewFolder] = useState(
    (translation &&
      initQuestionnaire?.folder?.nameTranslations &&
      initQuestionnaire?.folder?.nameTranslations[translation.toUpperCase()]) ||
      initQuestionnaire?.folder?.name,
  );
  const [newPFolder, setNewPFolder] = useState('');
  const [isAutoLoading, setIsAutoLoading] = useState(false);
  const [defaultIsOpen, setDefaultIsOpen] = useState<any>(false);
  const [approve, setApprove] = useState<any>(false);
  const [fileTranslation, setFileTranslation] = useState<any>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEditQuestionnaireModalOpen, setIsEditQuestionnaireModalOpen] = useState(false);
  const [eproModal, setEproModal] = useState(false);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const div1Ref: any = useRef(null);
  const div2Ref: any = useRef(null);

  const {
    steps,
    selectedQuestionnairy,
    setSelectedQuestionnairy,
    questions,
    setQuestions,
    setEditTsData,
    editTsData,
    showTranslate,
  } = useQuestionnaireStore();
  const { handleAddQSToQuestionnaire } = useQuestionnaire({});

  useEffect(() => {
    if (translation) {
      setNewTitle1(initQuestionnaire?.questionnaire?.titleTranslations[translation.toUpperCase()]);
      setNewFolder(initQuestionnaire?.folder?.nameTranslations[translation.toUpperCase()]);
      if (initQuestionnaire?.folder?.parentFolderId) {
        const findQust = steps?.find(
          (stp: any) =>
            stp?.folder && stp?.folder?.id === initQuestionnaire?.folder?.parentFolderId,
        );
        findQust &&
          findQust?.folder &&
          setNewPFolder(findQust?.folder?.nameTranslations[translation.toUpperCase()]);
      }
      initQuestionnaire?.questionnaire?.translationApproved &&
        setApprove(
          initQuestionnaire?.questionnaire?.translationApproved[translation.toUpperCase()],
        );
    }
  }, [translation, initQuestionnaire]);

  useEffect(() => {
    if (mobileView === 'mobile' && initQuestionnaire?.questionnaire?.type === 'E_PRO')
      setStyleType('vertical');
  }, [mobileView, styleType]);

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      event.preventDefault();
      if (clickedOut && event.target !== labelRef.current) {
        handleEditTitle(newTitle);
      }
    },
    [newTitle, editTsData],
  );
  useOutsideClick(containerRef, handleOutsideClick);

  const { mutate: questionTranslateAI } = useMutation<any, unknown, any, unknown>(
    (lang: string) => questionnairesTranslateAI(initQuestionnaire?.id, lang),
    {
      onSuccess() {
        refetchSteps();
        setIsAutoLoading(false);
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  useEffect(() => {
    if (!showTranslate) {
      setTranslation(null);
    }
  }, [showTranslate]);

  const { mutate: questionTranslateFile } = useMutation<any, unknown, any, unknown>(
    (data: any) => questionnairesTranslateFile(data),
    {
      onSuccess() {
        setTranslation(fileTranslation);
        refetchSteps();
        setIsAutoLoading(false);
      },
      onError(error: any) {
        setTranslation('');
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const { mutate: questionTranslateApprove } = useMutation<any, unknown, any, unknown>(
    ({ id, language, approveValue }: { id: number; language: string; approveValue: any }) =>
      questionnaireTranslateApprove(id, language, approveValue),
    {
      onSuccess() {
        refetchSteps();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  useEffect(() => {
    // const folders: any = [];
    if (Array.isArray(questions)) {
      const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

      if (fndEmptyItem === -1) {
        setQuestions(
          insertAtIndex(
            [...questions],
            {
              id: 0,
              questionTitle: '',
              sortOrder: getMaxSortId(questions) + 1,
              qssId: qssId,
            },
            questions.length - 1,
          ),
        );
      }
      const tmpQuestions: any = [...questions];
      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.parentQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.parentQuestionId === el?.id) {
              tmpQuestions[folderIndx]['children'] = el['children']
                ? tmpQuestions[folderIndx]['children']?.findIndex((it: any) => it.id === qs.id) < 0
                  ? [...el['children'], qs]
                  : [...el['children']]
                : [qs];
            }
          });
        }
      });

      // set sub title questions
      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.titleQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.titleQuestionId === el?.id) {
              tmpQuestions[folderIndx]['titleQuestions'] = el['titleQuestions']
                ? tmpQuestions[folderIndx]['titleQuestions']?.findIndex(
                    (it: any) => it.id === qs.id,
                  ) < 0
                  ? [...el['titleQuestions'], qs]
                  : [...el['titleQuestions']]
                : [qs];
            }
          });
        }
      });
    }
  }, [questions]);

  const { mutate: updateQuestionnaireMutation, isLoading: isEditQuestionnaireLoading } =
    useMutation<IQuestionnaireForm, unknown, IQuestionnaireForm, unknown>(
      (data: IQuestionnaireForm) => updateQuestionnaire(data),
      {
        onSuccess: () => {
          setSelectedQuestionnairy({
            ...selectedQuestionnairy,
            questionnaire: { ...selectedQuestionnairy?.questionnaire, title: newTitle },
          });

          setIsEditQuestionnaireModalOpen(false);
          refetchSteps();
        },
        onError(error: any) {
          toast.error(
            error?.response?.data?.message ??
              intl.formatMessage({ id: 'error.questionnaireUpdatedError' }),
          );
        },
      },
    );

  const { mutate: questionTranslateEntity } = useMutation<any, unknown, any, unknown>(
    (language: string) =>
      questionnairesTranslateEntity(
        parseInt(`${editTsData?.id}`, 10),
        language,
        editTsData?.key || '',
        parseInt(`${studyId}`, 10),
        editTsData?.value || '',
      ),
    {
      onSuccess() {
        refetchSteps();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const onSubmit: SubmitHandler<IQuestionnaireForm> = (data: any) => {
    updateQuestionnaireMutation({
      ...data,
      id: initQuestionnaire?.questionnaire?.id,
      sortOrder: initQuestionnaire?.questionnaire?.sortOrder,
      type: initQuestionnaire?.questionnaire?.type,
    });
  };

  const handleEditTitle = (title: string) => {
    if (title && editTsData?.key === '') {
      updateQuestionnaireMutation({
        title: title,
        id: initQuestionnaire?.questionnaire.id,
        sortOrder: initQuestionnaire?.questionnaire.sortOrder,
        type: initQuestionnaire?.questionnaire?.type,
      });
    } else if (editTsData?.key !== '' && editTsData?.value !== '') {
      questionTranslateEntity(translation?.toUpperCase());
    }
    setEditTitle(false);
    setEditType('');
  };

  const handleOnSaveQuestion = (
    tmpQuestion: IQuestion & { optionalProps?: any; qssId?: number },
    isTranslate: any,
  ) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const fndItem: any = questions.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire(
        {
          id: `${id}`,
          questionTitle: questionTitle,
          selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
          required: tmpQuestion?.required,
          maxCharacters: 100,
          ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
          inputPlaceHolder: 'placeholder ...',
          sortOrder: fndItem?.sortOrder,
          qssId: tmpQuestion?.qssId,
          optionalProps: {
            ...optionalProps,
            ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
            ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' && optionalProps?.isNew
              ? {
                  questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                    ...opt,
                    score: null,
                  })),
                }
              : {}),
            ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
            ...(tmpQuestion?.optionalProps?.numberType
              ? { numberType: tmpQuestion?.optionalProps?.numberType }
              : {}),
            ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),

            ...(tmpQuestion?.optionalProps?.dateTimeType
              ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
              : {}),
            ...(tmpQuestion?.inputType ? { inputType: tmpQuestion?.inputType } : {}),
            ...(tmpQuestion?.optionalProps?.inputType
              ? { inputType: tmpQuestion?.optionalProps?.inputType }
              : {}),
          },
          ...(tmpQuestion?.parentQuestionId
            ? { parentQuestionId: tmpQuestion?.parentQuestionId }
            : {}),
          ...(tmpQuestion?.titleQuestionId
            ? { titleQuestionId: tmpQuestion?.titleQuestionId }
            : {}),
          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
        },
        false,
        isTranslate ? isTranslate?.toUpperCase() : '',
      );
    } else {
      handleAddQSToQuestionnaire(
        {
          questionTitle: questionTitle,
          selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
          required: tmpQuestion?.required,
          maxCharacters: 100,
          ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
          inputPlaceHolder: 'placeholder ...',
          qssId: tmpQuestion?.qssId,
          optionalProps: {
            ...optionalProps,
            ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),

            ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF'
              ? {
                  questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                    ...opt,
                    label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
                    value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
                    score: null,
                  })),
                }
              : {}),
            ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
            ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          },
          ...(tmpQuestion?.parentQuestionId
            ? { parentQuestionId: tmpQuestion?.parentQuestionId }
            : {}),
          ...(tmpQuestion?.titleQuestionId
            ? { titleQuestionId: tmpQuestion?.titleQuestionId }
            : {}),
          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
        },
        false,
        isTranslate ? isTranslate?.toUpperCase() : '',
      );
    }
    // refetchSteps();
  };

  const handleType = (type: string) => {
    if (initQuestionnaire?.questionnaire?.type !== type) {
      updateQuestionnaireMutation({
        title:
          initQuestionnaire?.questionnaire?.titleTranslations?.[languageMap[locale]] ||
          initQuestionnaire?.questionnaire?.title,
        id: initQuestionnaire?.questionnaire?.id,
        sortOrder: initQuestionnaire?.questionnaire?.sortOrder,
        type,
      });
    }

    setChangeQuestionnaireConfirmation(false);
    if (type === 'E_PRO') {
      setEproModal(true);
    } else {
      setEproModal(false);
    }
  };
  const hasRepeatedQuestions = () =>
    questions?.findIndex((qs: IQuestion) => qs?.questionType === 'REPEATED') > -1;

  const handleImportTranslation = async (data: any, file: any, lang: string) => {
    setFileTranslation(lang.toLowerCase());
    const isEmpty = await isExcelFileEmpty(file);
    if (isEmpty) {
      toast.error(intl.formatMessage({ id: 'study-builder.fileMissingError' }));
      return;
    }
    const isValid = await isExcelFileValid(file, data?.questionnaire?.id);
    if (!isValid) {
      toast.error(intl.formatMessage({ id: 'study-builder.fileWrongError' }));
      return;
    }
    if (file && data?.id && lang) {
      setIsAutoLoading(true);
      questionTranslateFile({ file, stepId: data?.id, language: lang });
      setDefaultIsOpen(false);
    }
  };

  const handleTranslationAI = (lang: string) => {
    if (lang) {
      setDefaultIsOpen(false);
      setIsAutoLoading(true);
      questionTranslateAI(lang);
    }
  };

  const getLabel = () => {
    return langOpts?.find((option: any) => translation && `${translation}` === `${option?.value}`)
      ?.label;
  };

  const generateExcel = (data: any, otherLang: string, keyLang: string) => {
    // Define the data
    // Map data to an array of objects for Excel
    let sheetData: any = [];
    setDefaultIsOpen(false);

    const instructionsData = [
      [ intl.formatMessage({ id: 'study-builder.translationInstruction' }) ],
      [ intl.formatMessage({ id: 'study-builder.spreadsheetTranslation' }) ],
      [' '],
      [intl.formatMessage({ id: 'study-builder.spreadsheetIsDivided' })],
      [ intl.formatMessage({ id: 'study-builder.spreadsheetColumn1' }) ],
      [ intl.formatMessage({ id: 'study-builder.spreadsheetColumn2' }) ],
      [ intl.formatMessage({ id: 'study-builder.spreadsheetColumn3' }) ],
      [ intl.formatMessage({ id: 'study-builder.spreadsheetColumn4' }) ],
      [' '],
      [ intl.formatMessage({ id: 'study-builder.saveSpreadSheet' }) ],
    ];

    if (data && data?.folder) {
      if (data?.folder?.parentFolderId) {
        const foundData = steps?.find(
          (stp: any) => stp?.folder && stp?.folder?.id === data?.folder?.parentFolderId,
        );
        if (foundData.folder.name) {
          sheetData = [
            ...sheetData,
            {
              Key: 'folder_name',
              ID: `${foundData?.folder?.id}`,
              'Original language': removeHtmlTags(foundData.folder.name),
              [`${otherLang}`]: removeHtmlTags(foundData.folder.nameTranslations[keyLang] || ''),
            },
          ];
        }
      }
      if (data.folder.name) {
        sheetData = [
          ...sheetData,
          {
            Key: 'folder_name',
            ID: `${data?.folder?.id}`,
            'Original language': removeHtmlTags(data.folder.name),
            [`${otherLang}`]: removeHtmlTags(data.folder.nameTranslations[keyLang] || ''),
          },
        ];
      }
    }

    if (data && data?.questionnaire && data.questionnaire.title) {
      sheetData = [
        ...sheetData,
        {
          Key: 'questionnaire_title',
          ID: `${data?.questionnaire?.id}`,
          'Original language': removeHtmlTags(data.questionnaire.title),
          [`${otherLang}`]: removeHtmlTags(data.questionnaire.titleTranslations[keyLang] || ''),
        },
      ];

      if (data?.questionnaire?.questions && data?.questionnaire?.questions?.length > 0) {
        const questionsArr = data.questionnaire.questions.flatMap((qst: any) => {
          const entries = [];

          // Handle TITLE questions with subtitles
          if (qst.questionType === 'TITLE' && qst.subtitle) {
            if (qst.questionTitle) {
              entries.push({
                Key: 'question_title',
                ID: `${qst.id}`,
                'Original language': removeHtmlTags(qst.questionTitle),
                [`${otherLang}`]: removeHtmlTags(qst.titleTranslations[keyLang] || ''),
              });
            }
            entries.push({
              Key: 'question_subtitle',
              ID: `${qst.id}`,
              'Original language': removeHtmlTags(qst.subtitle),
              [`${otherLang}`]: removeHtmlTags(qst.subtitleTranslations[keyLang] || ''),
            });
          }

          // Handle DROPDOWN/RADIO_BUTTONS/CHECKBOX questions with options
          if (
            ['DROPDOWN', 'RADIO_BUTTONS', 'CHECKBOX'].includes(qst.questionType) &&
            qst.questionOptions?.length > 0
          ) {
            if (qst.questionTitle) {
              entries.push({
                Key: 'question_title',
                ID: `${qst.id}`,
                'Original language': removeHtmlTags(qst.questionTitle),
                [`${otherLang}`]: removeHtmlTags(qst.titleTranslations[keyLang] || ''),
              });
            }

            qst.questionOptions.forEach((opt: any) => {
              if (opt.label) {
                entries.push({
                  Key: 'question_option',
                  ID: `${opt.id}`,
                  'Original language': removeHtmlTags(opt.label),
                  [`${otherLang}`]: removeHtmlTags(opt.labelTranslations[keyLang] || ''),
                });
              }
            });
          }

          // Handle SCALE questions with legends
          if (qst.questionType === 'SCALE') {
            if (qst.questionTitle) {
              entries.push({
                Key: 'question_title',
                ID: `${qst.id}`,
                'Original language': removeHtmlTags(qst.questionTitle),
                [`${otherLang}`]: removeHtmlTags(qst.titleTranslations[keyLang] || ''),
              });
            }

            if (qst.startLegend) {
              entries.push({
                Key: 'scale_start_legend',
                ID: `${qst.id}`,
                'Original language': removeHtmlTags(qst.startLegend),
                [`${otherLang}`]: removeHtmlTags(qst.startLegendTranslations[keyLang] || ''),
              });
            }

            if (qst.endLegend) {
              entries.push({
                Key: 'scale_end_legend',
                ID: `${qst.id}`,
                'Original language': removeHtmlTags(qst.endLegend),
                [`${otherLang}`]: removeHtmlTags(qst.endLegendTranslations[keyLang] || ''),
              });
            }
          }

          if (entries.length === 0 && qst.questionTitle) {
            entries.push({
              Key: 'question_title',
              ID: `${qst.id}`,
              'Original language': removeHtmlTags(qst.questionTitle),
              [`${otherLang}`]: removeHtmlTags(qst.titleTranslations[keyLang] || ''),
            });
          }

          return entries;
        });
        sheetData = [...sheetData, ...questionsArr];
      }
    }

    // Create a worksheet and workbook
    const workbook = new ExcelJS.Workbook();
    const instructionsSheet = workbook.addWorksheet('Instructions');
    const translationSheet = workbook.addWorksheet('Translation');

    const headers = Object.values(instructionsData[0]);
    instructionsSheet.addRow(headers);
    headers.forEach((header, index) => {
      const cell = instructionsSheet.getCell(1, index + 1);
      cell.font = { bold: true, size: 14 };
    });

    instructionsSheet.columns = [{ width: 120 }];

    // Add data rows
    instructionsData.slice(1).forEach((row) => {
      const addedRow = instructionsSheet.addRow(Object.values(row));
      addedRow.eachCell((cell: any) => {
        cell.alignment = { wrapText: true };
      });
    });

    const headers1 = ['Key', 'ID', 'Original language', `${otherLang}`];
    translationSheet.addRow(headers1);
    headers1.forEach((header, index) => {
      const cell = translationSheet.getCell(1, index + 1);
      cell.font = { bold: true };
    });

    sheetData.forEach((row: any) => {
      translationSheet.addRow(Object.values(row));
    });

    // Save workbook as a file
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(
        blob,
        `${data?.questionnaire?.title || 'questionnaire-name'} ${otherLang} ${format(new Date(), 'yyyy-MM-dd HH.mm.ss')}.xlsx`,
      );
    });
  };

  const defaultLangOpts: any = [
    {
      value: 'en',
      label: 'lang.english',
      children: [
        {
          value: 'en-1',
          label: 'study-builder.downloadQuestionnaire',
          onClick: (data: any) =>
            generateExcel(data, intl.formatMessage({ id: 'lang.english' }), 'EN'),
        },
        {
          value: 'en-2',
          label: 'study-builder.importTranslation',
          onClick: (data: any, file: any) => handleImportTranslation(data, file, 'EN'),
        },
        {
          value: 'en-3',
          label: 'study-builder.automaticTranslation',
          onClick: () => handleTranslationAI('EN'),
        },
      ],
    },
    {
      value: 'pt',
      label: 'lang.portuguese',
      children: [
        {
          value: 'pt-1',
          label: 'study-builder.downloadQuestionnaire',
          onClick: (data: any) =>
            generateExcel(data, intl.formatMessage({ id: 'lang.portuguese' }), 'PT'),
        },
        {
          value: 'pt-2',
          label: 'study-builder.importTranslation',
          onClick: (data: any, file: any) => handleImportTranslation(data, file, 'PT'),
        },
        {
          value: 'pt-3',
          label: 'study-builder.automaticTranslation',
          onClick: () => handleTranslationAI('PT'),
        },
      ],
    },
    {
      value: 'ja',
      label: 'lang.japanese',
      children: [
        {
          value: 'ja-1',
          label: 'study-builder.downloadQuestionnaire',
          onClick: (data: any) =>
            generateExcel(data, intl.formatMessage({ id: 'lang.japanese' }), 'JA'),
        },
        {
          value: 'ja-2',
          label: 'study-builder.importTranslation',
          onClick: (data: any, file: any) => handleImportTranslation(data, file, 'JA'),
        },
        {
          value: 'ja-3',
          label: 'study-builder.automaticTranslation',
          onClick: () => handleTranslationAI('JA'),
        },
      ],
    },
    {
      value: 'fr',
      label: 'lang.french',
      children: [
        {
          value: 'fr-1',
          label: 'study-builder.downloadQuestionnaire',
          onClick: (data: any) =>
            generateExcel(data, intl.formatMessage({ id: 'lang.french' }), 'FR'),
        },
        {
          value: 'fr-2',
          label: 'study-builder.importTranslation',
          onClick: (data: any, file: any) => handleImportTranslation(data, file, 'FR'),
        },
        {
          value: 'fr-3',
          label: 'study-builder.automaticTranslation',
          onClick: () => handleTranslationAI('FR'),
        },
      ],
    },
  ];

  const [langOpts, setLangOpts] = useState(defaultLangOpts);

  const handleScroll = (sourceRef: any, targetRef: any) => {
    const sourceDiv = sourceRef.current;
    const targetDiv = targetRef.current;

    if (sourceDiv && targetDiv) {
      // Synchronize the scroll positions
      targetDiv.scrollTop = sourceDiv.scrollTop;
    }
  };

  const renderDragList = (translate: string) => {
    return (
      <div className={`px-9 ${translate ? 'pb-[6rem]' : ''}`}>
        <DragDropList
          questions={questions
            ?.filter((qs: IQuestion) => !qs.parentQuestionId && !qs?.titleQuestionId)
            ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
            .map((qs: IQuestion) => ({ ...qs, qssId: qssId }))}
          handleOnSaveQuestion={handleOnSaveQuestion}
          isTranslation={translate || ''}
          filterRepeatedInput={initQuestionnaire?.questionnaire?.type !== 'E_CRF'}
          disableHover={!!translate}
        />
      </div>
    );
  };

  useEffect(() => {
    setLangOpts(defaultLangOpts);
  }, []);

  useEffect(() => {
    setLangOpts(defaultLangOpts);
    if (
      initQuestionnaire?.questionnaire?.titleTranslations &&
      Object.keys(initQuestionnaire?.questionnaire?.titleTranslations)?.length > 0
    ) {
      const tempOpts: any = langOpts?.map((opt: any) => {
        const foundTranslation =
          (initQuestionnaire?.questionnaire?.titleTranslations &&
            initQuestionnaire?.questionnaire?.titleTranslations[opt?.value?.toUpperCase()]) ||
          '';
        const foundChild =
          opt?.children &&
          opt?.children.length > 0 &&
          opt?.children?.find((child: any) => child?.value === `${opt?.value}-0`);
        if (foundTranslation !== '' && opt?.children && opt?.children.length > 0) {
          if (!foundChild) {
            opt.children = [
              {
                value: `${opt?.value}-0`,
                label: 'study-builder.viewTranslation',
                onClick: () => setTranslation(opt?.value),
              },
              ...opt.children,
            ];
          }
          return opt;
        } else if (foundTranslation === '' && foundChild) {
          opt.children = opt.children.filter((child: any) => child?.value !== `${opt?.value}-0`);
          return opt;
        }
        return opt;
      });
      setLangOpts(tempOpts);
    }
  }, [initQuestionnaire]);

  return (
    <div className='w-full max-w-[1100px] h-full max-h-[calc(100vh-60px)] min-h-full overflow-hidden rounded-lg border-[1px] border-purple'>
      <Modal
        isOpen={isEditQuestionnaireModalOpen}
        onClose={() => setIsEditQuestionnaireModalOpen(false)}
        className={{ content: 'p-8 !bg-background' }}
        // title={<h1 className='text-blue-ocean-deep text-[32px]'>Update Questionnaire</h1>}
      >
        <EditQuestionnaireForm
          questionnaire={initQuestionnaire?.questionnaire}
          closeModal={() => setIsEditQuestionnaireModalOpen(false)}
          refetchSteps={refetchSteps}
          onSubmit={onSubmit}
          isLoading={isEditQuestionnaireLoading}
        />
      </Modal>
      <Modal
        isOpen={changeQuestionnaireConfirmation}
        onClose={() => setChangeQuestionnaireConfirmation(false)}
        className={{ content: 'p-8 !bg-background' }}
        // title={<h1 className='text-blue-ocean-deep text-[32px]'>Update Questionnaire</h1>}
      >
        <div className='w-[500px]'>
          <p className='my-4 mx-10 text-[#000]'>
            <FormattedMessage id='study-builder.are-you-sure-to-change-to-epro' />
          </p>

          <div className='flex justify-end'>
            <button
              onClick={() => setChangeQuestionnaireConfirmation(false)}
              className='border border-blue-ocean-deep text-blue-ocean-deep transition-opacity mr-3 w-fit py-[10px] px-5 rounded-[32px] hover:opacity-90 active:opacity-100 font-bold mt-4'
            >
              <FormattedMessage id='cancel' />
            </button>
            <button
              onClick={() => {
                handleType('E_PRO');
                // setTimeout(() => {
                // window.location.reload();
                // refetchSteps();
                // }, 200);
              }}
              className='bg-blue-ocean-deep transition-opacity text-white w-fit py-[10px] px-5 rounded-[32px] hover:opacity-90 active:opacity-100 font-bold mt-4'
              type='submit'
            >
              <FormattedMessage id='confirm' />
            </button>
          </div>
        </div>
      </Modal>
      <div className='bg-white flex justify-between items-center rounded-lg'>
        <div className='flex w-full justify-between items-center border-b border-purple pl-5 pr-7 rounded-t-lg'>
          {editTitle ? (
            <div ref={containerRef}>
              <TextInput
                control={{
                  value: newTitle,
                  onChange: (e) => {
                    setEditTsData({ id: -1, key: '', value: '' });
                    if (e.target.value) setNewTitle(e.target.value);
                  },
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleEditTitle(e.target.value);
                  }
                }}
                name={'title1'}
                placeholder='questionnaire-field.data-form.typeMessage'
                className='[&>input]:my-[18px] [&>input]:py-3 w-[300px] [&>input]:h-[36px]'
              />
            </div>
          ) : (
            <h2
              onClick={() => {
                setNewTitle(
                  initQuestionnaire?.questionnaire?.titleTranslations?.[languageMap[locale]] ||
                    initQuestionnaire?.questionnaire?.title,
                );
                setEditTsData({ id: -1, key: '', value: '' });
                setEditTitle(true);
              }}
              className='block gap-x-2 justify-start items-center text-blue-ocean-deep text-[24px] font-normal text-left py-5 w-[40rem] truncate text-ellipsis max-w-[23rem]'
            >
              {initQuestionnaire?.questionnaire?.titleTranslations?.[languageMap[locale]] ||
                initQuestionnaire?.questionnaire?.title}
            </h2>
          )}
          <div className='flex justify-end items-center gap-x-2 relative'>
            <span onClick={() => handleType('E_CRF')} className='flex cursor-pointer'>
              <SvgIcon
                className={`100 mr-1 ${
                  initQuestionnaire?.questionnaire?.type === 'E_CRF'
                    ? 'text-blue-normal'
                    : 'text-blue-oil'
                }`}
                component={SiteStaffIcon}
              />
              <Tooltip
                className='ml-[13px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.ecrfView' })}
              >
                <span
                  className={`font-light ${
                    initQuestionnaire?.questionnaire?.type === 'E_CRF'
                      ? 'text-blue-normal'
                      : 'text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='roles.SITE_STAFF' />
                </span>
              </Tooltip>
            </span>
            <span
              onClick={() => {
                if (hasRepeatedQuestions()) setChangeQuestionnaireConfirmation(true);
                else handleType('E_PRO');
              }}
              className='ml-2 flex cursor-pointer'
            >
              <SvgIcon
                className={`100 mr-1 font-light ${
                  initQuestionnaire?.questionnaire?.type === 'E_PRO'
                    ? 'text-blue-normal'
                    : 'text-blue-oil'
                }`}
                component={PatientsIcon}
              />
              <Tooltip
                className='ml-[10px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.eproView' })}
              >
                <span
                  className={`font-light ${
                    initQuestionnaire?.questionnaire?.type === 'E_PRO'
                      ? 'text-blue-normal'
                      : 'text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='study-dashboard.patients' />
                </span>
              </Tooltip>
            </span>
            {eproModal ? (
              <ScheduleEpro
                eproModal={eproModal}
                setEproModal={setEproModal}
                hasEpro={initQuestionnaire?.questionnaire?.eproSchedule}
              />
            ) : null}
          </div>
        </div>
      </div>
      {preview ? (
        <PreviewQuestions
          preview={true}
          styleType={styleType}
          mobileView={mobileView}
          setStyleType={setStyleType}
          handleType={handleType}
          initQuestionnaire={initQuestionnaire}
          questionnaire={initQuestionnaire}
          setChangeQuestionnaireConfirmation={setChangeQuestionnaireConfirmation}
          hasRepeatedQuestions={hasRepeatedQuestions}
          setMobileView={setMobileView}
          translation={translation}
        />
      ) : (
        <div className='bg-white py-8 rounded-lg h-full'>
          <div
            ref={div1Ref}
            onScroll={() => handleScroll(div1Ref, div2Ref)}
            className={`${showTranslate ? 'h-1/2 max-h-1/2 ' : 'h-[94%] '}  overflow-y-auto relative`}
          >
            {showTranslate && (
              <div className='px-9'>
                <div className='flex justify-end mb-6'>
                  <Tooltip
                    className='ml-16 -mt-[5px]'
                    text={intl.formatMessage({ id: 'study-builder.tooltip.selectTranslation' })}
                  >
                    <SubDropdown
                      name='translation'
                      customControl={{
                        value: translation || '',
                        onChange: (e: any) => setTranslation(e),
                      }}
                      defaultIsOpen={defaultIsOpen}
                      initQuestionnaire={initQuestionnaire}
                      placeholder={intl.formatMessage({ id: 'study-builder.selectTranslation' })}
                      options={langOpts.filter((option: any) => {
                        if (initQuestionnaire?.questionnaire?.language === null) {
                          return option?.value?.toLowerCase() !== 'en';
                        }
                        return (
                          option?.value?.toLowerCase() !==
                          initQuestionnaire?.questionnaire?.language?.toLowerCase()
                        );
                      })}
                    />
                  </Tooltip>
                </div>
                <div className='flex flex-col items-center justify-center mb-6'>
                  <p className='text-center font-semibold text-blue-normal pb-[4px]'>
                    <FormattedMessage id='study-builder.originalLanguage' />
                  </p>
                  <div className='h-[1px] w-[9.75rem] bg-blue-normal' />
                </div>
              </div>
            )}
            {renderDragList('')}
          </div>
          <hr className='bg-purple w-full mb-5 mt-[0.938rem]' />
          {showTranslate && (
            <div
              ref={div2Ref}
              onScroll={() => handleScroll(div2Ref, div1Ref)}
              className='h-1/2 max-h-1/2 overflow-y-auto relative'
            >
              {translation &&
                (isAutoLoading ? (
                  <div className='flex my-[9rem] flex-col justify-center'>
                    <FiLoader className='w-[4.2rem] h-[4.2rem] mb-6 animate-spin place-self-center text-blue-ocean-deep' />
                    <p className='text-blue-oil text-sm'>
                      <FormattedMessage id='study-builder.loadingTranslationWait' />
                    </p>
                  </div>
                ) : (
                  <div className='relative'>
                    <div className='flex flex-col items-center justify-center mb-6'>
                      <p className='text-center font-semibold text-blue-normal pb-[4px]'>
                        {intl.formatMessage({ id: getLabel() })}
                      </p>
                      <div className='h-[1px] w-[9.75rem] bg-blue-normal' />
                    </div>
                    {initQuestionnaire?.questionnaire?.translationWithAI &&
                      initQuestionnaire?.questionnaire?.translationWithAI[
                        translation.toUpperCase()
                      ] && (
                        <div className='absolute top-0 right-0 pr-6'>
                          <Checkbox
                            control={{
                              value: approve,
                              checked: approve,
                              onChange: () => {
                                setApprove(!approve);
                                questionTranslateApprove({
                                  id: initQuestionnaire?.questionnaire?.id,
                                  language: translation.toUpperCase(),
                                  approveValue: !approve,
                                });
                              },
                            }}
                            name='approveTranslation'
                            mainClass='!w-[180px]'
                            label={intl.formatMessage({ id: 'study-builder.approveTranslation' })}
                            labelClass='!text-blue-oil !font-normal'
                            inputClass='!accent-base-white !bg-base-white !border-[1.185px] !w-4 !h-4 !shrink-0 !border-blue-oil !text-blue-oil !appearance-none'
                            svgClass={approve ? 'stroke-blue-oil' : ''}
                          />
                        </div>
                      )}
                    <div className='flex px-9 items-baseline max-w-[calc(100vw_-_34rem)] 2xl:max-w-[calc(100vw_-_52rem)] w-full flex-wrap'>
                      {initQuestionnaire?.folder?.parentFolderId &&
                        (editType === 'newPFolder' ? (
                          <div ref={containerRef} className={`flex items-center ${newPFolder?.length > 20 ? 'w-full' : 'w-max'}`}>
                            <TextInput
                              control={{
                                value: newPFolder,
                                onChange: (e) => {
                                  setEditTsData({
                                    id: initQuestionnaire?.folder?.parentFolderId,
                                    key: 'folder_name',
                                    value: e.target.value,
                                  });
                                  if (e.target.value) setNewPFolder(e.target.value);
                                },
                              }}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                  handleEditTitle(e.target.value);
                                }
                              }}
                              name={'folder'}
                              placeholder='questionnaire-field.data-form.typeMessage'
                              className={`[&>input]:py-3 mb-4 ${newPFolder?.length > 20 ? 'w-full' : 'w-max'} [&>input]:h-[28px] !mt-0`}
                              inputClassName='!border-blue-normal-35 !rounded'
                            />{' '}
                            <span className='text-blue-ocean-deep text-lg font-medium px-1'>/</span>
                          </div>
                        ) : (
                          <h2
                            onClick={() => {
                              setEditTsData({
                                id: initQuestionnaire?.folder?.parentFolderId,
                                key: 'folder_name',
                                value: newPFolder,
                              });
                              setNewPFolder(newPFolder);
                              setEditType('newPFolder');
                            }}
                            className='block gap-x-2 justify-start break-all items-center text-blue-ocean-deep text-[24px] font-normal text-left pb-3.5 w-max whitespace-break-spaces'
                          >
                            {newPFolder ?? ''}
                            <span className='text-blue-ocean-deep text-lg font-medium px-1'>/</span>
                          </h2>
                        ))}
                      {initQuestionnaire?.folder?.name &&
                        (editType === 'newFolder' ? (
                          <div ref={containerRef} className={`flex items-center ${newFolder?.length > 20 ? 'w-full' : 'w-max'}`}>
                            <TextInput
                              control={{
                                value: newFolder,
                                onChange: (e) => {
                                  setEditTsData({
                                    id: initQuestionnaire?.folder?.id,
                                    key: 'folder_name',
                                    value: e.target.value,
                                  });
                                  if (e.target.value) setNewFolder(e.target.value);
                                },
                              }}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                  handleEditTitle(e.target.value);
                                }
                              }}
                              name={'folder'}
                              placeholder='questionnaire-field.data-form.typeMessage'
                              className={`[&>input]:py-3 mb-4 ${newFolder?.length > 20 ? 'w-full' : 'w-max'} [&>input]:h-[28px] !mt-0`}
                              inputClassName='!border-blue-normal-35 !rounded'
                            />{' '}
                            <span className='text-blue-ocean-deep text-lg font-medium px-1'>/</span>
                          </div>
                        ) : (
                          <h2
                            onClick={() => {
                              setEditTsData({
                                id: initQuestionnaire?.folder?.id,
                                key: 'folder_name',
                                value: newFolder.trim(),
                              });
                              setNewFolder(newFolder);
                              setEditType('newFolder');
                            }}
                            className='block gap-x-2 justify-start break-all items-center text-blue-ocean-deep text-[24px] font-normal text-left pb-3.5 w-max whitespace-break-spaces'
                          >
                            {newFolder ?? ''}
                            <span className='text-blue-ocean-deep text-lg font-medium px-1'>/</span>
                          </h2>
                        ))}
                      {editType === 'newTitle1' ? (
                        <div ref={containerRef} className={`${newTitle1?.length > 20 ? 'w-full' : 'w-max'}`}>
                          <TextInput
                            control={{
                              value: newTitle1?.replace(/"/g, ''),
                              onChange: (e) => {
                                setEditTsData({
                                  id: initQuestionnaire?.questionnaire?.id,
                                  key: 'questionnaire_title',
                                  value: e.target.value.replace(/"/g, ''),
                                });
                                if (e.target.value) setNewTitle1(e.target.value);
                              },
                            }}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                handleEditTitle(e.target.value);
                              }
                            }}
                            name={'title2'}
                            placeholder='questionnaire-field.data-form.typeMessage'
                            className={`[&>input]:py-3 mb-4 ${newTitle1?.length > 20 ? 'w-full' : 'w-max'} [&>input]:h-[28px] !mt-0`}
                            inputClassName='!border-blue-normal-35 !rounded'
                          />
                        </div>
                      ) : (
                        <h2
                          onClick={() => {
                            setEditTsData({
                              id: initQuestionnaire?.questionnaire?.id,
                              key: 'questionnaire_title',
                              value: newTitle1.trim(),
                            });
                            setNewTitle1(newTitle1);
                            setEditType('newTitle1');
                          }}
                          className='block gap-x-2 justify-start break-all items-center text-blue-ocean-deep text-[24px] font-normal text-left pb-3.5 w-max whitespace-break-spaces'
                        >
                          {newTitle1?.replace(/"/g, '') ?? ''}
                        </h2>
                      )}
                    </div>
                    {renderDragList(translation)}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionnairePage;
