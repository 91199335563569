import { useState, useEffect, useContext } from 'react';
import { IQuestion } from 'types/questionnaire';
import Question from './Question';
import { useParams } from 'react-router-dom';
import { LanguageContext } from 'pages/App/LanguageContext';
import { languageMap } from 'types/util';
import { createIntl, createIntlCache, FormattedMessage } from 'react-intl';
import { loadMessages } from '../loadMessages';
import { useQuestionnaireStore } from '../../../../../store/QuestionnaireStore';

interface IProps {
  questions: IQuestion[];
  questionnaire?: any;
  getAnswer?: any;
  methods?: any;
  translation?: string;
  handleNext: any;
  handlePrev: any;
  setCurrentStep: any;
  currentStep: any;
  subCurrentStep: any;
  completedSteps: any;
}

const MobileView = ({
  questions, questionnaire, getAnswer, methods, translation, handleNext,
  subCurrentStep, currentStep, setCurrentStep, completedSteps, handlePrev
}: IProps) => {
  const { questionnaireId } = useParams();
  const [messages, setMessages] = useState<any>(null);
  const { locale } = useContext(LanguageContext);
  const { showTranslate } = useQuestionnaireStore();
  const [defaultLocale, setDefaultLocale] = useState<string>(locale);
  questions =
    questions
      ?.filter((qs: any) => qs?.questionTitle)
      ?.filter((qs: any) => !(qs?.questionType === 'TITLE' && !qs?.titleQuestions))
      ?.filter((qs: any) => !qs?.titleQuestionId) ?? [];

  useEffect(() => {
    if (translation) {
      setDefaultLocale(translation);
      loadMessages(translation || 'en').then((data: any) => setMessages(data));
    }
  }, [translation]);

  useEffect(() => {
    if (locale && !translation && !showTranslate) {
      setDefaultLocale(locale);
    } else if (showTranslate && !translation) {
      setDefaultLocale(questionnaire?.questionnaire?.language || 'en');
    }
  }, [locale]);

  const formatMessageInLanguage = (id: string, language: any) => {
    const cache = createIntlCache();

    const customIntl = createIntl(
      {
        locale: language,
        messages, // Pass the translations for the given language
      },
      cache
    );

    return (messages && customIntl && customIntl.formatMessage({ id })) || '';
  };

  useEffect(() => {
    setCurrentStep(0);
  }, [questionnaireId]);

  const question: any = questions?.[currentStep];

  const progressPercentage = ((completedSteps.length + 1) / questions.length) * 100;
  const checkType = () => {
    return questionnaire?.type && questionnaire?.type === 'E_CRF';
  };

  const foundAns: any = question?.id && getAnswer(question?.id);
  return (
    <div className='relative mt-[30px]'>
      <img
        className='w-[245px] h-[515px] rounded-[21px] scale-[1.1] bg-white'
        src={'/assets/images/study/preview/iphone-template.svg'}
        alt='iphone-template'
      />
      <div className='w-[245px] absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-opacity-50'>
        <div className='flex flex-col  rounded w-full h-full pt-2'>
          <div className='flex justify-between items-center w-full'>
            <img
              className='w-[34px] h-[30px] cursor-pointer'
              src={'/assets/images/study/preview/left-arrow.svg'}
              alt='left-arrow'
              onClick={handlePrev}
            />
            <img
              className='w-5 h-5 '
              src={'/assets/images/study/preview/close-round.svg'}
              alt='close'
            />
          </div>

          <div className='flex items-center justify-between h-10 w-[254px] -ml-1'>
            <div className='w-full bg-[rgb(254,232,232)] h-1 '>
              <div
                className='bg-[rgb(35,114,209)] h-1 max-w-full'
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>
          <div className='py-4 h-[485px] overflow-y-auto pr-1'>
            <Question
              methods={methods}
              {...question}
              isLastQuestion={false} // `${i}` === `${filteredQuestions.length - 1}`}
              checkType={checkType}
              onBlur={() => {}}
              title={
                question?.titleTranslations?.[languageMap[defaultLocale]] || question?.questionTitle || ''
              }
              answer={foundAns && foundAns?.optionId ? foundAns?.optionId : foundAns?.answer}
              preview={true}
              previewMobileStyle={true}
              questionNumber={currentStep + 1}
              mobileView={true}
              translation={translation}
            />
            {question?.questionType === 'TITLE' ? (
              <Question
                methods={methods}
                {...question?.titleQuestions?.[subCurrentStep]}
                isLastQuestion={false}
                checkType={checkType}
                onBlur={() => {}}
                title={
                  question?.titleQuestions?.[subCurrentStep]?.titleTranslations?.[
                    languageMap[defaultLocale]
                  ] ||
                  question?.titleQuestions?.[subCurrentStep]?.questionTitle ||
                  ''
                }
                answer={foundAns && foundAns?.optionId ? foundAns?.optionId : foundAns?.answer}
                preview={true}
                previewMobileStyle={true}
                questionNumber={subCurrentStep}
                mobileView={true}
                translation={translation}
              />
            ) : null}
          </div>
          <div className='flex justify-end pr-1 pl-1 flex-col -mb-4'>
            <button
              onClick={handleNext}
              className='w-full text-xs rounded-full bg-blue-ocean-deep hover:bg-blue-70 text-white font-bold h-9'
            >
              {translation ? formatMessageInLanguage('next', translation) : <FormattedMessage id='next' />}
            </button>
            <div className='mt-[18px] w-[30%] h-1 rounded-full bg-[rgb(202,202,202)] mx-auto'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileView;
