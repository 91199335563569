import { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import { FiCheck, FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { CustomControl } from '../Select/CustomControl';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useIntl } from 'react-intl';
import Tooltip from '../Tooltip';

interface Option {
  label: string;
  value: string | number;
  children?: Option[]; // For nested submenus
  onClick?: any; // For nested submenus
}

interface IProps extends UseControllerProps<any> {
  placeholder?: string;
  options: Option[];
  customControl?: CustomControl;
  label?: string;
  disabled?: boolean;
  isTooltip?: boolean;
  onBlur?: (e: any) => void;
  className?: string;
  buttonClassName?: string;
  iconClass?: string;
  style?: any;
  optionsClassName?: string;
  errors?: FieldErrors<FieldValues>;
  mainClass?: string;
  selectOptClass?: string;
  noErrorMessage?: boolean;
  optWrapperClassName?: string;
  noTranslation?: boolean;
  isCheckIcon?: boolean;
  onFocus?: any;
  isSearchable?: any;
  defaultIsOpen?: any;
  defaultFileCheck?: any;
  initQuestionnaire?: any;
}

function SubDropdown({
  placeholder,
  options,
  isTooltip,
  customControl,
  label,
  disabled,
  style,
  onBlur,
  buttonClassName,
  errors,
  className,
  iconClass,
  mainClass,
  selectOptClass,
  optionsClassName,
  noErrorMessage = false,
  optWrapperClassName,
  noTranslation,
  onFocus,
  isCheckIcon,
  isSearchable,
  defaultIsOpen,
  initQuestionnaire,
  ...rest
}: IProps) {
  const intl = useIntl();
  const props = customControl ? customControl : useController(rest)?.field;
  const errorMsg = errors?.[rest.name]?.message as string | undefined;
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [selectedOption, setSelectedOption] = useState(props?.value);
  const [filteredOpts, setFilteredOpts] = useState<any>();
  const [searchQuery, setSearchQuery] = useState(''); // Search input state
  const [subOptHover, setSubOptHover] = useState<any>('');
  const [fileOption, setFileOption] = useState<any>(null); // Track option for file input

  const inputFile: any = useRef(null);

  useEffect(() => {
    setIsOpen(false);
  }, [defaultIsOpen]);

  useEffect(() => {
    setSelectedOption(props?.value);
    if (props?.value === undefined) {
      setSearchQuery('');
    }
  }, [props?.value]);

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  useEffect(() => {
    if (selectedOption && isCheckIcon && searchQuery === '') {
      const newOpts = options?.filter(
        (opt: any) => opt?.value && selectedOption != opt?.value,
      );
      const selectedOpts = options?.find(
        (opt: any) => opt?.value && selectedOption === opt?.value,
      );
      setFilteredOpts([selectedOpts, ...newOpts]);
    } else if (options && options?.length > 0 && searchQuery === '') {
      setFilteredOpts(options);
    }
  }, [options, selectedOption, searchQuery]);

  useEffect(() => {
    searchQuery &&
    setFilteredOpts(
      options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [searchQuery, options]);

  const getLabel = () => {
    return options?.find((option: any) => {
      const newValue = option?.value && option?.value?.includes('-') ? option.value?.split('-')[0] : option.value;
      return newValue && `${selectedOption}` === `${option?.value}`;
    })?.label;
  };

  useEffect(() => {
    if (fileOption && fileOption?.value && inputFile?.current) {
      inputFile.current.click();
    }
  }, [fileOption, inputFile]);

  const handleSubOpt = (option: any, newValue: any) => {
    const optId: any =  option.value?.split('-')[1];
    setIsOpen(false);
    if (optId === '2') {
      setFileOption(option);
      return;
    }
    if (optId === '1') {
      props.onChange('' as string);
      option?.onClick && option?.onClick?.(initQuestionnaire);
    } else {
      props.onChange(newValue as string);
      option?.onClick && option?.onClick?.();
    }
  }

  const handleSelectOption = useCallback((option: any) => {
      if (option.value && option.value?.includes('-')) {
        setIsOpen(false);
        const newValue: any =  option.value?.split('-')[0];
        const newOpt = options.find((opt) => opt.value === newValue);
        isSearchable && newValue && newOpt && setSearchQuery(newValue === null ? '' : noTranslation ? newOpt?.label : intl.formatMessage({ id: `${newOpt?.label}` }));
        setSelectedOption(newValue);
        handleSubOpt(option, newValue);
        return;
      }
      const newValue: any =  option.value;
      const newOpt = options.find((opt) => opt.value === newValue);
      isSearchable && newValue && newOpt && setSearchQuery(newValue === null ? '' : noTranslation ? newOpt?.label : intl.formatMessage({ id: `${newOpt?.label}` }));
      setSelectedOption(newValue);
      props.onChange(newValue as string);
      onBlur?.(newValue);
      onFocus?.();
      toggleOpen();
    },
    [setSelectedOption, props.value, isOpen],
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
        setFileOption(null);
      }
    },
    [setIsOpen],
  );

  const getTooltip = (optIndex: any) => {
    switch (optIndex) {
      case '1':
        return 'study-builder.tooltip.downloadFile';
      case '2':
        return 'study-builder.tooltip.importTranslation';
      case '3':
        return 'study-builder.tooltip.autoTranslation';
      default:
        return '';
    }
  }

  const renderOptions = (opts: Option[], level = 0) => (
    <div
      className={clsx(
        'absolute flex z-100 border-blue-ocean-deep box-content max-h-[11.4rem] no-scrollbar bg-base-white origin-top -left-[0.5px] right-0 bottom-0',
        isOpen ? 'min-h-fit border' : 'hidden',
        optWrapperClassName,
        level === 1 ? ' rounded py-1 !w-max top-0 ' : ' border-t-0 rounded-b !w-[99.9%] top-full ',
        filteredOpts && filteredOpts?.length > 4 ? 'h-[11.4rem]' : 'h-auto',
      )}
    >
      <ul className={clsx('w-full !pl-0')}>
        {opts.map((option: any) => {
          const tooltipText = getTooltip(option.value?.split('-')[1]);
          return (
            <Tooltip
              key={option.value}
              className='ml-16 -mt-[5px] z-[9999]'
              text={tooltipText ? intl.formatMessage({ id: tooltipText }) : ''}
            >
              <li
                onMouseEnter={() => setSubOptHover(option.value)}
                className={clsx(
                  'hover:bg-gray-100 flex group justify-between items-center py-0.5 cursor-pointer ',
                  isCheckIcon && option.value === selectedOption && ' font-semibold ',
                  optionsClassName,
                  level === 1 ? ' px-1 ' : ' px-2 relative ',
                )}
                onClick={() => !option.children && handleSelectOption(option)}
              >
                <div className='flex items-center text-sm gap-2'>
                  {!option.children && option.value?.split('-')?.length > 0 && option.value?.split('-')[1] === '2' && fileOption?.value === option.value && (
                    <input
                      ref={inputFile}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={(e: any) => {
                        if (fileOption?.onClick && e.target.files?.[0]) {
                          fileOption.onClick(initQuestionnaire, e.target.files?.[0]); // Call the specific option's `onClick` handler
                        }
                        setFileOption(null);
                      }}
                    />
                  )}
                  {isCheckIcon && option.value === selectedOption && (
                    <FiCheck className='text-blue-500' />
                  )}
                  {noTranslation ? option.label : intl.formatMessage({ id: `${option.label}` })}
                </div>
                {option.children && <FiChevronRight />}
                {option.children && (
                  <div
                    className={`absolute -left-full top-0 ${subOptHover === option.value ? '' : 'hidden'} w-full group-hover:block -ml-[18px]`}
                  >
                    {renderOptions(option.children, level + 1)}
                  </div>
                )}
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </div>
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getValue = () => {
    return searchQuery
    ? searchQuery
    : !isEdit && !isOpen && selectedOption
    ? noTranslation
    ? getLabel()
    : intl.formatMessage({id: `${getLabel()}`})
    : ''
  }

  const renderBtn = () => {
    return (
      <button
        type='button'
        disabled={disabled}
        id={`button-${rest.name}`}
        onClick={() => {
          toggleOpen();
          setFileOption(null);
          !isOpen && setSearchQuery('');
        }}
        className={clsx(
          `flex w-full flex-1 gap-x-2 min-w-[9.56rem] outline-none focus:outline-none ring-0 items-center px-2 h-[24px] rounded ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`,
          buttonClassName,
        )}
      >
        {isSearchable ? (
          <input
            type="text"
            value={getValue()}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              e.target.value === '' && setIsEdit(true);
              setIsOpen(true);
            }}
            disabled={disabled}
            placeholder={placeholder}
            className={`w-full !bg-white !text-blue-ocean-deep text-base truncate flex-1 !text-left text-ellipsis focus:outline-none focus:ring-0 focus:border-none border-none outline-none ${selectOptClass}`}
          />
        ) : (
          <p
            className={clsx(
              'text-sm truncate flex-1 !text-left w-[50%] text-ellipsis',
              (!selectedOption || selectedOption === ' ') && 'text-blue-ocean-deep',
              selectOptClass,
            )}
          >
            {selectedOption === ' '
              ? placeholder
              : selectedOption
                ? noTranslation
                  ? getLabel()
                  : intl.formatMessage({ id: `${getLabel()}` })
                : placeholder}
          </p>
        )}
        <FiChevronDown
          className={clsx('transition-all ', iconClass ? iconClass : isOpen && ' -rotate-180')}
        />
      </button>
    );
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={`button-${rest.name}`}
          ref={labelRef}
          className="text-left w-full text-blue-ocean-deep mb-2 inline-block leading-6 font-semibold"
        >
          {label}
        </label>
      )}
      <div
        ref={containerRef}
        style={style}
        className={clsx(
          isOpen ? 'border border-b-transparent rounded-b-none' : 'border',
            ` rounded flex items-center relative max-h-[3rem] text-blue-ocean-deep ${errors?.[rest.name]?.message ? 'border-danger-100 border-2' : 'border-blue-ocean-deep'}`,
            mainClass,
            isOpen && 'open',
          )}
        >
          {isTooltip ? (
            <Tooltip
              position='right'
              arrowClass='top-[6px]'
              className='w-max'
              text={isTooltip ? getLabel() : ''}
            >
              {renderBtn()}
            </Tooltip>
          ) : (
            renderBtn()
          )}
          {renderOptions(filteredOpts || options)}
        </div>
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default memo(SubDropdown);
