import { ICenter } from 'types/centers';
import { useParticipantStore } from 'store/participantStore';
import ParticipantsListItem from './ParticipantsListItem';
import AddNewPatient from './AddNewPatient';
import { useStudyStore } from 'store/StudyStore';
import { useIntl } from 'react-intl';
import { useCentersStore } from '../../../store/centers';
import usePermissions from '../../../hooks/usePermissions';

interface IProps {
  centersData?: any;
  status?: string;
  onRowDelete?: (item: ICenter) => void;
  isFreezed: boolean;
}

export default function ParticipantsList(props: IProps) {
  const intl = useIntl();
  const { centersData, status } = props;
  const { loggedInStudyUser, currentStudy } = useStudyStore();
  const { participant } = useParticipantStore();
  const { selectedCenter } = useCentersStore();
  const { hasNotAccess } = usePermissions();
  const isViewOthers = !hasNotAccess('VIEW_OTHER_PATIENTS')

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const isRandomized: any = currentStudy && currentStudy?.studyType === 'RANDOMIZED';

  const isShowPatient = (center: any) => {
    const countryCheck = (`${center?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}`) || (loggedInStudyUser?.country?.name === 'Global');
    const isAllowedCntrs = (loggedInStudyUser?.allowedCenters?.length === 0 && countryCheck) || (loggedInStudyUser?.allowedCenters?.length > 0 && loggedInStudyUser?.allowedCenters?.find((cntr: any) => selectedCenter === 'all' ? `${cntr}` === `${center?.id}` : `${cntr}` === `${selectedCenter}`));
    return (isSiteStaff && (isViewOthers || `${center.id}` === `${loggedInStudyUser?.centerId}`)) || (!isSiteStaff && isAllowedCntrs)
  }

  const isStudyConsent = currentStudy?.consentQuestionnaire ? currentStudy?.consentQuestionnaire?.active : false;

  const mainHeaders: any = [
    { title: intl.formatMessage({ id: 'patient-enrollment.patientId' }), name: 'patientCode' },
    { title: intl.formatMessage({ id: 'site' }), name: 'site' },
    { title: intl.formatMessage({ id: 'patient.name' }), name: 'name' },
    { title: intl.formatMessage({ id: 'login.emailAddress' }), name: 'emailAddress' },
    isRandomized ? { title: intl.formatMessage({ id: 'patient-enrollment.randomizedGroup' }), name: 'randomizedGroup' } : null,
    { title: intl.formatMessage({ id: 'patient.status' }), name: 'status' },
  ].filter((item) => item !== null);

  const consentHeaders: any = [
    { title: intl.formatMessage({ id: 'patient-enrollment.consentStatus' }), name: 'consentStatus' },
    { title: intl.formatMessage({ id: 'patient-enrollment.consentedOn' }), name: 'consentedOn' },
    { title: intl.formatMessage({ id: 'patient-enrollment.icfVersion' }), name: 'icfVersion' },
  ]

  const filteredHeaders: any = isStudyConsent ? [
    ...mainHeaders,  ...consentHeaders,
    { title: intl.formatMessage({ id: 'site.phoneNumber' }), name: 'phoneNumber' },
  ] : [
    ...mainHeaders,
    { title: intl.formatMessage({ id: 'site.phoneNumber' }), name: 'phoneNumber' },
  ];

  const getFilteredPatient = (patients: any) => {
    if (patients && patients?.length > 0) {
      const startList = patients.filter((item: any) => `${item.centerId}` === `${loggedInStudyUser?.centerId}`) || [];
      const endList = patients.filter((item: any) => `${item.centerId}` != `${loggedInStudyUser?.centerId}`) || [];
      return startList.concat(endList) || [];
    }
    return [];
  }

  return (
    <table className='table-auto bg-white w-full'>
      <thead className='sticky top-0 bg-white'>
        <tr>
          {filteredHeaders?.map((r: any) => (
            <th
              key={r.name}
              className='px-4 pt-4 pb-6 text-left font-semibold text-blue-ocean-deep text-base tracking-wider min-w-[160px] '
            >
              {r.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isSiteStaff && !props.isFreezed && ((selectedCenter === 'all') || (`${selectedCenter}` === `${loggedInStudyUser?.centerId}`)) ? <AddNewPatient /> : null}
        {centersData?.map((center: ICenter) => {
          return getFilteredPatient(center?.patients)?.map((item: any, index: number) => {

            if (item.id === participant && isShowPatient(center) && (status ? item?.patientStatus === status : true)) {
              return (
                <ParticipantsListItem
                  key={index}
                  item={item}
                  center={center}
                  isSiteStaff={isSiteStaff}
                  isShowPatient={isShowPatient}
                />
              );
            }

            if (participant === 'all' && isShowPatient(center) && (status ? item?.patientStatus === status : true)) {
              return (
                <ParticipantsListItem
                  key={index}
                  item={item}
                  center={center}
                  isSiteStaff={isSiteStaff}
                  isShowPatient={isShowPatient}
                />
              );
            }
          });
        })}
      </tbody>
    </table>
  );
}
