import DragDropList from '../components/DragableQuestionsContainer/DragDropList';
import { useState, useEffect } from 'react';
import { IQuestion } from 'types/questionnaire';
import { templateUsequestionnaire } from './templateUsequestionnaire';
import { useParams } from 'react-router-dom';

interface IProps {
  selectedTemplate: any | null;
  refetchFlows?: any;
}
const TemplateQuestions = ({ selectedTemplate, refetchFlows }: IProps) => {
  const [questions, setQuestions] = useState(selectedTemplate?.questions ?? []);
  const {
    handleAddQSToQuestionnaire,
    handleRemoveQuestionApi,
    handleDuplicateQuestion,
    handleReorderQuestions,
  } = templateUsequestionnaire({
    selectedQuestionnairy: { questionnaire: selectedTemplate },
    setQuestions,
    questions,
    refetchFlows,
  });
  const { questionnaireId: questionnaireIdStr } = useParams();
  const qssId = questionnaireIdStr?.split('-')?.[1];
  useEffect(() => {
    if (selectedTemplate) {
      setQuestions(selectedTemplate?.questions);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const tmpQuestions: any = [...questions];
    tmpQuestions.map((qs: IQuestion) => {
      if (qs?.parentQuestionId) {
        tmpQuestions.forEach((el: any, folderIndx: number) => {
          if (qs?.parentQuestionId === el?.id) {
            tmpQuestions[folderIndx]['children'] = el['children']
              ? tmpQuestions[folderIndx]['children']?.findIndex((it: any) => it.id === qs.id) < 0
                ? [...el['children'], qs]
                : [...el['children']]
              : [qs];
          }
        });
      }
    });

    tmpQuestions.map((qs: IQuestion) => {
      if (qs?.titleQuestionId) {
        tmpQuestions.forEach((el: any, folderIndx: number) => {
          if (qs?.titleQuestionId === el?.id) {
            tmpQuestions[folderIndx]['titleQuestions'] = el['titleQuestions']
              ? tmpQuestions[folderIndx]['titleQuestions']?.findIndex(
                  (it: any) => it.id === qs.id,
                ) < 0
                ? [...el['titleQuestions'], qs]
                : [...el['titleQuestions']]
              : [qs];
          }
        });
      }
    });
  }, [questions, selectedTemplate]);
  const handleOnSaveQuestion = (
    tmpQuestion: IQuestion & { optionalProps?: any; qssId?: any },
    isSubtitle?: boolean,
  ) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const tmpQuestions = isSubtitle ? questions : questions;
    const fndItem: any = tmpQuestions?.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        qssId: tmpQuestion?.qssId,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        qssId: tmpQuestion?.qssId,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    }
  };
  return (
    <div className='mt-2 h-full max-h-[329px] overflow-y-auto overflow-x-hidden !border-b-1 !border-b-gray'>
      <div className='bg-white relative'>
        <div className='absolute inset-0 z-10 bg-transparent pb-1 ' />
        <DragDropList
          questions={questions
            ?.filter((qs: IQuestion) => !qs.parentQuestionId && !qs?.titleQuestionId)
            ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
            .map((qs: IQuestion) => ({ ...qs, qssId: qssId }))}
          repeatedQuestion={true}
          allQuestions={questions}
          filterRepeatedInput={true}
          className=' [&>div>div>.value]:bg-white rounded-[4px] !border-[1px]'
          isTitleQuestion={false}
          filterTitleInput={false}
          filterRequired={false}
          onRemoveQuestionApi={handleRemoveQuestionApi}
          onDuplicateQuestion={handleDuplicateQuestion}
          handleOnSaveQuestion={handleOnSaveQuestion}
          handleReorderQuestionsFC={handleReorderQuestions}
          workflowQuestionnaire={selectedTemplate}
          handleWorkflowOnSaveQuestion={handleOnSaveQuestion}
          shouldFetchSteps={false}
          shouldFetchSF={false}
          isReadOnlyQuestions={true}
        />
      </div>
    </div>
  );
};

export default TemplateQuestions;
