import { IQuestionnaire } from 'types/questionnaire';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { get as getIdVerifications } from 'services/api/idVerification';
import { getQuestionnairesByStudyId } from 'services/api/questionnaire';
import { IIdVerification } from 'types/step';
import FallbackLoading from 'components/common/FallbackLoading';
import { QuestionsView } from './QuestionsView';
import { retrieveStandardForms } from 'services/api/study';
import { useStudyStore } from 'store/StudyStore';
import { useParticipantStore } from 'store/participantStore';
import { getPatientData } from 'services/api/appointment';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTerminationSubmission } from 'services/api/standardForms';
import usePermissions from 'hooks/usePermissions';
import { useQuestionnaireStore } from '../../../../store/QuestionnaireStore';
import { useCentersStore } from '../../../../store/centers';

export default function DisplayStep() {
  const intl = useIntl();
  const { loggedInStudyUser } = useStudyStore();
  const { isFreezeStudy } = usePermissions();
  const { steps } = useQuestionnaireStore();
  const { selectedCenter } = useCentersStore();

  const { questionnaireId, studyId, participantId } = useParams() as {
    questionnaireId: string;
    studyId: string;
    participantId: string;
  };

  const [idFlag, idValue] = questionnaireId.split('-');

  const { participant } = useParticipantStore();

  // useEffect(() => {
  //   setSelectedQuestionnaire(questionnaireId ? parseInt(questionnaireId.split('-')[1]) : -1);
  // }, [questionnaireId]);

  const { data: patientData } = useQuery({
    queryKey: ['patientData'],
    queryFn: () =>
      (participantId || (participant && participant !== 'all')) &&
      getPatientData(participantId || participant),
    refetchOnMount: true,
  });

  const { data: terminationSubmissionData, refetch: refetchTerminationSubmissionData } = useQuery({
    queryKey: ['termination-submission', questionnaireId],
    queryFn: () => getTerminationSubmission(questionnaireId?.split('-')?.[1], participantId),
    refetchOnMount: true,
  });

  const { isLoading, data } =
    idFlag === 'sf'
      ? useQuery<any[] | undefined>({
          queryKey: ['standard-forms', studyId],
          queryFn: () => retrieveStandardForms(studyId),
          refetchOnMount: 'always',
        })
      : idFlag === 'i'
        ? useQuery<IIdVerification[] | undefined>({
            queryKey: ['id-verification'],
            queryFn: () => getIdVerifications(),
          })
        : useQuery<IQuestionnaire[] | undefined>({
            queryKey: ['questionnaire', studyId],
            queryFn: () => getQuestionnairesByStudyId(studyId as string),
          });
  const isStandardForm = questionnaireId?.split('-')?.[0] === 'sf';
  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const fndQuestionnaire = data?.find((item) => item.id === Number(idValue));
  const isTerminationFormAndSubmitted =
    fndQuestionnaire &&
    fndQuestionnaire?.type === 'TERMINATION' &&
    (patientData?.patientStatus === 'COMPLETED' || patientData?.patientStatus === 'TERMINATED') &&
    terminationSubmissionData?.answers;

  const hasAddPatientAccess = `${selectedCenter}` === `${loggedInStudyUser?.centerId}`;

  const canEditStandardForm = isStandardForm
    ? isSiteStaff &&
      !isTerminationFormAndSubmitted &&
      !(idFlag == 'sf' && isFreezeStudy()) &&
      hasAddPatientAccess
    : !!isSiteStaff;

  let currentData: any;
  if (data && idFlag == 'sf') {
    currentData = data?.find((it: any) => it.id === Number(questionnaireId?.split('-')?.[1]));
    if (
      canEditStandardForm &&
      fndQuestionnaire?.type !== 'TERMINATION' &&
      currentData?.questions?.findIndex(
        (it: any) =>
          it?.questionTitle === intl.formatMessage({ id: 'patient-status.doesEventHappen' }),
      ) === -1
    ) {
      currentData.questions = [
        ...currentData.questions,
        {
          questionType: 'DROPDOWN',
          questionTitle: intl.formatMessage({ id: 'patient-status.doesEventHappen' }),
          id: 1,
          questionOptions: [
            {
              label: intl.formatMessage({ id: 'patient-status.outsideVisit' }),
              value: 'Outside of a visit',
            },
            { label: intl.formatMessage({ id: 'patient-status.unknown' }), value: 'Unkown' },
          ],
        },
      ];

      steps?.map(({ step }: any) => {
        if (step?.folder?.id && !step?.folder?.parentFolderId) {
          currentData.questions[currentData.questions?.length - 1].questionOptions.push({
            label: step.folder.name,
            value: step.folder.name,
          });
        }
      });
    }
  } else if (steps && steps?.length > 0) {
    const questionnaireData: any = steps?.find((stp: any) => stp?.step?.questionnaire?.id === Number(idValue));
    currentData = questionnaireData ? questionnaireData?.step?.questionnaire : null;
  } else if (data && !('type' in data[0])) {
    const idVerificationData = data as IIdVerification[];
    currentData = idVerificationData.find((item) => item.id === Number(idValue));
  }



  return isLoading ? (
    <FallbackLoading className='h-auto w-auto flex-1' />
  ) : currentData && 'type' in currentData ? (
    <QuestionsView
      canEditStandardForm={canEditStandardForm}
      questionnaire={currentData}
      isStandardForm={idFlag == 'sf'}
      isTerminationFormAndSubmitted={isTerminationFormAndSubmitted}
      terminationSubmissionData={terminationSubmissionData}
      refetchTerminationSubmissionData={refetchTerminationSubmissionData}
      canEditAnswer={idFlag == 'sf' && !(idFlag == 'sf' && isFreezeStudy()) && hasAddPatientAccess}
      isInModal={false}
    />
  ) : (
    <div className='flex-1 text-left overflow-y-auto px-20 py-8'>
      <h1 className='text-blue-oil font-serif text-[2rem] leading-[2.75rem] mb-6'>
        <FormattedMessage id='step.IDVerification' />
      </h1>
    </div>
  );
}
