import { useContext, useEffect, useState } from 'react';
import { IFolder } from 'types/folder';
import QuestionnaireButton from './QuestionnaireButton';
import Folder from './Folder';
import { IStepWithStatus, IStepStatus } from 'types/step';
import { useQueryQuestionStore } from 'store/queryQuestionStore';
import SubFolder from './SubFolder';
import { useIntl } from 'react-intl';
import clsx from 'classnames';
import { LanguageContext } from 'pages/App/LanguageContext';
import { languageMap } from 'types/util';

interface FolderListItem extends Partial<IFolder> {
  id?: number;
  name: string;
  status: IStepStatus;
  selectedQuestionnaire: number | string;
  setSelectedQuestionnaire: React.Dispatch<React.SetStateAction<number | string>>;
  option: IStepWithStatus;
  folders: any;
  isQueries?: boolean;
}

export default function FolderListItem({
  name,
  status,
  questionnaires,
  selectedQuestionnaire,
  setSelectedQuestionnaire,
  folders,
  option,
  isQueries,
}: FolderListItem) {
  const intl = useIntl();
  const { queryQuestions } = useQueryQuestionStore();
  const [isOpen, setIsOpen] = useState(
    !!questionnaires?.find((questionnaire) => `${questionnaire.id}` === `${selectedQuestionnaire}`),
  );
  const { locale } = useContext(LanguageContext);
  useEffect(() => {
    if (selectedQuestionnaire) {
      if (folders && folders?.length > 0) {
        const foundQuestionList = folders?.find((fld: any) => fld?.folder?.questionnaires && fld?.folder?.questionnaires?.length > 0);
        if (foundQuestionList) {
          const foundFld = folders?.find((fld: any) => fld?.folder?.questionnaires && fld?.folder?.questionnaires?.length > 0 && fld?.folder?.questionnaires?.find((quest: any) => quest?.id === selectedQuestionnaire));
          foundFld && setIsOpen(true);
          return;
        }
      }
      const foundQuest = questionnaires?.find((questionnaire) => `${questionnaire.id}` === `${selectedQuestionnaire}`);
      foundQuest && setIsOpen(true);
    }
  }, [questionnaires, selectedQuestionnaire]);

  function handleToggleFolder() {
    setIsOpen((prevValue) => !prevValue);
  }

  const checkResolvedFolder = (option: any) => {
    let allResolved = true;
    if (option?.folder && queryQuestions?.length > 0) {
      queryQuestions?.map((item: any) => {
        const foundFolder =
          option?.folder?.folders &&
          option?.folder?.folders?.length > 0 &&
          option?.folder?.folders?.find(
            (fld: any) => fld?.folder?.parentFolderId === item?.folder?.id,
          );
        if (foundFolder && !item.resolved) {
          allResolved = false;
        } else if (option?.folder?.questionnaires && item?.folder?.id === option?.folder?.id) {
          if (!item.resolved) {
            allResolved = false;
          }
        }
      });
    }
    return allResolved;
  };
  return (
    <div data-type='folder' className='bg-base-white rounded-lg'>
      <Folder
        isOpen={isOpen}
        title={name}
        isQueries={isQueries}
        isResolved={checkResolvedFolder(option)}
        onClick={handleToggleFolder}
      />
      {isOpen && !!questionnaires?.length && (
        <div
          className={clsx(
            'pl-7 mt-[0.3rem] flex flex-col gap-[0.7rem] pr-6',
            questionnaires.length && 'pb-2',
          )}
        >
          {questionnaires.map((questionnaire: any) =>
            'type' in questionnaire ? (
               <QuestionnaireButton
                id={questionnaire.id}
                status={status}
                option={option}
                isQueries={isQueries}
                selectedQuestionnaire={selectedQuestionnaire}
                setSelectedQuestionnaire={setSelectedQuestionnaire}
                type={questionnaire.type}
                title={questionnaire?.titleTranslations?.[languageMap[locale]] || questionnaire.title}          
                key={questionnaire.id}
              />
              
            ) : (
              <QuestionnaireButton
                id={questionnaire.id}
                status={status}
                isQueries={isQueries}
                option={option}
                key={questionnaire.id}
                selectedQuestionnaire={selectedQuestionnaire}
                setSelectedQuestionnaire={setSelectedQuestionnaire}
                type='ID_VERIFICATION'
                title={intl.formatMessage({ id: 'step.IDVerification' })}
              />
            ),
          )}
        </div>
      )}
      {isOpen &&
        !!folders?.length &&
        folders?.map((fld: any) => {
          return fld?.folder ? (
            <div
              key={'folder-' + fld?.folder?.id}
              data-type='folder'
              className='pl-5 mt-[0.3rem] flex flex-col gap-[0.7rem] pb-1'
            >
              <SubFolder
                name={fld.folder?.nameTranslations?.[languageMap[locale]] || fld.folder?.name || ''}
                status={option.status}
                option={option}
                setIsParentOpen={setIsOpen}
                isQueries={isQueries}
                folders={option.folder?.folders || []}
                selectedQuestionnaire={selectedQuestionnaire}
                setSelectedQuestionnaire={setSelectedQuestionnaire}
                questionnaires={fld?.folder?.questionnaires || []}
              />
            </div>
          ) : '';
        })}
    </div>
  );
}
