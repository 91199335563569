import { useState, useRef } from 'react';
import { FiEdit3, FiBook } from 'react-icons/fi';
import { Button } from 'components/common';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTemplateModal } from '../../../../useTemplateModal';

interface AddFolderItemProps {
  onCreateFolder?: (name: string) => void;
  folder?: any;
  hadnleSaveItem?: any;
}

const AddFolderItem = ({ onCreateFolder, folder, hadnleSaveItem }: AddFolderItemProps) => {
  const { setShowLibraryModal } = useTemplateModal((state) => ({
    setShowLibraryModal: state.setShowLibraryModal,
  }));
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [itemType, setItemType] = useState<'item' | 'folder'>('item');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = () => {
    if (inputValue.trim()) {
      if (itemType === 'item' && hadnleSaveItem) {
        hadnleSaveItem({ itemName: inputValue.trim(), folder });
      } else if (itemType === 'folder' && onCreateFolder) {
        onCreateFolder(inputValue.trim());
      }
    }
    setIsEditing(false);
    setInputValue('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
      setInputValue('');
    }
  };

  if (isEditing) {
    return (
      <div className='flex -mt-1 w-[199px] border-purple rounded-[2px] justify-between items-center ml-5 gap-x-2 border-[1px]'>
        <input
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className='w-full p-2 text-sm border-none outline-none bg-[#EEF5FF]'
          placeholder={intl.formatMessage({ id: 'study-builder.type-file-name' })}
          autoFocus
        />
      </div>
    );
  }

  return (
    <div className='flex -mt-1 w-[220px] justify-between items-center ml-5 gap-x-2 border-[1px] border-[#CACACA] rounded-[4px] px-2'>
      <span className='text-xs text-[#737373]'>
        <FormattedMessage id='study-builder.add' />
      </span>

      <Button
        onClick={() => {
          setItemType('item');
          setIsEditing(true);
        }}
        className='flex justify-center !border-spacing-5 items-center border-2 h-10 border-gray-medium border-none rounded-md text-xs font-medium	px-[2px] py-[6px] text-gray-medium w-fit'
      >
        <FiEdit3 className='w-5 h-5 text-gray-medium' />
        <FormattedMessage id='study-builder.item' />
      </Button>
      <Button
        onClick={() => {
          setShowLibraryModal({ ...folder });
        }}
        className='flex justify-center items-center border-2 h-10 border-none rounded-md border-gray-medium text-xs font-medium	 px-[2px] py-[6px] text-gray-medium w-fit'
      >
        <FiBook className='w-6 h-5 text-gray-medium' />
        <FormattedMessage id='study-builder.Library' />
      </Button>
    </div>
  );
};

export default AddFolderItem;
