import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  ReactNode,
} from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
// import { TextInput } from 'components/common';
// import clsx from 'classnames';

type WrapperComponentProps = any;
type WrappedComponentProps = any;

interface EditOptionProps {
  WrapperComponent?: FunctionComponent<WrapperComponentProps>;
  handleCallBack?: any;
  children: ReactNode;
  props?: WrapperComponentProps & WrappedComponentProps;
  defaultValue?: any;
  defaultIsOpen?: boolean;
  inputPlaceHolder?: string;
  inputContainerClassName?: string;
  setIsEditingOption?: any;
  IsEditingOption?: any;
  options?: any;
  setOptionChanges?: any;
  optionIndex?: number;
}

const EditOption: FunctionComponent<EditOptionProps> = ({
  handleCallBack = () => {},
  children,
  defaultValue,
  defaultIsOpen = false,
  inputPlaceHolder = '',
  // inputContainerClassName = '',
  setIsEditingOption,
  options,
  setOptionChanges,
  optionIndex,
}) => {
  const [isOpen, setIsOpen] = useState<any>(null);
  const [value, setValue] = useState(defaultValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        // setIsOpen(false);
        // handleCallBack(value);
      }
    },
    [setIsOpen, value],
  );

  useEffect(() => {
    setIsEditingOption(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen == false) {
      console.log('call handleCallBack count');
      // handleCallBack(value);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultIsOpen) {
      setIsOpen(true);
    }
  }, [defaultIsOpen]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const cleanedOptions = options?.map((opt: any) => opt?.toLowerCase());
      if (
        cleanedOptions?.indexOf(value?.toLowerCase()?.trim()) > -1 ||
        (value?.toLocaleLowerCase() === 'other' && cleanedOptions?.indexOf('other') > -1)
      ) {
        setIsOpen(false);
        return;
      } else {
        handleCallBack(value);
        setIsOpen(false);
      }
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setOptionChanges((prev: any) => {
      const newOptionChanges = [...prev];
      newOptionChanges[optionIndex ?? 0] = e.target.value;
      return newOptionChanges;
    });
    const textarea = textareaRef.current;

    if (textarea) {
      // Reset width and height before measuring
      textarea.style.width = 'auto';
      textarea.style.height = 'auto';

      // Check the scrollWidth to expand the width dynamically
      const scrollWidth = textarea.scrollWidth;
      const maxWidth = 513; // Maximum width 513px
      const minWidth = 120; // Minimum width 120px

      // Update the width dynamically while constraining it between min and max width
      const newWidth = Math.min(Math.max(scrollWidth, minWidth), maxWidth);
      textarea.style.width = `${newWidth}px`;

      // Check the scrollHeight to expand the height dynamically
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 390; // Maximum height 390px
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Set initial dimensions
      textarea.style.width = '120px';
      textarea.style.height = 'auto';
    }
    setTimeout(() => {
      // setValue((prev: any) => prev + ' ');
      // const containerRef = useRef<HTMLDivElement>(null);
      console.log('call timeout');
      handleInput({ target: { value: defaultValue } } as any);
    }, 100);
  }, [textareaRef.current]);

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className='w-full'>
      {isOpen ? (
        <div ref={containerRef} className='w-full max-h-[272px] overflow-y-auto'>
          <textarea
            ref={textareaRef}
            value={value}
            onChange={handleInput}
            className='resize-none overflow-y-auto p-0 pl-1 pr-5 h-full rounded-md focus:outline-none outline-none focus:!border-none !border-none'
            style={{ minWidth: '120px', maxWidth: '513px', minHeight: '35px', maxHeight: '390px' }}
            rows={1}
            onKeyDown={handleKeyDown}
            placeholder={inputPlaceHolder ?? 'Type ...'}
          />
        </div>
      ) : (
        <div
          className='flex justify-start items-center pr-[5px]'
          onClick={(e: any) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default EditOption;
