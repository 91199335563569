import { axiosClient } from 'config/axios';
import { format } from 'date-fns';
import {
  ICreateQuestionnaireForm,
  IQuestionForm,
  IQuestionnaire,
  IQuestionnaireForm,
  ISaveQuestionnairesForm,
} from 'types/questionnaire';
import { throwError } from 'utils/throwError';

interface IConsetForm {}
export interface ISignForm {
  date: string;
  country: string;
  city: string;
  ipAddress: string;
}

export const addQuestionToQuestionnaire = async (
  newQuestion: [IQuestionForm],
  questionnaireId: number,
) => {
  const { data } = await axiosClient.post<IQuestionForm>(
    `/questions/questionnaires/${questionnaireId}`,
    newQuestion,
  );
  return data;
};
export const addStaticQuestionToQuestionnaire = async (
  newQuestion: [IQuestionForm],
  questionnaireId: number,
) => {
  const { data } = await axiosClient.post<IQuestionForm>(
    `/static-contents/questionnaires/${questionnaireId}`,
    newQuestion,
  );
  return data;
};

export const deleteQuestion = async (questionId: number, questionnaireId: number) => {
  return axiosClient.delete(`/questions/${questionId}/questionnaires/${questionnaireId}`);
};

export const getQuestionnairesByStudyId = async (studyId: number | string) => {
  try {
    const { data } = await axiosClient.get<IQuestionnaire[]>(`/questionnaires/studies/${studyId}`);
    return data;
  } catch (err) {
    throwError(err);
  }
};

export const createQuestionnaire = async (payload: ICreateQuestionnaireForm) => {
  const { data } = await axiosClient.post<IQuestionnaireForm>(
    `/questionnaires/studies/${payload.studyId}`,
    {
      folderId: payload?.folderId,
      studyId: payload.studyId,
      title: payload.title,
      type: payload.type,
      sortOrder: payload.sortOrder,
      language: payload.language,

      startDate: format(new Date(), 'yyyy-MM-dd'),
      dueDate: format(new Date(2024, 12, 12), 'yyyy-MM-dd'),
    },
  );
  return data;
};
export const createIDVerification = async (payload: ICreateQuestionnaireForm) => {
  const { data } = await axiosClient.post<IQuestionnaireForm>('/id-verification', payload);
  return data;
};

export const updateQuestionnaire = async (questionnaire: IQuestionnaireForm) => {
  const { data } = await axiosClient.put<IQuestionnaireForm>(
    `/questionnaires/${questionnaire.id}`,
    {
      type: questionnaire.type,
      title: questionnaire.title,
      sortOrder: questionnaire.sortOrder,
      startDate: format(new Date(), 'yyyy-MM-dd'),
      dueDate: format(new Date(2024, 12, 12), 'yyyy-MM-dd'),
    },
  );
  return data;
};
export const deleteStaticQuestion = async (staticQuestionId: number, questionnaireId: number) => {
  return axiosClient.delete(
    `/static-contents/${staticQuestionId}/questionnaires/${questionnaireId}`,
  );
};
export const saveQuestionnaires = async (payload: ISaveQuestionnairesForm) => {
  const { data } = await axiosClient.post<IQuestionnaireForm>(
    `/questions/questionnaires/${payload.questionnaireId}`,
    payload.data,
  );
  return data;
};
export const saveStaticQuestionnaires = async (payload: ISaveQuestionnairesForm) => {
  const { data } = await axiosClient.post<IQuestionnaireForm>(
    `/static-contents/questionnaires/${payload.questionnaireId}`,
    payload.data,
  );
  return data;
};
export const duplicateQuestion = async (id: number) => {
  const { data } = await axiosClient.post(`/questions/${id}/duplicate`);
  return data;
};

export const getConsetFormPatient = async (patientId: any) => {
  const { data } = await axiosClient.get(`/consent/patients/${patientId}`);
  return data;
};

export const sendPatientEmail = async (consentId: any) => {
  const { data } = await axiosClient.put(`/consent/${consentId}/send-email`);
  return data;
};

export const postConsetForm = async (consentId: any, meetingNo: number, payload: IConsetForm) => {
  const { data } = await axiosClient.post(
    `/consent/${consentId}?meetingNumber=${meetingNo}`,
    payload,
  );
  return data;
};

export const endConsetFormMeeting = async (consentId: any, meetingNo: number) => {
  const { data } = await axiosClient.put(`/consent/${consentId}/end?meetingNumber=${meetingNo}`);
  return data;
};

export const signConsetForm = async (consentId: any, studyUserId: number, payload: ISignForm) => {
  const { data } = await axiosClient.put(
    `/consent/${consentId}/sign-by-study-user?studyUserId=${studyUserId}`,
    payload,
  );
  return data;
};

export const setsdvAnswer = async (answerId: number) => {
  const { data } = await axiosClient.put(`/patients/answers/${answerId}/set-sdv`);
  return data;
};

export const setRepeatsdvAnswer = async (repeatedAnswersRecordId: number) => {
  const { data } = await axiosClient.put(
    `patients/repeated-answers-records/${repeatedAnswersRecordId}/set-sdv`,
  );
  return data;
};

export const postRepeatedAnswers = async (
  patientStepId: number,
  repeatedAnswersRecordId = -1,
  payload: any,
) => {
  const { data } = await axiosClient.post(
    `patients/steps/${patientStepId}/repeated-answer${repeatedAnswersRecordId > -1 ? `?repeatedAnswersRecordId=${repeatedAnswersRecordId}` : ''}`,
    payload,
  );
  return data;
};

export const duplicateStepApi = async (stepId: number) => {
  const { data } = await axiosClient.post(`/steps/${stepId}/duplicate`);
  return data;
};

export const triggerEpro = async (stepId: number, patientId: number) => {
  const { data } = await axiosClient.post(`patients/${patientId}/steps/${stepId}/trigger-epro`);
  return data;
};

export const getEproListApi = async (stepId: number, patientId: number, page: number, size: number, sort: any) => {
  const { data } = await axiosClient.get(`patients/${patientId}/steps/${stepId}?page=${page}&size=${size}&sort=${sort}`);
  return data;
};

export const deleteMultiAnswers = async (payload: any) => {
  const { data } = await axiosClient.delete('patients/answers/delete-multiple', { data: payload });
  return data;
};

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const questionnairesTranslateFile = async ({ stepId, language, file }: any) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axiosClient.post(
    `translations/${stepId}/translate-with-file?language=${language}`,
    formData,
    { headers },
  );
  return data;
};

export const questionnairesTranslateAI = async (stepId: number, language: string) => {
  const { data } = await axiosClient.post(`translations/${stepId}/translate-with-ai?targetLanguage=${language}`,{}, { timeout: 120000 });
  return data;
};

export const questionnairesTranslateEntity = async (entityId: number, language: string, key: string, studyId: number, value: any) => {
  const { data } = await axiosClient.post(`translations/${entityId}?languageCode=${language}&key=${key}&studyId=${studyId}`, value);
  return data;
};

export const questionnaireTranslateApprove = async (questionnaireId: number, language: string, value: any) => {
  const { data } = await axiosClient.put(`translations/questionnaires/${questionnaireId}/approve?targetLanguage=${language}&value=${value}`);
  return data;
};
